import { Box, Card, CardContent, Container, Grid, Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import kcDataInfo from "../../DataInfo/DataInfo";
import RouterProp from "../../utils/RouterType";
import { withRouter } from "../../utils/WithRouter";
import favicon from "../../favicon.png";

type StateType = {
   Account: string;
   PassWord: string;
   Loading: boolean;
   Account_Error: boolean;
   Account_ErrMsg: string;
   PassWord_Error: boolean;
   PassWord_ErrMsg: string;
};
class Login extends React.Component<RouterProp> {
   state: StateType = {
      Account: "",
      PassWord: "",
      Loading: false,
      Account_Error: false,
      Account_ErrMsg: "",
      PassWord_Error: false,
      PassWord_ErrMsg: "",
   };

   private m_bFirstDidMount = true;

   componentDidMount() {
      if (this.m_bFirstDidMount) {
         this.CheckAccount();
         this.m_bFirstDidMount = false;
      }
   }

   private CheckAccount = async () => {
      // const AccountState = await kcDataInfo.GetAccount();
      // if (AccountState.Succeed) this.props.Route.Navigate("/Code");
   };

   private Login = async () => {
      if (!this.CheckLoginInput()) return;

      await this.setStateAsync({ Loading: true });
      await this.Sleep(500);

      // const Resault = await kcDataInfo.Login(this.state.Account, this.state.PassWord);
      const Resault = await kcDataInfo.Login(this.state.Account, this.state.PassWord);

      if (Resault.Success) {
         await this.setStateAsync({ Loading: false });
         this.props.Route.Navigate("/Code");
      } else {
         this.setState({
            Account_Error: !Resault.Success,
            PassWord_Error: !Resault.Success,
            Account_ErrMsg: "",
            PassWord_ErrMsg: !Resault.Success ? Resault.Message : "",
            Loading: false,
         });
      }
   };

   private CheckLoginInput = () => {
      var Account_Error = false;
      var Account_ErrMsg = "";
      var PassWord_Error = false;
      var PassWord_ErrMsg = "";
      if (this.state.Account === "") {
         Account_Error = true;
         Account_ErrMsg = "請輸入帳號";
      }
      if (this.state.PassWord === "") {
         PassWord_Error = true;
         PassWord_ErrMsg = "請輸入帳號";
      }

      if (this.state.Account_Error !== Account_Error || this.state.Account_ErrMsg !== Account_ErrMsg || this.state.PassWord_Error !== PassWord_Error || this.state.PassWord_ErrMsg !== PassWord_ErrMsg) {
         this.setState({
            Account_Error,
            Account_ErrMsg,
            PassWord_Error,
            PassWord_ErrMsg,
         });
      }

      return !Account_Error && !PassWord_Error;
   };
   private setStateAsync = (state: {}) => {
      return new Promise((resolve) => {
         this.setState(state, resolve as () => void);
      });
   };
   private Sleep(_nTimeOut: number) {
      return new Promise((r) => setTimeout(r, _nTimeOut));
   }
   private OnInput_Account = (_value: string) => {
      this.setState({
         Account: _value,
         Account_Error: false,
         Account_ErrMsg: "",
      });
   };
   private OnInput_Password = (_value: string) => {
      this.setState({
         PassWord: _value,
         PassWord_Error: false,
         PassWord_ErrMsg: "",
      });
   };

   render() {
      return (
         <div>
            <Box
               sx={{
                  position: "absolute",
                  inset: 0,
                  top: 0,
                  //bgcolor: "rgba(0,0,0,0.55)",
                  bgcolor: "#1c1c1c",
                  //px: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               <Container
                  maxWidth="sm"
                  disableGutters
                  sx={{
                     position: "absolute",
                     zIndex: 2,
                     top: "50%",
                     left: "50%",
                     transform: "translate(-50%,-50%)",
                  }}
               >
                  <Card
                     //style={{ width: "100%" }}
                     sx={{
                        px: { xs: 0, md: 2 },
                        maxHeight: { xs: "450px", md: "550px" },
                        overflowY: "inherit",
                        mx: 2,
                     }}
                  >
                     <img src={favicon} className="HomeImage" alt="logo" style={{ padding: "20px" }} />
                     <CardContent sx={{ overflowX: "hidden" }}>
                        <Grid container spacing={2}>
                           <Grid item xs={12}>
                              <TextField
                                 size="small"
                                 fullWidth
                                 label="交易帳號"
                                 variant="standard"
                                 value={this.state.Account}
                                 error={this.state.Account_Error}
                                 helperText={this.state.Account_ErrMsg}
                                 onChange={(event) => {
                                    this.OnInput_Account(event.target.value);
                                 }}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <TextField
                                 size="small"
                                 fullWidth
                                 label="密碼"
                                 type="password"
                                 variant="standard"
                                 value={this.state.PassWord}
                                 error={this.state.PassWord_Error}
                                 helperText={this.state.PassWord_ErrMsg}
                                 onChange={(event) => {
                                    this.OnInput_Password(event.target.value);
                                 }}
                              />
                           </Grid>
                        </Grid>
                        {/* 登入/忘記密碼 */}
                        <Stack spacing={2} sx={{ mt: 4 }}>
                           <LoadingButton
                              loading={this.state.Loading}
                              loadingPosition="start"
                              startIcon={<></>}
                              variant="contained"
                              fullWidth
                              style={{
                                 color: "#fff",
                                 backgroundColor: "#bfa534",
                              }}
                              onClick={this.Login}
                           >
                              登入
                           </LoadingButton>
                           {/* <Button
                      fullWidth
                      onClick={() => {
                        // 先去到輸入手機的頁面
                      }}
                    >
                      忘記密碼
                    </Button> */}
                        </Stack>
                     </CardContent>
                  </Card>
               </Container>
            </Box>
         </div>
      );
   }
}
export default withRouter(Login);
