export const MSG = {
   title: "程式碼",
   blocks: "積木",
   linkTooltip: "儲存積木組並提供連結。",
   runTooltip: "於工作區中執行積木組所定義的程式。",
   badCode: "程式錯誤：\n%1",
   timeout: "超過最大執行數。",
   trashTooltip: "捨棄所有積木。",
   catLogic: "邏輯",
   catLoops: "迴圈",
   catMath: "數學式",
   catText: "文字",
   catLists: "列表",
   catColour: "顏色",
   catVariables: "變數",
   catFunctions: "函式",
   listVariable: "列表",
   textVariable: "文字",
   httpRequestError: "命令出現錯誤。",
   linkAlert: "透過此連結分享您的積木組：\n\n%1",
   hashError: "對不起，「%1」並未對應任何已保存的程式。",
   xmlError: "未能載入您保存的檔案。或許它是由其他版本的Blockly創建？",
   badXml: "解析 XML 時出現錯誤：\n%1\n\n選擇'確定'以放棄您的更改，或選擇'取消'以進一步編輯 XML。",
   UNDO: "上一步",
   REDO: "下一步",

   catMain: "主程式",
   catDateTime: "時間",
   catCommodity: "商品",
   catQuote: "報價",
   catTECP: "技術指標",
   catOrder: "交易",
   catOrder_Trade: "即時下單",
   catOrder_Entrust: "掛單",
   catOrder_Inventory: "庫存",
   catOrder_History: "交易歷史",
   catOrder_TradeIndex: "交易單號",
   catAccount: "帳戶",

   SaveBlocks: "儲存",
   LoadBlocks: "讀取",
   SortBlocks: "排序積木",
   LogOut: "登出",

   // Global
   blockMsg_Global_Commodity: "目前商品",
   block_string_length_2_Text: "取得 %1 的字串長度",
};
