import Blockly from "blockly";
import RegTECPHelper from "./Block_Helper_RegisterTECP";
import { Version } from "./Block_Define";

//import RegTECPHelper from "./Block_Helper_RegisterTECP";

const JS: Blockly.Generator = Blockly.JavaScript;

/* ---------------------------------------------------------------------------------------------------------*/
// override Blockly.JavaScript.init
const f_JS_init_Base = JS.init;
function Override_JS_init() {
   JS.init = function (workspace: Blockly.Workspace) {
      RegTECPHelper.Init(); // 啟用TECP註冊
      const Ret = f_JS_init_Base.call(JS, workspace);
      // console.log(Blockly.Variables.allUsedVarModels(workspace));
      // console.log(Blockly.Procedures.allProcedures(workspace));
      //console.log(workspace.getVariableMap().getAllVariables());

      // var NameDB = Blockly.JavaScript.nameDB_;

      // var i = 0;
      // for (var VarItem of Blockly.Variables.allUsedVarModels(workspace)) {
      //   var szDisName = NameDB.getName(VarItem.getId(), "VARIABLE");
      //   console.log(`Var (${i}) ${VarItem.name} => `, szDisName);
      //   i++;
      // }

      // for (var ProItem of Blockly.Procedures.allProcedures(workspace)) {
      //   console.log("ProItem: ", ProItem);
      // }

      /* ------------------------------------------------ */
      // 這邊可以拿到 Blockly.JavaScript.definitions_, Function要在finish裡面才拿得到
      // console.log(
      //   "Blockly.JavaScript.init => definitions_: ",
      //   Blockly.JavaScript.definitions_
      // );

      /* ------------------------------------------------ */

      return Ret;
   };
}

/* ---------------------------------------------------------------------------------------------------------*/
// override Blockly.JavaScript.finish
const f_JS_finish_Base = JS.finish;
function Override_JS_finish() {
   JS.finish = function (code: string) {
      const szRegFunc = RegTECPHelper.GetRunRegisterFuncCode();
      if (szRegFunc) code = szRegFunc + "\n\n\n" + code;

      const szVersionCode = `const Version = '${Version}';\n\n\n`;
      const Code = f_JS_finish_Base.call(JS, code).trim();
      return szVersionCode + Code;
   };
}

/* ---------------------------------------------------------------------------------------------------------*/
// override Blockly.Variables.flyoutCategoryBlocks
// 複寫變數工具箱
const flyoutCategoryBlocks_Ori = Blockly.Variables.flyoutCategoryBlocks;
function Override_flyoutCategoryBlocks() {
   Blockly.Variables.flyoutCategoryBlocks = function (workspace) {
      const xmlList = flyoutCategoryBlocks_Ori(workspace);
      if (xmlList.length === 0) return xmlList;

      let ndeclareIdx = xmlList.findIndex((q) => q.getAttribute("type")?.valueOf() === "variables_declare");
      if (ndeclareIdx > -1) return xmlList;

      let nIdx_Math_Change = xmlList.findIndex((q) => q.getAttribute("type")?.valueOf() === "math_change");
      if (nIdx_Math_Change >= 0) xmlList.splice(nIdx_Math_Change, 1);

      const variableModelList = workspace.getVariablesOfType("");
      const mostRecentVariable = variableModelList[variableModelList.length - 1];

      var XML = Blockly.Xml.textToDom(
         `   <block type="variables_declare">
              <field name="VAR">${mostRecentVariable.name}</field>
          </block>`
      );
      // var XML = Blockly.Xml.textToDom(
      //   `   <block type="variables_declare">
      //           <field name="VAR">${mostRecentVariable.name}</field>
      //             <value name="Value">
      //               <shadow type="math_number">
      //                 <field name="NUM">0</field>
      //               </shadow>
      //             </value>
      //       </block>`
      // );

      return [XML, ...xmlList];
   };
}
function Override_flyoutCategoryBlocks_Hide_Math_Change() {
   Blockly.Variables.flyoutCategoryBlocks = function (workspace) {
      const xmlList = flyoutCategoryBlocks_Ori(workspace);
      if (xmlList.length === 0) return xmlList;

      let nIdx_Math_Change = xmlList.findIndex((q) => q.getAttribute("type")?.valueOf() === "math_change");
      if (nIdx_Math_Change >= 0) xmlList.splice(nIdx_Math_Change, 1);

      return xmlList;
   };
}

/* ---------------------------------------------------------------------------------------------------------*/
// 客製化 ConnectionChecker, 目前實作Commodity Check
class ConnectionChecker_Override extends Blockly.ConnectionChecker {
   constructor() {
      super();
   }
   public doTypeChecks(a: Blockly.Connection, b: Blockly.Connection): boolean {
      // a: Output side
      // b: Input side

      return this.CheckType_Commodity(a, b) && super.doTypeChecks(a, b);
   }

   private CheckType_Commodity(_OutSide: Blockly.Connection, _InSide: Blockly.Connection): boolean {
      var ComIdx_Out = _OutSide.getCheck()?.indexOf("Commodity");
      var ComIdx_In = _InSide.getCheck()?.indexOf("Commodity");

      if (ComIdx_Out === undefined) ComIdx_Out = -1;
      if (ComIdx_In === undefined) ComIdx_In = -1;

      if (ComIdx_Out === ComIdx_In) return true;

      if (ComIdx_Out < 0 || ComIdx_In < 0) {
         return false;
      }

      return true;
   }
}

function Override_ConnectionChecker() {
   Blockly.registry.unregister(Blockly.registry.Type.CONNECTION_CHECKER, Blockly.registry.DEFAULT);
   Blockly.registry.register(Blockly.registry.Type.CONNECTION_CHECKER, Blockly.registry.DEFAULT, ConnectionChecker_Override);
}

/* ---------------------------------------------------------------------------------------------------------*/
// 執行override
Override_JS_init();
Override_JS_finish();
Override_flyoutCategoryBlocks_Hide_Math_Change(); // 隱藏Math_Change的Block
// Override_ConnectionChecker(); // 客製化積木連接判斷
