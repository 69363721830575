import { Version } from "./Block_Define";
import "./Block_Helper_Override";
import { ToolboxItems_Account } from "./Blocks_Account";
import { ToolboxItems_Commodity } from "./Blocks_Commodity";
import { ToolboxItems_DateTime } from "./Blocks_DateTime";
import "./Blocks_Debug";
import { ToolboxItems_Main, MainBlockType as MainBlock_Type } from "./Blocks_Main";
import { Toolbox_Order } from "./Blocks_Order_All";
import { ToolboxItems_Quote } from "./Blocks_Quote";
import { ToolboxItems_TECP } from "./Blocks_TECP";
import "./Blocks_Variables";

export const Toolbox = {
   Account: ToolboxItems_Account,
   Commodity: ToolboxItems_Commodity,
   DateTime: ToolboxItems_DateTime,
   Main: ToolboxItems_Main,
   Order: Toolbox_Order,
   Quote: ToolboxItems_Quote,
   TECP: ToolboxItems_TECP,
};
export const BlocklyVersion = Version;
export type MainBlockType = MainBlock_Type;
