import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes } from "./Block_Define";
import RegTECPHelper from "./Block_Helper_RegisterTECP";

Blockly.Blocks["block_commodity"] = {
   init: function () {
      this.appendDummyInput().appendField("目前商品"); //appendField(Blockly.Msg.blockMsg_Global_Commodity);
      this.setOutput(true, FieldTypes.Commodity);
      this.setStyle(Keys_Block.Commodity);
      this.setTooltip("回傳當前圖表的商品名稱");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_setcommodity"] = {
   init: function () {
      const This = this as Blockly.Block;
      This.appendDummyInput().appendField("指定商品").appendField(new Blockly.FieldTextInput("代號"), "StockCode");
      this.setOutput(true, FieldTypes.Commodity);
      this.setStyle(Keys_Block.Commodity);
      this.setTooltip("指定商品");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_commodity_getinfo_number"] = {
   init: function () {
      this.appendValueInput("NAME").setCheck(FieldTypes.Commodity).appendField("取得商品資料 (double)").appendField("目前商品");
      this.appendDummyInput()
         .appendField("型別")
         .appendField(
            new Blockly.FieldDropdown([
               ["合約大小", "ContractSize"],
               ["點差", "PointDiff"],
               ["小數點位數", "DecimalNum"],
               ["最小跳動點數", "TickSize"],
               ["買入庫存費", "SwapLong"],
               ["賣出庫存費", "SwapShort"],
               ["最少交易手數", "MinTradeHVol"],
               ["最大交易手數", "MaxTradeHVol"],
               ["最大持倉手數", "MaxHandHVol"],
            ]),
            "Field"
         );
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Commodity);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_commodity_getinfo_string"] = {
   init: function () {
      this.appendValueInput("NAME").setCheck(FieldTypes.Commodity).appendField("取得商品資料 (string)").appendField("目前商品");
      this.appendDummyInput()
         .appendField("型別")
         .appendField(
            new Blockly.FieldDropdown([
               ["商品名稱", "StockName"],
               ["貨幣", "Currency"],
               ["交易所代碼", "ExchangeCode"],
            ]),
            "Field"
         );
      this.setOutput(true, FieldTypes.String);
      this.setStyle(Keys_Block.Commodity);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_commodity_getcommoditycount"] = {
   init: function () {
      this.appendDummyInput().appendField("取得可用交易商品數量");
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Commodity);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_commodity_getcommoditycode"] = {
   init: function () {
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("取得交易商品名稱").appendField("順序位置");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.String);
      this.setStyle(Keys_Block.Commodity);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

// Blockly.Blocks["block_commodity_gettradetime"] = {
//   init: function () {
//     this.appendValueInput("Commodity")
//       .setCheck(FieldTypes.Commodity)
//       .appendField("取得商品交易時間")
//       .appendField("商品");
//     this.appendDummyInput()
//       .appendField(
//         new Blockly.FieldDropdown([
//           ["星期一", "1"],
//           ["星期二", "2"],
//           ["星期三", "3"],
//           ["星期四", "4"],
//           ["星期五", "5"],
//           ["星期六", "6"],
//           ["星期日", "0"],
//         ]),
//         "DayOfWeek"
//       )
//       .appendField("時間")
//       .appendField(
//         new Blockly.FieldDropdown([
//           ["開始", "StartTime"],
//           ["結束", "EndTime"],
//         ]),
//         "Trade"
//       );
//     this.setInputsInline(true);
//     this.setOutput(true, FieldTypes.DateTime);
//     this.setStyle(Keys_Block.Commodity);
//     this.setTooltip("");
//     this.setHelpUrl("");
//   },
// };

Blockly.JavaScript["block_commodity"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_GetCommodity()";

   RegTECPHelper.AppendCommodity(code);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_setcommodity"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.

   var szStockCode = block.getFieldValue("StockCode");
   var code = `'${szStockCode}'`;

   RegTECPHelper.AppendCommodity(code);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_commodity_getinfo_number"] = function (block: Blockly.Block) {
   var value_name = Blockly.JavaScript.valueToCode(block, "NAME", Blockly.JavaScript.ORDER_NONE);

   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetCommodityInfo_Double(${value_name}, '${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_commodity_getinfo_string"] = function (block: Blockly.Block) {
   var value_name = Blockly.JavaScript.valueToCode(block, "NAME", Blockly.JavaScript.ORDER_NONE);
   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetCommodityInfo_Double(${value_name}, '${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_commodity_getcommoditycount"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_GetTradableCommodityNumber()";

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_commodity_getcommoditycode"] = function (block: Blockly.Block) {
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetCommodityByIndex(${value_index})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

// Blockly.JavaScript["block_commodity_gettradetime"] = function (
//   block: Blockly.Block
// ) {
//   var value_commodity = Blockly.JavaScript.valueToCode(
//     block,
//     "Commodity",
//     Blockly.JavaScript.ORDER_NONE
//   );
//   var dropdown_dayofweek = block.getFieldValue("DayOfWeek");
//   var dropdown_trade = block.getFieldValue("Trade");
//   // TODO: Assemble JavaScript into code variable.
//   var code = `_GetTradeTime(${value_commodity}, ${dropdown_dayofweek}, '${dropdown_trade}')`;

//   return [code, Blockly.JavaScript.ORDER_ATOMIC];
// };

export const ToolboxItems_Commodity = {
   kind: "category",
   name: "%{BKY_catCommodity}",
   categorystyle: Keys_Cat.Commodity,
   contents: [
      {
         kind: "block",
         blockxml: `<block type="block_commodity"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_setcommodity"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_commodity_getinfo_number">
      <field name="Field">ContractSize</field>
      <value name="NAME">
        <block type="block_commodity"></block>
      </value>
    </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_commodity_getinfo_string">
      <field name="Field">StockName</field>
      <value name="NAME">
        <block type="block_commodity"></block>
      </value>
    </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_commodity_getcommoditycount"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_commodity_getcommoditycode">
      <value name="Index">
        <shadow type="math_number">
          <field name="NUM">0</field>
        </shadow>
      </value>
    </block>`,
      },
      // {
      //   kind: "block",
      //   blockxml: `<block type="block_commodity_gettradetime">
      //   <field name="DayOfWeek">1</field>
      //   <field name="Trade">StartTime</field>
      //   <value name="Commodity">
      //     <block type="block_commodity"></block>
      //   </value>
      // </block>`,
      // },
   ],
};
