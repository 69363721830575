import "./Home.css";
import React from "react";
import favicon from "../../favicon.png";

export const Home: React.FC = () => {
   return (
      <div className="App">
         <header className="App-header">
            <img src={favicon} className="HomeImage" alt="logo" />
         </header>
      </div>
   );
};
