import "./BlocklyEdit.css";
import React from "react";
import RouterProp from "../../utils/RouterType";
import { withRouter } from "../../utils/WithRouter";
import { Box, Button, Select, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import SortIcon from "@mui/icons-material/Sort";
// import UpLoadICon from "@mui/icons-material/CloudUpload";
// import DownLoadICon from "@mui/icons-material/CloudDownload";
import UpLoadICon from "@mui/icons-material/TaskAltOutlined";
import DownLoadICon from "@mui/icons-material/ReplayOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Blockly from "blockly";
import { BlocklyWorkspace, ToolboxDefinition, WorkspaceSvg } from "react-blockly";
import BlocklyHelper, { LanguageList } from "./BlocklyHelper";
import BlockConfig from "./ConfigFile";
import { ExampleList, Examples } from "./Example";
import { BlockSvg } from "core/blockly";
import favicon from "../../favicon.png";
import kcDataInfo from "../../DataInfo/DataInfo";
import { PostApi, StrategyFileModel } from "../../Api";
import ShareApi from "../../ShareApi";
import { UpdateStrategyParamModel } from "../../Api/PostApi";

const DebugArea = false;
const ShowJavaScript = () => {
   return DebugArea || kcDataInfo.Account() === "1382103001";
};

const ICon_Color = "#ebebebff"; //"#ebebebc0";
const ICon_Color_Disable = "#ebebeb40"; //"#ebebebc0";

const ICon_Color_TradeApp = "#00000070";
const ICon_Color_TradeApp_Disable = "#00000020";

type StateType = {
   FixedUpdate: number;
   CurrentEditState: eEditType;
   XML: string;
   JSon: string;
   Code_JS: string;
   StrategyFile: StrategyFileModel | undefined;
   StrategyIndex: number;
   Locale: string;
   toolbox: ToolboxDefinition;
   IOLoading: boolean;
   InitLoading: boolean;

   DebugArea_Width: number;
   DebugArea_pDown?: { X: number; W: number };
};
enum eEditType {
   Blockly,
   XML,
   JSon,
   JS,
   Dart,
}

type PropsType = RouterProp & { classes: any; StrategyIndex: number; ChangeStrategy: () => void };

class BlocklyEdit extends React.Component<PropsType, StateType> {
   private m_bLoadedSave = false; // 範例策略在這邊產生jsCode後需要存檔的flag
   private m_bTraderAppMode = false; // Trader端登入進來的(有帶參數)
   private m_bFirstDidMount = true;
   private m_DialogRef = React.createRef<AlertDialog>();
   private m_workspace?: WorkspaceSvg = undefined;
   private m_bInitClearUnDo = false; // 第一次

   state: StateType = {
      FixedUpdate: 0,
      CurrentEditState: eEditType.Blockly,
      XML: "",
      JSon: "",
      Code_JS: "",
      StrategyIndex: 0,
      StrategyFile: undefined,
      Locale: LanguageList[1].Name,
      toolbox: BlockConfig.INITIAL_TOOLBOX_JSON,
      IOLoading: false,
      InitLoading: true,

      DebugArea_Width: 600,
      DebugArea_pDown: undefined,
   };

   constructor(_prop: any) {
      super(_prop);
      this.InitShareApi();

      const StorageLocale = BlocklyHelper.GetStorageLocale();
      if (StorageLocale) this.state.Locale = StorageLocale;
   }

   componentDidMount() {
      if (this.m_bFirstDidMount) {
         BlocklyHelper.ChangeLocale(this.state.Locale, "", this.props.StrategyIndex, undefined);
         this.ChangeStrategyAsync(this.props.StrategyIndex);

         this.m_bFirstDidMount = false;
      }
   }

   componentDidUpdate(prevProps: Readonly<PropsType>, prevState: Readonly<StateType>, snapshot?: any): void {
      if (this.props.StrategyIndex !== prevProps.StrategyIndex) {
         this.ChangeStrategyAsync(this.props.StrategyIndex);
      }
   }

   private InitShareApi = () => {
      if (ShareApi.TradeAppMode) {
         this.m_bTraderAppMode = true;
         ShareApi.ChangeStrategy = this.ChangeLoadBlocks;
         ShareApi.HasChangeBlock = this.GetCanUnDo;
         ShareApi.SaveBlock = this.SaveBlocks;
      }
   };

   private ChangeStrategyAsync = async (_nInitStrategy: number) => {
      if (!this.state.InitLoading) await this.setStateAsync({ IOLoading: true });

      var StrategyFile: StrategyFileModel | undefined;
      const LoadRes = await PostApi.GetStrategy(_nInitStrategy);
      if (LoadRes?.Success) StrategyFile = LoadRes.Data;
      if (!StrategyFile) {
         if (LoadRes && !LoadRes.Success) this.m_DialogRef?.current?.ShowDialog({ _szContent: LoadRes.Message, _szOkButtonText: "關閉" });
         await this.setStateAsync({ StrategyFile, InitLoading: false, IOLoading: false });
         return;
      }

      /* -------------------------------------------- */
      // 新創造的策略處裡
      var bLoadedSave = true; // 範例策略 等到jsCode產生完後需要存檔
      if (!StrategyFile.XML) StrategyFile.XML = BlockConfig.Get_INITIAL_XML(StrategyFile.StrategyName, StrategyFile.Description);
      else if (StrategyFile.XML === "Default") StrategyFile.XML = Examples.Main_Single.xml; // 預設框架
      else if (StrategyFile.XML === "KLine_Inc") StrategyFile.XML = ExampleList[2]?.xml; // 單筆上漲
      else if (StrategyFile.XML === "MA_Cross") StrategyFile.XML = ExampleList[3]?.xml; // 突破均線
      else if (StrategyFile.XML === "Bool_Cross") StrategyFile.XML = ExampleList[4]?.xml; // 突破布林通道
      else if (StrategyFile.XML === "KD_Cross") StrategyFile.XML = ExampleList[5]?.xml; // KD黃金交叉
      else if (StrategyFile.XML === "RSI_Cross") StrategyFile.XML = ExampleList[6]?.xml; // RSI黃金交叉
      else if (StrategyFile.XML === "MACD_Zero") StrategyFile.XML = ExampleList[7]?.xml; // MACD轉為正值
      else if (StrategyFile.XML === "KLine_Incs") StrategyFile.XML = ExampleList[8]?.xml; // 連續大漲
      else bLoadedSave = false;

      if (StrategyFile.Version !== "1.0.1") bLoadedSave = true; // 針對刷新檔案的存檔(目前用1.0.0.2)

      this.m_bLoadedSave = bLoadedSave;
      /* -------------------------------------------- */

      await this.setStateAsync({ StrategyFile, XML: StrategyFile.XML, StrategyIndex: StrategyFile.StrategyIndex, InitLoading: false, IOLoading: false });

      // var BlockXml: string = "";
      // var StrategyIndex: number | undefined = _nInitStrategy;
      // if (_nInitStrategy === undefined) {
      //    // 讀取檔案
      //    const szAccount = bHasAccount ? kcDataInfo.Account() : "";
      //    const ReadItem = BlocklyHelper.GetBlockBuffer(szAccount);
      //    if (ReadItem?.XML) {
      //       BlockXml = ReadItem.XML;
      //       StrategyIndex = ReadItem.StrategyIndex;
      //    }
      // } else {
      //    // Api Load
      //    const LoadRes = await PostApi.GetStrategy(_nInitStrategy);
      //    if (LoadRes?.Success) {
      //       StrategyFile = LoadRes.Data;
      //       BlockXml = LoadRes.Data.XML;
      //       var bLoadedSave = true; // 範例策略 等到jsCode產生完後需要存檔
      //       if (!BlockXml) BlockXml = BlockConfig.INITIAL_XML;
      //       else if (BlockXml === "預設框架") BlockXml = ExampleList[1]?.xml;
      //       else if (BlockXml === "單筆上漲") BlockXml = ExampleList[2]?.xml;
      //       else if (BlockXml === "突破均線") BlockXml = ExampleList[3]?.xml;
      //       else if (BlockXml === "突破布林通道") BlockXml = ExampleList[4]?.xml;
      //       else if (BlockXml === "KD黃金交叉") BlockXml = ExampleList[5]?.xml;
      //       else if (BlockXml === "RSI黃金交叉") BlockXml = ExampleList[6]?.xml;
      //       else if (BlockXml === "MACD轉為正值") BlockXml = ExampleList[7]?.xml;
      //       else if (BlockXml === "連續大漲") BlockXml = ExampleList[8]?.xml;
      //       else bLoadedSave = false;
      //       this.m_bLoadedSave = bLoadedSave;
      //    }
      // }

      // if (!BlockXml) BlockXml = BlockConfig.INITIAL_XML;

      // await this.setStateAsync({ StrategyFile, XML: BlockXml, StrategyIndex: StrategyIndex, IOLoading: false });
      // await Extensions.Sleep(500);
      // await this.setStateAsync({ InitLoading: false, IOLoading: false });
   };

   private _OnXmlChange = async (_xml: string) => {
      if (this.m_workspace === undefined) return (this.m_bInitClearUnDo = true);

      if (this.m_bInitClearUnDo) {
         if (this.m_workspace.getUndoStack().length > 0) {
            this.m_workspace.clearUndo();
            this.m_bInitClearUnDo = false;
            this.FixedUpdate();
         }
      }

      //BlocklyHelper.SaveBlockBuffer(kcDataInfo.Account(), this.state.StrategyIndex, this.m_workspace); // 本機存檔

      /* ------------------------------------------------------------ */
      // 範例策略 等到jsCode產生完後需要存檔
      if (this.m_bLoadedSave) {
         await this.SaveBlocks();
         this.m_bLoadedSave = false;
      }

      /* ------------------------------------------------------------ */
      // 名稱.描述變更
      if (this.state.StrategyFile) {
         const StrategyName = BlocklyHelper.GetStrategyName(this.m_workspace);
         const Description = BlocklyHelper.GetDescription(this.m_workspace);

         // StrategyName Change
         if (StrategyName !== this.state.StrategyFile.StrategyName) {
            this.state.StrategyFile.StrategyName = StrategyName;
            this.setState({});
         }
         //Description Change
         if (Description !== this.state.StrategyFile.Description) {
            // 送callback
            this.state.StrategyFile.Description = Description;
            this.setState({});
         }
      }
   };

   private _OnWorkspaceChange = (workspace: WorkspaceSvg) => {
      this.m_workspace = workspace;

      // Xml 換行時較JSon小
      var code_Xml = BlocklyHelper.getBlockDefinition_XML(workspace, true, true);

      // JSon 換行時較XML小
      var code_JSON = BlocklyHelper.getBlockDefinition_JSon(workspace, true, true);

      // JavaScript
      const code_JS = Blockly.JavaScript.workspaceToCode(workspace);

      this.setState({
         Code_JS: code_JS,
         //Code_CS: code_CS,
         XML: code_Xml,
         JSon: code_JSON,
      });
   };

   private FixedUpdate = () => {
      this.setState({ FixedUpdate: this.state.FixedUpdate + 1 });
   };
   private setStateAsync = (state: {}) => {
      return new Promise((resolve) => {
         this.setState(state, resolve as () => void);
      });
   };

   // 伺服器上傳
   private SaveBlocks = async (): Promise<boolean> => {
      if (!this.state.StrategyFile) return false;
      if (!this.CheckAllBlock()) return false;

      await this.setStateAsync({ IOLoading: true });

      const { uid, StrategyIndex } = this.state.StrategyFile;
      const { XML, Code_JS: Code } = this.state;
      const Version = BlocklyHelper.GetCurrentVersion();
      const StrategyName = BlocklyHelper.GetStrategyName(this.m_workspace);
      const Description = BlocklyHelper.GetDescription(this.m_workspace);

      const mdSave: UpdateStrategyParamModel = { uid, StrategyIndex, Version, StrategyName, Description, XML, Code };
      const SaveRes = await kcDataInfo.UpdateStrategy(mdSave);

      if (SaveRes.Success) {
         this.ClearTrashcan(true); // 清空垃圾桶
         this.m_workspace?.clearUndo(); // 清除上一步

         await ShareApi.Callbacks.onStrategyUpdate();
         await this.setStateAsync({ IOLoading: false, StrategyFile: mdSave });
      }

      if (!SaveRes.Success) {
         await this.setStateAsync({ IOLoading: false });

         this.m_DialogRef?.current?.ShowDialog({
            _szContent: "上傳失敗",
            _szOkButtonText: "確定",
         });
         return false;
      }

      return true;
   };

   // 伺服器下載
   private LoadBlocks = async () => {
      // if (!this.m_workspace || !BlockStorage.CanLoad) return;
      // const BlockData = BlockStorage.LoadBlocks();
      if (!this.m_workspace) return;
      await this.setStateAsync({ IOLoading: true });

      const ReadRes = await kcDataInfo.GetStrategy(this.state.StrategyIndex);
      await this.setStateAsync({ IOLoading: false });

      // 讀檔失敗, 這個失敗可能需要跳回Login頁面
      if (!ReadRes.Success) return this.m_DialogRef?.current?.ShowDialog({ _szContent: ReadRes.Message, _szOkButtonText: "關閉" });
      if (!ReadRes?.Data?.XML) return this.m_DialogRef?.current?.ShowDialog({ _szContent: "查無資料", _szOkButtonText: "關閉" });

      const BlockData = ReadRes.Data.XML;
      try {
         var xml = Blockly.Xml.textToDom(BlockData); // 嘗試轉換xml

         /* ------------------------------------ */
         // 彈出刪除Message
         var AllBlocks = this.m_workspace.getAllBlocks(false);
         let bNeedConfirm = AllBlocks.length > 0;
         if (AllBlocks.length === 1) {
            const block0 = AllBlocks[0];
            if (block0.type === "block_mainfunction") bNeedConfirm = false;
         }

         if (bNeedConfirm) {
            let szConfirmMsg = (Blockly.Msg.DELETE_X_BLOCKS as string).replace("%1", `${AllBlocks.length}`);
            if (!window.confirm(szConfirmMsg)) return;
         }
         /* ------------------------------------ */

         this.m_workspace.clear();
         this.m_workspace.clearUndo(); // 清除上一步

         // 以xml匯入
         Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, this.m_workspace);

         this.ClearTrashcan(true); // 清空垃圾桶
         this.m_workspace.clearUndo(); // 清除上一步
         //this.m_workspace.scrollCenter();
      } catch {
         return;
      }
   };

   // 伺服器下載 - 現在給ShareApi.ChangeStrategy使用, 但沒目前沒用到, 也應該要由props更改
   private ChangeLoadBlocks = async (_StrategyIndex: number) => {
      // if (!this.m_workspace || !BlockStorage.CanLoad) return;
      // const BlockData = BlockStorage.LoadBlocks();
      if (!this.m_workspace) return;
      await this.setStateAsync({ IOLoading: true, StrategyIndex: _StrategyIndex });

      const ReadRes = await kcDataInfo.GetStrategy(_StrategyIndex);
      await this.setStateAsync({ IOLoading: false });

      if (!ReadRes?.Success) return; // 讀檔失敗, 這個失敗可能需要跳回Login頁面
      var BlockData = ReadRes.Data.XML;
      if (!BlockData) BlockData = BlockConfig.INITIAL_XML;

      try {
         var xml = Blockly.Xml.textToDom(BlockData); // 嘗試轉換xml

         this.m_workspace.clear();
         this.m_workspace.clearUndo(); // 清除上一步

         // 以xml匯入
         Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, this.m_workspace);

         this.ClearTrashcan(true); // 清空垃圾桶
         this.m_workspace.clearUndo(); // 清除上一步
         //this.m_workspace.scrollCenter();
      } catch {
         return;
      }
   };

   // 切換範例程式
   private ShowExampleBlocks = (_nIdx: number) => {
      if (!this.m_workspace || _nIdx < 0 || _nIdx >= ExampleList.length) return;

      const xmlString = ExampleList[_nIdx]?.xml;
      if (!xmlString) return;

      try {
         var xml = Blockly.Xml.textToDom(xmlString); // 嘗試轉換xml

         /* ------------------------------------ */
         // 彈出刪除Message
         var AllBlocks = this.m_workspace.getAllBlocks(false);
         let bNeedConfirm = AllBlocks.length > 0;
         if (AllBlocks.length === 1) {
            const block0 = AllBlocks[0];
            if (block0.type === "block_mainfunction") bNeedConfirm = false;
         }

         if (bNeedConfirm) {
            let szConfirmMsg = (Blockly.Msg.DELETE_X_BLOCKS as string).replace("%1", `${AllBlocks.length}`);
            if (!window.confirm(szConfirmMsg)) return;
         }
         /* ------------------------------------ */

         this.m_workspace.clear();
         this.m_workspace.clearUndo(); // 清除上一步

         // 以xml匯入
         Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, this.m_workspace);

         this.ClearTrashcan(true); // 清空垃圾桶
         this.m_workspace.scroll(0, 0);
      } catch {
         return;
      }
   };

   // 下載範例程式檔案(.ts)
   private DownloadTSFile = () => {
      this.m_workspace?.cleanUp();

      let DisplayName_prompt = prompt("Display Name", BlocklyHelper.GetStrategyName(this.m_workspace));
      let szDisplayName = DisplayName_prompt ?? "";

      let Name = prompt("範例(檔案)名稱", "");
      if (!Name || Name.length === 0) return;

      let FilePath = Name;
      if (FilePath.indexOf(".") < 0) FilePath += ".ts";

      let szMXL = this.state.XML;
      szMXL = szMXL.replaceAll("\n", "\n  ");

      let Lines: string[] = [];
      Lines.push('import ExampleType from "./type";\n');
      Lines.push("\n");
      Lines.push(`const ${Name}: ExampleType = {\n`);
      Lines.push(`Name: "${szDisplayName}",\n`);
      Lines.push(`xml: \`\n  ${szMXL}\`,\n`);
      Lines.push("};\n");
      Lines.push(`export default ${Name};`);

      const element = document.createElement("a");
      const file = new Blob(Lines, { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = FilePath;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
   };

   // 下載Javascript檔案(.js)
   private DownloadJsCodeFile = () => {
      this.m_workspace?.cleanUp();

      let Name = prompt("檔案名稱", "code");
      if (!Name || Name.length === 0) return;

      let FilePath = Name;
      if (FilePath.indexOf(".") < 0) FilePath += ".js";

      let szCode_JS = this.state.Code_JS;

      const element = document.createElement("a");
      const file = new Blob([szCode_JS], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = FilePath;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
   };

   // 清空垃圾桶
   private ClearTrashcan = (_bTimeout: boolean = false) => {
      if (!this.m_workspace) return;

      if (_bTimeout)
         setTimeout(() => {
            this.ClearTrashcan(false);
         }, 1);
      else this.m_workspace.trashcan.emptyContents();

      // https://developers.google.com/blockly/reference/js/Blockly.Trashcan

      //this.m_workspace.trashcan.emptyContents(); // 清空垃圾桶
      //this.m_workspace.trashcan.click(); // 打開垃圾桶
      //this.m_workspace.trashcan.openFlyout(); // 打開垃圾桶
      //this.m_workspace.trashcan.closeFlyout(); // 關閉垃圾桶

      //this.m_workspace.trashcan.setLidOpen() // 打開(關閉)垃圾桶蓋子
      //this.m_workspace.trashcan.isLidOpen // 垃圾桶蓋子是否打開
   };

   // 手動更新Size
   private ReSizeWorkspace = () => {
      if (!this.m_workspace) return;

      this.m_workspace.setCachedParentSvgSize(this.m_workspace.getInjectionDiv().clientWidth, this.m_workspace.getInjectionDiv().clientHeight);
      this.m_workspace.resize();
   };

   // 檢查Block
   private CheckAllBlock = (): boolean => {
      if (!this.m_workspace) return false;

      const TopBlocks = this.m_workspace.getTopBlocks(false);

      // 移除所有Select
      this.m_workspace.getAllBlocks(false).forEach((q) => (q as BlockSvg).removeSelect());

      const mdCheckMain = BlocklyHelper.CheckBlock_MainBlock(TopBlocks);
      if (!mdCheckMain.Resault) {
         const mdBlink = BlocklyHelper.StartBlinkBlocks(mdCheckMain.FailBlocks);
         this.m_DialogRef?.current?.ShowDialog({
            _szContent: mdCheckMain.FailMsg,
            _szOkButtonText: "確定",
            onClose(_Res) {
               mdBlink.Stop();
            },
         });
         return false;
      }
      //if (!BlocklyHelper.CheckBlock_MainBlock(TopBlocks)) return false;

      for (let Block of TopBlocks) {
         const mdCheckBlock = BlocklyHelper.CheckBlock_InOut(Block);
         if (!mdCheckBlock.Resault) {
            const mdBlink = BlocklyHelper.StartBlinkBlocks(mdCheckBlock.FailBlocks);
            this.m_DialogRef?.current?.ShowDialog({
               _szContent: mdCheckBlock.FailMsg,
               _szOkButtonText: "確定",
               onClose(_Res) {
                  mdBlink.Stop();
               },
            });
            return false;
         }
      }
      return true;
   };

   // 登出
   private LogOut = async () => {
      kcDataInfo.LogOut();
      //this.props.Route.Navigate("/Login");
      window.location.reload();
   };

   private GetCanUnDo = () => {
      if (this.m_bInitClearUnDo) return false;
      return (this.m_workspace?.getUndoStack().length ?? 0) > 0;
   };
   private GetCanReDo = () => {
      return (this.m_workspace?.getRedoStack().length ?? 0) > 0;
   };

   // 顯示程式碼TextBox
   private ShowEditText = (_Type: eEditType) => {
      if (this.state.CurrentEditState === _Type) _Type = eEditType.Blockly;

      this.setState({ CurrentEditState: _Type }, this.ReSizeWorkspace);
   };

   private GetIConColor = (_bDisable?: boolean) => {
      if (!!_bDisable) return ICon_Color_Disable;
      return ICon_Color;
   };

   private GetIConColor_TradeApp = (_bDisable?: boolean) => {
      if (!!_bDisable) return ICon_Color_TradeApp_Disable;
      return ICon_Color_TradeApp;
   };
   private GetIConColor_Green_TradeApp = (_bDisable?: boolean) => {
      if (!!_bDisable) return "#30B030";
      return ICon_Color_TradeApp;
   };

   /* ------------------------------------------------------------ */
   // render
   render_StrategyInfo = () => {
      if (!this.state.StrategyFile) return <div style={{ width: "100%", height: "20px", backgroundColor: "#00000016" }}>null Strategy</div>;
      const { uid, StrategyIndex, StrategyName, Description } = this.state.StrategyFile;

      return (
         <div style={{ width: "100%", backgroundColor: "#00000016", display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 0.2 }}>{`Index: ${StrategyIndex}`}</div>
            <div style={{ flex: 1, marginLeft: "15px" }}>{`uid: ${uid}`}</div>
            <div style={{ flex: 1, marginLeft: "15px" }}>{`Name: ${StrategyName}`}</div>
            <div style={{ flex: 1, marginLeft: "15px" }}>{`Desc: ${Description}`}</div>
         </div>
      );
   };
   render_Top = (classes: any, CanUnDo: boolean, CanReDo: boolean, CanRemoveAll: boolean) => {
      // Web Mode
      if (!this.m_bTraderAppMode) return this.render_Top_Web(classes, CanUnDo, CanReDo, CanRemoveAll);
      // TradeApp Mode
      else return this.render_Top_TradeApp(classes, CanUnDo, CanReDo, CanRemoveAll);
   };

   render_Top_Web = (classes: any, CanUnDo: boolean, CanReDo: boolean, CanRemoveAll: boolean) => {
      const classesName: string = classes.select;
      return (
         <Box sx={{ width: "100%", flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
            <Box component="img" alt="logo" src={favicon} sx={{ marginLeft: "15px", marginRight: "15px", height: 82 }} />

            {/* 上面右邊區塊 Box */}
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
               {/* 右邊區塊的上排 Box */}
               <Box sx={{ flex: 1, width: "100%", flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "flex-start", flexWrap: "wrap", paddingTop: "3px", paddingBottom: "3px", marginTop: "10px" }}>
                  {/* 範例 DropDown */}
                  <Select
                     variant="standard"
                     className={classesName}
                     classes={{ icon: classes.icon }}
                     sx={{ width: 200, color: ICon_Color, marginLeft: "15px", marginRight: "10px", marginBottom: "5px" }}
                     value={0}
                     onChange={(e, c) => {
                        this.ShowExampleBlocks(e.target.value as number);
                     }}
                  >
                     {ExampleList.map((q, _nIdx) => (
                        <MenuItem
                           key={`Ex_${_nIdx}`}
                           value={_nIdx}
                           // disabled={q.xml === ""}
                        >
                           {q.Name}
                        </MenuItem>
                     ))}
                  </Select>

                  <Box sx={{ flex: 1 }}></Box>

                  {DebugArea && (
                     <Button
                        style={{
                           //flex: 1,
                           marginLeft: 10,
                           marginRight: 10,
                           textTransform: "none",
                           color: this.state.CurrentEditState === eEditType.Blockly ? "black" : undefined,
                        }}
                        variant={this.state.CurrentEditState === eEditType.Blockly ? "outlined" : "contained"}
                        onClick={() => {
                           this.setState(
                              {
                                 CurrentEditState: eEditType.Blockly,
                                 DebugArea_Width: 600,
                              },
                              this.ReSizeWorkspace
                           );
                        }}
                     >
                        Blockly
                     </Button>
                  )}

                  {DebugArea && (
                     <Button
                        style={{
                           marginLeft: 10,
                           marginRight: 10,
                           textTransform: "none",
                           color: this.state.CurrentEditState === eEditType.XML ? "black" : undefined,
                        }}
                        variant={this.state.CurrentEditState === eEditType.XML ? "outlined" : "contained"}
                        onClick={() => {
                           this.ShowEditText(eEditType.XML);
                        }}
                     >
                        XML
                     </Button>
                  )}

                  {DebugArea && (
                     <Button
                        style={{
                           marginLeft: 10,
                           marginRight: 10,
                           textTransform: "none",
                           color: this.state.CurrentEditState === eEditType.JSon ? "black" : undefined,
                        }}
                        variant={this.state.CurrentEditState === eEditType.JSon ? "outlined" : "contained"}
                        onClick={() => {
                           this.ShowEditText(eEditType.JSon);
                        }}
                     >
                        JSon
                     </Button>
                  )}

                  {ShowJavaScript() && (
                     <Button
                        style={{
                           //flex: 1,
                           marginLeft: 10,
                           marginRight: 10,
                           textTransform: "none",
                           color: this.state.CurrentEditState === eEditType.JS ? "black" : undefined,
                        }}
                        //variant="contained"
                        variant={this.state.CurrentEditState === eEditType.JS ? "outlined" : "contained"}
                        onClick={() => {
                           this.ShowEditText(eEditType.JS);
                        }}
                     >
                        JavaScript
                     </Button>
                  )}

                  {DebugArea && (
                     <Button
                        style={{
                           //flex: 1,
                           marginLeft: 10,
                           marginRight: 10,
                           textTransform: "none",
                        }}
                        variant="contained"
                        onClick={() => {
                           this.DownloadTSFile();
                        }}
                     >
                        下載XML(.ts)檔
                     </Button>
                  )}
                  {DebugArea && (
                     <Button
                        style={{
                           //flex: 1,
                           marginLeft: 10,
                           marginRight: 10,
                           textTransform: "none",
                        }}
                        variant="contained"
                        onClick={() => {
                           this.DownloadJsCodeFile();
                        }}
                     >
                        下載JS Code(.ts)檔
                     </Button>
                  )}
               </Box>

               {/* 右邊區塊的下排 Box */}
               <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "5px", paddingTop: "3px", paddingBottom: "3px" }}>
                  {/* UnDo */}
                  <IconButton
                     style={{ marginLeft: 10, color: this.GetIConColor(!CanUnDo) }}
                     disabled={!CanUnDo}
                     aria-label="undo"
                     title={Blockly.Msg.UNDO}
                     onClick={() => {
                        this.m_workspace?.undo(false);
                     }}
                  >
                     <UndoIcon fontSize="medium" />
                  </IconButton>

                  {/* ReDo */}
                  <IconButton
                     style={{ color: this.GetIConColor(!CanReDo) }}
                     disabled={!CanReDo}
                     aria-label="redo"
                     title={Blockly.Msg.REDO}
                     onClick={() => {
                        this.m_workspace?.undo(true);
                     }}
                  >
                     <RedoIcon fontSize="medium" />
                  </IconButton>

                  {/* Sort */}
                  <IconButton
                     style={{ color: this.GetIConColor(!this.m_workspace || !this.state.StrategyFile) }}
                     disabled={!this.m_workspace || !this.state.StrategyFile}
                     aria-label="sort"
                     title={Blockly.Msg.SortBlocks}
                     onClick={() => {
                        this.m_workspace?.cleanUp();
                        // this.m_workspace?.scroll(0, 0);
                     }}
                  >
                     <SortIcon fontSize="medium" />
                  </IconButton>

                  {/* Delete */}
                  <IconButton
                     style={{
                        marginRight: 10,
                        color: this.GetIConColor(!CanRemoveAll),
                     }}
                     disabled={!CanRemoveAll}
                     aria-label="delete"
                     title={Blockly.Msg.trashTooltip}
                     onClick={() => {
                        var AllBlocks = this.m_workspace?.getAllBlocks(false);
                        if (AllBlocks && AllBlocks.length > 0) {
                           var szMsg: string = Blockly.Msg.DELETE_X_BLOCKS;
                           szMsg = szMsg.replace("%1", `${AllBlocks.length}`);
                           if (window.confirm(szMsg)) this.m_workspace?.clear();
                        }
                     }}
                  >
                     <DeleteIcon fontSize="medium" />
                  </IconButton>

                  {/* UpLoad */}
                  <IconButton style={{ marginLeft: 10, color: this.GetIConColor(!CanUnDo) }} disabled={!CanUnDo} aria-label="UpLoad" title={Blockly.Msg.SaveBlocks} onClick={this.SaveBlocks}>
                     <UpLoadICon fontSize="medium" />
                  </IconButton>

                  {/* DownLoad */}
                  <IconButton style={{ marginRight: 10, color: this.GetIConColor(!this.m_workspace || !this.state.StrategyFile) }} disabled={!this.state.StrategyFile} aria-label="DownLoad" title={Blockly.Msg.LoadBlocks} onClick={this.LoadBlocks}>
                     <DownLoadICon fontSize="medium" />
                  </IconButton>

                  <Box sx={{ flex: 1 }}></Box>

                  {/* LogOut */}

                  <Select
                     variant="standard"
                     className={classesName}
                     classes={{ icon: classes.icon }}
                     style={{ width: "100px", color: ICon_Color, marginLeft: 10, marginRight: 20, marginBottom: 5 }}
                     value={this.state.Locale}
                     onChange={(e, c) => {
                        BlocklyHelper.ChangeLocale(e.target.value, kcDataInfo.Account(), this.state.StrategyIndex, this.m_workspace);
                        this.setState({ Locale: e.target.value });
                     }}
                  >
                     {LanguageList.map((q) => (
                        <MenuItem key={`Language_${q.Name}`} value={q.Name}>
                           {q.Text}
                        </MenuItem>
                     ))}
                  </Select>

                  <IconButton style={{ marginRight: 10, color: this.GetIConColor() }} aria-label="logout" title={Blockly.Msg.LogOut} onClick={this.LogOut}>
                     <ExitToAppIcon fontSize="medium" />
                  </IconButton>
               </Box>
            </Box>
         </Box>
      );
   };

   render_Top_TradeApp = (classes: any, CanUnDo: boolean, CanReDo: boolean, CanRemoveAll: boolean) => {
      if (!this.m_bTraderAppMode) return <></>;
      const classesName: string = classes.select_TradeApp;

      var TopBakcColor = "#EEEEEE";
      //if (ShareApi.TopBackColor) TopBakcColor = ShareApi.TopBackColor;
      return (
         <Box sx={{ width: "100%", flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", backgroundColor: TopBakcColor }}>
            {/* 右邊區塊的下排 Box */}
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-start", flexWrap: "wrap", paddingTop: "3px", paddingBottom: "3px", borderBottom: "1px solid #bcbcbc" }}>
               {/* UnDo */}
               <IconButton
                  style={{ marginLeft: 10, color: this.GetIConColor_TradeApp(!CanUnDo) }}
                  disabled={!CanUnDo}
                  aria-label="undo"
                  title={Blockly.Msg.UNDO}
                  onClick={() => {
                     this.m_workspace?.undo(false);
                  }}
               >
                  <UndoIcon fontSize="medium" />
               </IconButton>

               {/* ReDo */}
               <IconButton
                  style={{ color: this.GetIConColor_TradeApp(!CanReDo) }}
                  disabled={!CanReDo}
                  aria-label="redo"
                  title={Blockly.Msg.REDO}
                  onClick={() => {
                     this.m_workspace?.undo(true);
                  }}
               >
                  <RedoIcon fontSize="medium" />
               </IconButton>

               {/* Sort */}
               <IconButton
                  style={{ color: this.GetIConColor_TradeApp(!this.m_workspace || !this.state.StrategyFile) }}
                  disabled={!this.m_workspace || !this.state.StrategyFile}
                  aria-label="sort"
                  title={Blockly.Msg.SortBlocks}
                  onClick={() => {
                     this.m_workspace?.cleanUp();
                     this.m_workspace?.scroll(0, 0);
                  }}
               >
                  <SortIcon fontSize="medium" />
               </IconButton>

               {/* Delete */}
               <IconButton
                  style={{ marginRight: 10, color: this.GetIConColor_TradeApp(!CanRemoveAll) }}
                  disabled={!CanRemoveAll}
                  aria-label="delete"
                  title={Blockly.Msg.trashTooltip}
                  onClick={() => {
                     var AllBlocks = this.m_workspace?.getAllBlocks(false);
                     if (AllBlocks && AllBlocks.length > 0) {
                        var szMsg: string = Blockly.Msg.DELETE_X_BLOCKS;
                        szMsg = szMsg.replace("%1", `${AllBlocks.length}`);
                        if (window.confirm(szMsg)) this.m_workspace?.clear();
                     }
                  }}
               >
                  <DeleteIcon fontSize="medium" />
               </IconButton>

               <Box sx={{ flex: 1 }}></Box>

               {/* UpLoad */}
               <IconButton style={{ marginLeft: 10, marginRight: 5, color: this.GetIConColor_Green_TradeApp(!CanUnDo) }} disabled={!CanUnDo} aria-label="UpLoad" title={Blockly.Msg.SaveBlocks} onClick={this.SaveBlocks}>
                  <UpLoadICon sx={{ fontSize: "28px" }} />
               </IconButton>
            </Box>
         </Box>
      );
   };

   render_Blockly = () => {
      return (
         <Box sx={{ flex: 1, width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "#fbfbfb" }}>
            <Box sx={{ flex: 1, height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fbfbfb" }}>
               {this.state.InitLoading && <CircularProgress />}
               {!this.state.InitLoading && (
                  <BlocklyWorkspace
                     key={`Strategy${this.state.StrategyIndex}-${this.state.Locale}`}
                     className="fill-height"
                     //className="width-100" // you can use whatever classes are appropriate for your app's CSS
                     toolboxConfiguration={this.state.toolbox} // this must be a JSON toolbox definition
                     initialXml={this.state.XML}
                     workspaceConfiguration={{
                        collapse: true,
                        grid: {
                           spacing: 27,
                           length: 28,
                           colour: "#00000010",
                           snap: true, // 積木貼齊格子
                        },
                        move: {
                           scrollbars: {
                              horizontal: true,
                              vertical: true,
                           },
                           drag: true,
                           wheel: false,
                        },
                        zoom: {
                           controls: true, // 放大縮小按鈕
                           wheel: true, // 設置為true允許鼠標滾輪縮放
                           startScale: 1.0,
                           maxScale: 3,
                           minScale: 0.3,
                           scaleSpeed: 1.05,
                           pinch: true, // 手指縮放
                        },
                        trashcan: true, // 垃圾桶
                     }}
                     onXmlChange={this._OnXmlChange}
                     onWorkspaceChange={this._OnWorkspaceChange}
                     onInject={(_ws) => {
                        _ws.scroll(0, 0);
                     }}
                  />
               )}
            </Box>

            {this.render_CodeText()}
         </Box>
      );
   };

   render_CodeText = () => {
      if (this.state.CurrentEditState === eEditType.Blockly) return <></>;
      return (
         <Box sx={{ width: `${this.state.DebugArea_Width}px`, display: "flex", height: "100%", alignItems: "center", justifyContent: "center", marginLeft: "3px" }}>
            <div
               style={{ flex: 1, borderLeftColor: "#00000060", borderLeftWidth: 3, borderLeftStyle: "dashed", height: "99%", cursor: "w-resize" }}
               onMouseDown={(e) => {
                  if (e.buttons !== 1) return;
                  if (e.nativeEvent.offsetX >= 0) return;
                  window.onmousemove = (e) => {
                     if (this.state.DebugArea_pDown === undefined) return;
                     let posX = e.clientX;
                     let Diff = posX - this.state.DebugArea_pDown.X;
                     let Width = Math.max(80, this.state.DebugArea_pDown.W - Diff);
                     if (Width !== this.state.DebugArea_pDown.X) this.setState({ DebugArea_Width: Width }, this.ReSizeWorkspace);
                  };
                  window.onmouseup = (e) => {
                     window.onmousemove = null;
                     window.onmouseup = null;
                     this.setState({ DebugArea_pDown: undefined });
                  };

                  let DownPoint = {
                     X: e.nativeEvent.clientX,
                     W: this.state.DebugArea_Width,
                  };
                  this.setState({ DebugArea_pDown: DownPoint });
               }}
            >
               <textarea style={{ width: "100%", height: "100%", resize: "none", boxSizing: "border-box" }} readOnly={true} value={this.state.CurrentEditState === eEditType.XML ? this.state.XML : this.state.CurrentEditState === eEditType.JSon ? this.state.JSon : this.state.CurrentEditState === eEditType.JS ? this.state.Code_JS : "undefine"} />
            </div>
         </Box>
      );
   };

   render() {
      ShareApi.BlocklyEdit = this;
      // /* ------------------------------------------ */
      // // 第一次render處理, 貌似比componentDidMount更早
      // if (this.m_bFirstRender) {
      //    this.CheckIsTraderAppMode();
      //    this.m_bFirstRender = false;
      // }
      // /* ------------------------------------------ */

      const { classes } = this.props;
      const classesName: string = classes.select;
      const CanUnDo = this.GetCanUnDo();
      const CanReDo = this.GetCanReDo();
      const CanRemoveAll = (this.m_workspace?.getAllBlocks(false).length ?? 0) > 0;
      return (
         <Box
            sx={{
               height: "100vh",
               bgcolor: "#00000000",
               flexDirection: "column",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            <AlertDialog ref={this.m_DialogRef} />
            <Loading Loading={this.state.IOLoading} />

            {/* 上面Title整體區塊 Box */}
            {this.render_Top(classes, CanUnDo, CanReDo, CanRemoveAll)}
            {/* <this.render_StrategyInfo /> */}
            {this.render_Blockly()}
         </Box>
      );
   }
   renderTop = () => {};
}
const styles = (theme: Theme) => ({
   select: {
      "&:before": {
         borderColor: ICon_Color + "!important",
      },
      "&:after": {
         borderColor: ICon_Color + "!important",
      },
      "&:not(.Mui-disabled):hover::before": {
         borderColor: ICon_Color + "!important",
      },
   },
   icon: {
      fill: ICon_Color + "!important",
   },
   select_TradeApp: {
      "&:before": {
         borderColor: ICon_Color_TradeApp + "!important",
      },
      "&:after": {
         borderColor: ICon_Color_TradeApp + "!important",
      },
      "&:not(.Mui-disabled):hover::before": {
         borderColor: ICon_Color_TradeApp + "!important",
      },
   },
   icon_TradeApp: {
      fill: ICon_Color_TradeApp + "!important",
   },
});

export default withRouter(withStyles(styles)(BlocklyEdit));

type AlertDialogProp = {
   ref?: React.LegacyRef<AlertDialog> | undefined;
   style?: React.CSSProperties | undefined;
};
type AlertDialogState = { Open: boolean };
type AlertDialogShowProp = {
   _szTitle?: string;
   _szContent?: string;
   _szOkButtonText?: string;
   _szCancelButtonText?: string;
   onClose?: (_Res: "OK" | "Cancel") => void;
};
class AlertDialog extends React.Component<AlertDialogProp, AlertDialogState> {
   state: AlertDialogState = {
      Open: false,
   };

   m_fOnClose: ((_Res: "OK" | "Cancel") => void) | undefined = undefined;
   m_szTitle: string | undefined = undefined;
   m_szContent: string | undefined = undefined;
   m_szOkButtonText: string | undefined = undefined;
   m_szCancelButtonText: string | undefined = undefined;

   public ShowDialog = (_OpenProp?: AlertDialogShowProp) => {
      this.m_szTitle = _OpenProp?._szTitle;
      this.m_szContent = _OpenProp?._szContent;
      this.m_szOkButtonText = _OpenProp?._szOkButtonText;
      this.m_szCancelButtonText = _OpenProp?._szCancelButtonText;
      this.m_fOnClose = _OpenProp?.onClose;
      this.setState({ Open: true });
   };

   private onDialogClose = (_reason: "backdropClick" | "escapeKeyDown") => {
      this.m_fOnClose?.call(this, "Cancel");
      this.setState({ Open: false });
   };
   private on_OK_Click = () => {
      this.m_fOnClose?.call(this, "OK");
      this.setState({ Open: false });
   };
   private on_Cancel_Click = () => {
      this.m_fOnClose?.call(this, "Cancel");
      this.setState({ Open: false });
   };

   render() {
      if (!this.state.Open) return <></>;
      return (
         <Dialog
            style={{ ...this.props.style }}
            PaperProps={{ style: { position: "absolute", top: 10 } }}
            // classes={{ paper: this.DialogStyle.dialog }}
            //style={{ position: "absolute", left: 10, top: 50 }}
            fullWidth={true}
            open={this.state.Open}
            onClose={(e, r) => {
               this.onDialogClose(r);
            }}
            //aria-labelledby="child-modal-title"
            //aria-describedby="child-modal-description"
         >
            {this.m_szTitle && <DialogTitle id="alert-dialog-title">{this.m_szTitle}</DialogTitle>}

            {this.m_szContent && (
               <DialogContent>
                  <DialogContentText id="alert-dialog-description">{this.m_szContent}</DialogContentText>
               </DialogContent>
            )}

            {(this.m_szOkButtonText || this.m_szCancelButtonText) && (
               <DialogActions>
                  {this.m_szOkButtonText && <Button onClick={this.on_OK_Click}>{this.m_szOkButtonText}</Button>}
                  {this.m_szCancelButtonText && <Button onClick={this.on_Cancel_Click}>{this.m_szCancelButtonText}</Button>}
               </DialogActions>
            )}
         </Dialog>
      );
   }
}

type LoadingProp = {
   ref?: React.LegacyRef<Loading> | undefined;
   style?: React.CSSProperties | undefined;
   Loading?: boolean | undefined;
};

class Loading extends React.Component<LoadingProp> {
   render() {
      if (!this.props.Loading) return <></>;
      return (
         <Dialog
            style={{ ...this.props.style }}
            PaperProps={{
               style: {
                  backgroundColor: "#00000000", //"#0000004C",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "none",
               },
            }}
            open={!!this.props.Loading}
            onClose={(e, r) => {}}
         >
            <CircularProgress size={100} style={{ margin: 40 }} />
         </Dialog>
      );
   }
}
