import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes } from "./Block_Define";

Blockly.Blocks["block_quote_getbidprice"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("商品");
      this.appendDummyInput().appendField("買價");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Quote);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_quote_getaskprice"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("商品");
      this.appendDummyInput().appendField("賣價");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Quote);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_quote_getpricediff"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("商品");
      this.appendDummyInput().appendField("買賣價差");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Quote);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_quote_getpointdiff"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("商品");
      this.appendDummyInput().appendField("買賣點差");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Quote);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_quote_gethighprice"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("商品");
      this.appendDummyInput().appendField("最高價");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Quote);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_quote_getlowprice"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("商品");
      this.appendDummyInput().appendField("最低價");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Quote);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_quote_getlasttime"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("商品");
      this.appendDummyInput().appendField("最後成交時間");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.DateTime);
      this.setStyle(Keys_Block.Quote);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.JavaScript["block_quote_getbidprice"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Quote_BidPrice(${value_commodity})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_quote_getaskprice"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Quote_AskPrice(${value_commodity})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_quote_getpricediff"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Quote_PriceDiff(${value_commodity})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_quote_getpointdiff"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Quote_PointDiff(${value_commodity})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_quote_gethighprice"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Quote_HighPrice(${value_commodity})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_quote_getlowprice"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Quote_LowPrice(${value_commodity})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_quote_getlasttime"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Quote_LastTime(${value_commodity})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

export const ToolboxItems_Quote = {
   kind: "category",
   name: "%{BKY_catQuote}",
   categorystyle: Keys_Cat.Quote,
   contents: [
      {
         kind: "block",
         blockxml: `<block type="block_quote_getbidprice">
      <value name="Commodity">
        <block type="block_commodity"></block>
      </value>
    </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_quote_getaskprice">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_quote_getpricediff">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_quote_getpointdiff">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_quote_gethighprice">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_quote_getlowprice">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_quote_getlasttime">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
      </block>`,
      },
   ],
};
