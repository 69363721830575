import ExampleType from "./type";

const Bool_Cross: ExampleType = {
   Name: "突破布林通道",
   xml: `
  <xml xmlns="https://developers.google.com/blockly/xml">
    <variables>
      <variable>單號-多單</variable>
      <variable>函式運算結果</variable>
      <variable>收盤價1</variable>
      <variable>單號-空單</variable>
      <variable>收盤價2</variable>
      <variable>布林下線1</variable>
      <variable>布林上線1</variable>
      <variable>布林下線2</variable>
      <variable>布林上線2</variable>
    </variables>
    <block type="block_mainfunction" x="14" y="14">
      <field name="StrategyName">突破布林通道</field>
      <field name="Description">範例策略 - 突破布林通道</field>
      <statement name="fInitialize">
        <block type="variables_set">
          <field name="VAR">單號-多單</field>
          <value name="VALUE">
            <block type="block_order_tradeindex_getlasttradeidx_inventory">
              <field name="BuySellType">Buy</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">單號-空單</field>
              <value name="VALUE">
                <block type="block_order_tradeindex_getlasttradeidx_inventory">
                  <field name="BuySellType">Sell</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">函式運算結果</field>
                  <value name="VALUE">
                    <block type="logic_boolean">
                      <field name="BOOL">FALSE</field>
                    </block>
                  </value>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <statement name="fOnTick">
        <block type="controls_if">
          <mutation else="1"></mutation>
          <value name="IF0">
            <block type="logic_compare">
              <field name="OP">LTE</field>
              <value name="A">
                <block type="variables_get">
                  <field name="VAR">單號-多單</field>
                </block>
              </value>
              <value name="B">
                <block type="math_number">
                  <field name="NUM">0</field>
                </block>
              </value>
            </block>
          </value>
          <statement name="DO0">
            <block type="controls_if">
              <value name="IF0">
                <block type="procedures_callreturn">
                  <mutation name="判斷允許做多"></mutation>
                </block>
              </value>
              <statement name="DO0">
                <block type="variables_set">
                  <field name="VAR">單號-多單</field>
                  <value name="VALUE">
                    <block type="block_order_trade_longin">
                      <field name="Commodity">商品</field>
                      <value name="Commodity">
                        <block type="block_commodity"></block>
                      </value>
                      <value name="HVol">
                        <shadow type="math_number">
                          <field name="NUM">0.01</field>
                        </shadow>
                      </value>
                    </block>
                  </value>
                </block>
              </statement>
            </block>
          </statement>
          <statement name="ELSE">
            <block type="controls_if">
              <value name="IF0">
                <block type="procedures_callreturn">
                  <mutation name="判斷允許多平"></mutation>
                </block>
              </value>
              <statement name="DO0">
                <block type="block_order_trade_out">
                  <value name="Commodity">
                    <shadow type="math_number">
                      <field name="NUM">0</field>
                    </shadow>
                    <block type="variables_get">
                      <field name="VAR">單號-多單</field>
                    </block>
                  </value>
                  <next>
                    <block type="variables_set">
                      <field name="VAR">單號-多單</field>
                      <value name="VALUE">
                        <block type="block_order_tradeindex_getlasttradeidx_inventory">
                          <field name="BuySellType">Buy</field>
                          <value name="Commodity">
                            <block type="block_commodity"></block>
                          </value>
                        </block>
                      </value>
                    </block>
                  </next>
                </block>
              </statement>
            </block>
          </statement>
          <next>
            <block type="controls_if">
              <mutation else="1"></mutation>
              <value name="IF0">
                <block type="logic_compare">
                  <field name="OP">LTE</field>
                  <value name="A">
                    <block type="variables_get">
                      <field name="VAR">單號-空單</field>
                    </block>
                  </value>
                  <value name="B">
                    <block type="math_number">
                      <field name="NUM">0</field>
                    </block>
                  </value>
                </block>
              </value>
              <statement name="DO0">
                <block type="controls_if">
                  <value name="IF0">
                    <block type="procedures_callreturn">
                      <mutation name="判斷允許做空"></mutation>
                    </block>
                  </value>
                  <statement name="DO0">
                    <block type="variables_set">
                      <field name="VAR">單號-空單</field>
                      <value name="VALUE">
                        <block type="block_order_trade_shortin">
                          <field name="Commodity">商品</field>
                          <value name="Commodity">
                            <block type="block_commodity"></block>
                          </value>
                          <value name="HVol">
                            <shadow type="math_number">
                              <field name="NUM">0.01</field>
                            </shadow>
                          </value>
                        </block>
                      </value>
                    </block>
                  </statement>
                </block>
              </statement>
              <statement name="ELSE">
                <block type="controls_if">
                  <value name="IF0">
                    <block type="procedures_callreturn">
                      <mutation name="判斷允許空平"></mutation>
                    </block>
                  </value>
                  <statement name="DO0">
                    <block type="block_order_trade_out">
                      <value name="Commodity">
                        <shadow type="math_number">
                          <field name="NUM">0</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">單號-空單</field>
                        </block>
                      </value>
                      <next>
                        <block type="variables_set">
                          <field name="VAR">單號-空單</field>
                          <value name="VALUE">
                            <block type="block_order_tradeindex_getlasttradeidx_inventory">
                              <field name="BuySellType">Sell</field>
                              <value name="Commodity">
                                <block type="block_commodity"></block>
                              </value>
                            </block>
                          </value>
                        </block>
                      </next>
                    </block>
                  </statement>
                </block>
              </statement>
            </block>
          </next>
        </block>
      </statement>
    </block>
    <block type="procedures_defreturn" x="14" y="797">
      <mutation statements="false"></mutation>
      <field name="NAME">判斷允許做多</field>
      <comment pinned="false" h="36" w="147">使用者自訂此函式</comment>
      <value name="RETURN">
        <block type="procedures_callreturn">
          <mutation name="判斷突破布林下線"></mutation>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="878">
      <mutation statements="false"></mutation>
      <field name="NAME">判斷允許多平</field>
      <comment pinned="false" h="33" w="143">使用者自訂此函式</comment>
      <value name="RETURN">
        <block type="procedures_callreturn">
          <mutation name="判斷突破布林上線"></mutation>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="959">
      <mutation statements="false"></mutation>
      <field name="NAME">判斷允許做空</field>
      <comment pinned="false" h="34" w="151">使用者自訂此函式</comment>
      <value name="RETURN">
        <block type="procedures_callreturn">
          <mutation name="判斷跌破布林上線"></mutation>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="1040">
      <mutation statements="false"></mutation>
      <field name="NAME">判斷允許空平</field>
      <comment pinned="false" h="36" w="148">使用者自訂此函式</comment>
      <value name="RETURN">
        <block type="procedures_callreturn">
          <mutation name="判斷跌破布林下線"></mutation>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="1121">
      <field name="NAME">判斷突破布林下線</field>
      <comment pinned="false" h="80" w="160">描述此函式...</comment>
      <statement name="STACK">
        <block type="variables_set">
          <field name="VAR">收盤價1</field>
          <value name="VALUE">
            <block type="block_tecp_kline">
              <field name="Value">ClosePrice</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
              <value name="HParam">
                <block type="block_hparam">
                  <field name="HParam">1Minute</field>
                </block>
              </value>
              <value name="Index">
                <block type="math_number">
                  <field name="NUM">1</field>
                </block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">收盤價2</field>
              <value name="VALUE">
                <block type="block_tecp_kline">
                  <field name="Value">ClosePrice</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                  <value name="HParam">
                    <block type="block_hparam">
                      <field name="HParam">1Minute</field>
                    </block>
                  </value>
                  <value name="Index">
                    <block type="math_number">
                      <field name="NUM">2</field>
                    </block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">布林下線1</field>
                  <value name="VALUE">
                    <block type="block_tecp_bband">
                      <field name="Value">MaStrut</field>
                      <value name="Commodity">
                        <block type="block_commodity"></block>
                      </value>
                      <value name="HParam">
                        <block type="block_hparam">
                          <field name="HParam">1Minute</field>
                        </block>
                      </value>
                      <value name="MaBase">
                        <block type="math_number">
                          <field name="NUM">20</field>
                        </block>
                      </value>
                      <value name="SDBase">
                        <block type="math_number">
                          <field name="NUM">2</field>
                        </block>
                      </value>
                      <value name="Index">
                        <block type="math_number">
                          <field name="NUM">1</field>
                        </block>
                      </value>
                    </block>
                  </value>
                  <next>
                    <block type="variables_set">
                      <field name="VAR">布林下線2</field>
                      <value name="VALUE">
                        <block type="block_tecp_bband">
                          <field name="Value">MaStrut</field>
                          <value name="Commodity">
                            <block type="block_commodity"></block>
                          </value>
                          <value name="HParam">
                            <block type="block_hparam">
                              <field name="HParam">1Minute</field>
                            </block>
                          </value>
                          <value name="MaBase">
                            <block type="math_number">
                              <field name="NUM">20</field>
                            </block>
                          </value>
                          <value name="SDBase">
                            <block type="math_number">
                              <field name="NUM">2</field>
                            </block>
                          </value>
                          <value name="Index">
                            <block type="math_number">
                              <field name="NUM">2</field>
                            </block>
                          </value>
                        </block>
                      </value>
                      <next>
                        <block type="controls_if">
                          <mutation else="1"></mutation>
                          <value name="IF0">
                            <block type="logic_operation">
                              <field name="OP">AND</field>
                              <value name="A">
                                <block type="logic_compare">
                                  <field name="OP">GT</field>
                                  <value name="A">
                                    <block type="variables_get">
                                      <field name="VAR">收盤價1</field>
                                    </block>
                                  </value>
                                  <value name="B">
                                    <block type="variables_get">
                                      <field name="VAR">布林下線1</field>
                                    </block>
                                  </value>
                                </block>
                              </value>
                              <value name="B">
                                <block type="logic_compare">
                                  <field name="OP">LTE</field>
                                  <value name="A">
                                    <block type="variables_get">
                                      <field name="VAR">收盤價2</field>
                                    </block>
                                  </value>
                                  <value name="B">
                                    <block type="variables_get">
                                      <field name="VAR">布林下線2</field>
                                    </block>
                                  </value>
                                </block>
                              </value>
                            </block>
                          </value>
                          <statement name="DO0">
                            <block type="variables_set">
                              <field name="VAR">函式運算結果</field>
                              <value name="VALUE">
                                <block type="logic_boolean">
                                  <field name="BOOL">TRUE</field>
                                </block>
                              </value>
                            </block>
                          </statement>
                          <statement name="ELSE">
                            <block type="variables_set">
                              <field name="VAR">函式運算結果</field>
                              <value name="VALUE">
                                <block type="logic_boolean">
                                  <field name="BOOL">FALSE</field>
                                </block>
                              </value>
                            </block>
                          </statement>
                        </block>
                      </next>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <value name="RETURN">
        <block type="variables_get">
          <field name="VAR">函式運算結果</field>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="1472">
      <field name="NAME">判斷跌破布林下線</field>
      <comment pinned="false" h="80" w="160">描述此函式...</comment>
      <statement name="STACK">
        <block type="variables_set">
          <field name="VAR">收盤價1</field>
          <value name="VALUE">
            <block type="block_tecp_kline">
              <field name="Value">ClosePrice</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
              <value name="HParam">
                <block type="block_hparam">
                  <field name="HParam">1Minute</field>
                </block>
              </value>
              <value name="Index">
                <block type="math_number">
                  <field name="NUM">1</field>
                </block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">收盤價2</field>
              <value name="VALUE">
                <block type="block_tecp_kline">
                  <field name="Value">ClosePrice</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                  <value name="HParam">
                    <block type="block_hparam">
                      <field name="HParam">1Minute</field>
                    </block>
                  </value>
                  <value name="Index">
                    <block type="math_number">
                      <field name="NUM">2</field>
                    </block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">布林下線1</field>
                  <value name="VALUE">
                    <block type="block_tecp_bband">
                      <field name="Value">MaStrut</field>
                      <value name="Commodity">
                        <block type="block_commodity"></block>
                      </value>
                      <value name="HParam">
                        <block type="block_hparam">
                          <field name="HParam">1Minute</field>
                        </block>
                      </value>
                      <value name="MaBase">
                        <block type="math_number">
                          <field name="NUM">20</field>
                        </block>
                      </value>
                      <value name="SDBase">
                        <block type="math_number">
                          <field name="NUM">2</field>
                        </block>
                      </value>
                      <value name="Index">
                        <block type="math_number">
                          <field name="NUM">1</field>
                        </block>
                      </value>
                    </block>
                  </value>
                  <next>
                    <block type="variables_set">
                      <field name="VAR">布林下線2</field>
                      <value name="VALUE">
                        <block type="block_tecp_bband">
                          <field name="Value">MaStrut</field>
                          <value name="Commodity">
                            <block type="block_commodity"></block>
                          </value>
                          <value name="HParam">
                            <block type="block_hparam">
                              <field name="HParam">1Minute</field>
                            </block>
                          </value>
                          <value name="MaBase">
                            <block type="math_number">
                              <field name="NUM">20</field>
                            </block>
                          </value>
                          <value name="SDBase">
                            <block type="math_number">
                              <field name="NUM">2</field>
                            </block>
                          </value>
                          <value name="Index">
                            <block type="math_number">
                              <field name="NUM">2</field>
                            </block>
                          </value>
                        </block>
                      </value>
                      <next>
                        <block type="controls_if">
                          <mutation else="1"></mutation>
                          <value name="IF0">
                            <block type="logic_operation">
                              <field name="OP">AND</field>
                              <value name="A">
                                <block type="logic_compare">
                                  <field name="OP">LT</field>
                                  <value name="A">
                                    <block type="variables_get">
                                      <field name="VAR">收盤價1</field>
                                    </block>
                                  </value>
                                  <value name="B">
                                    <block type="variables_get">
                                      <field name="VAR">布林下線1</field>
                                    </block>
                                  </value>
                                </block>
                              </value>
                              <value name="B">
                                <block type="logic_compare">
                                  <field name="OP">GTE</field>
                                  <value name="A">
                                    <block type="variables_get">
                                      <field name="VAR">收盤價2</field>
                                    </block>
                                  </value>
                                  <value name="B">
                                    <block type="variables_get">
                                      <field name="VAR">布林下線2</field>
                                    </block>
                                  </value>
                                </block>
                              </value>
                            </block>
                          </value>
                          <statement name="DO0">
                            <block type="variables_set">
                              <field name="VAR">函式運算結果</field>
                              <value name="VALUE">
                                <block type="logic_boolean">
                                  <field name="BOOL">TRUE</field>
                                </block>
                              </value>
                            </block>
                          </statement>
                          <statement name="ELSE">
                            <block type="variables_set">
                              <field name="VAR">函式運算結果</field>
                              <value name="VALUE">
                                <block type="logic_boolean">
                                  <field name="BOOL">FALSE</field>
                                </block>
                              </value>
                            </block>
                          </statement>
                        </block>
                      </next>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <value name="RETURN">
        <block type="variables_get">
          <field name="VAR">函式運算結果</field>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="1823">
      <field name="NAME">判斷突破布林上線</field>
      <comment pinned="false" h="80" w="160">描述此函式...</comment>
      <statement name="STACK">
        <block type="variables_set">
          <field name="VAR">收盤價1</field>
          <value name="VALUE">
            <block type="block_tecp_kline">
              <field name="Value">ClosePrice</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
              <value name="HParam">
                <block type="block_hparam">
                  <field name="HParam">1Minute</field>
                </block>
              </value>
              <value name="Index">
                <block type="math_number">
                  <field name="NUM">1</field>
                </block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">收盤價2</field>
              <value name="VALUE">
                <block type="block_tecp_kline">
                  <field name="Value">ClosePrice</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                  <value name="HParam">
                    <block type="block_hparam">
                      <field name="HParam">1Minute</field>
                    </block>
                  </value>
                  <value name="Index">
                    <block type="math_number">
                      <field name="NUM">2</field>
                    </block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">布林上線1</field>
                  <value name="VALUE">
                    <block type="block_tecp_bband">
                      <field name="Value">MaPressure</field>
                      <value name="Commodity">
                        <block type="block_commodity"></block>
                      </value>
                      <value name="HParam">
                        <block type="block_hparam">
                          <field name="HParam">1Minute</field>
                        </block>
                      </value>
                      <value name="MaBase">
                        <block type="math_number">
                          <field name="NUM">20</field>
                        </block>
                      </value>
                      <value name="SDBase">
                        <block type="math_number">
                          <field name="NUM">2</field>
                        </block>
                      </value>
                      <value name="Index">
                        <block type="math_number">
                          <field name="NUM">1</field>
                        </block>
                      </value>
                    </block>
                  </value>
                  <next>
                    <block type="variables_set">
                      <field name="VAR">布林上線2</field>
                      <value name="VALUE">
                        <block type="block_tecp_bband">
                          <field name="Value">MaPressure</field>
                          <value name="Commodity">
                            <block type="block_commodity"></block>
                          </value>
                          <value name="HParam">
                            <block type="block_hparam">
                              <field name="HParam">1Minute</field>
                            </block>
                          </value>
                          <value name="MaBase">
                            <block type="math_number">
                              <field name="NUM">20</field>
                            </block>
                          </value>
                          <value name="SDBase">
                            <block type="math_number">
                              <field name="NUM">2</field>
                            </block>
                          </value>
                          <value name="Index">
                            <block type="math_number">
                              <field name="NUM">2</field>
                            </block>
                          </value>
                        </block>
                      </value>
                      <next>
                        <block type="controls_if">
                          <mutation else="1"></mutation>
                          <value name="IF0">
                            <block type="logic_operation">
                              <field name="OP">AND</field>
                              <value name="A">
                                <block type="logic_compare">
                                  <field name="OP">GT</field>
                                  <value name="A">
                                    <block type="variables_get">
                                      <field name="VAR">收盤價1</field>
                                    </block>
                                  </value>
                                  <value name="B">
                                    <block type="variables_get">
                                      <field name="VAR">布林上線1</field>
                                    </block>
                                  </value>
                                </block>
                              </value>
                              <value name="B">
                                <block type="logic_compare">
                                  <field name="OP">LTE</field>
                                  <value name="A">
                                    <block type="variables_get">
                                      <field name="VAR">收盤價2</field>
                                    </block>
                                  </value>
                                  <value name="B">
                                    <block type="variables_get">
                                      <field name="VAR">布林上線2</field>
                                    </block>
                                  </value>
                                </block>
                              </value>
                            </block>
                          </value>
                          <statement name="DO0">
                            <block type="variables_set">
                              <field name="VAR">函式運算結果</field>
                              <value name="VALUE">
                                <block type="logic_boolean">
                                  <field name="BOOL">TRUE</field>
                                </block>
                              </value>
                            </block>
                          </statement>
                          <statement name="ELSE">
                            <block type="variables_set">
                              <field name="VAR">函式運算結果</field>
                              <value name="VALUE">
                                <block type="logic_boolean">
                                  <field name="BOOL">FALSE</field>
                                </block>
                              </value>
                            </block>
                          </statement>
                        </block>
                      </next>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <value name="RETURN">
        <block type="variables_get">
          <field name="VAR">函式運算結果</field>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="2174">
      <field name="NAME">判斷跌破布林上線</field>
      <comment pinned="false" h="80" w="160">描述此函式...</comment>
      <statement name="STACK">
        <block type="variables_set">
          <field name="VAR">收盤價1</field>
          <value name="VALUE">
            <block type="block_tecp_kline">
              <field name="Value">ClosePrice</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
              <value name="HParam">
                <block type="block_hparam">
                  <field name="HParam">1Minute</field>
                </block>
              </value>
              <value name="Index">
                <block type="math_number">
                  <field name="NUM">1</field>
                </block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">收盤價2</field>
              <value name="VALUE">
                <block type="block_tecp_kline">
                  <field name="Value">ClosePrice</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                  <value name="HParam">
                    <block type="block_hparam">
                      <field name="HParam">1Minute</field>
                    </block>
                  </value>
                  <value name="Index">
                    <block type="math_number">
                      <field name="NUM">2</field>
                    </block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">布林上線1</field>
                  <value name="VALUE">
                    <block type="block_tecp_bband">
                      <field name="Value">MaPressure</field>
                      <value name="Commodity">
                        <block type="block_commodity"></block>
                      </value>
                      <value name="HParam">
                        <block type="block_hparam">
                          <field name="HParam">1Minute</field>
                        </block>
                      </value>
                      <value name="MaBase">
                        <block type="math_number">
                          <field name="NUM">20</field>
                        </block>
                      </value>
                      <value name="SDBase">
                        <block type="math_number">
                          <field name="NUM">2</field>
                        </block>
                      </value>
                      <value name="Index">
                        <block type="math_number">
                          <field name="NUM">1</field>
                        </block>
                      </value>
                    </block>
                  </value>
                  <next>
                    <block type="variables_set">
                      <field name="VAR">布林上線2</field>
                      <value name="VALUE">
                        <block type="block_tecp_bband">
                          <field name="Value">MaPressure</field>
                          <value name="Commodity">
                            <block type="block_commodity"></block>
                          </value>
                          <value name="HParam">
                            <block type="block_hparam">
                              <field name="HParam">1Minute</field>
                            </block>
                          </value>
                          <value name="MaBase">
                            <block type="math_number">
                              <field name="NUM">20</field>
                            </block>
                          </value>
                          <value name="SDBase">
                            <block type="math_number">
                              <field name="NUM">2</field>
                            </block>
                          </value>
                          <value name="Index">
                            <block type="math_number">
                              <field name="NUM">2</field>
                            </block>
                          </value>
                        </block>
                      </value>
                      <next>
                        <block type="controls_if">
                          <mutation else="1"></mutation>
                          <value name="IF0">
                            <block type="logic_operation">
                              <field name="OP">AND</field>
                              <value name="A">
                                <block type="logic_compare">
                                  <field name="OP">LT</field>
                                  <value name="A">
                                    <block type="variables_get">
                                      <field name="VAR">收盤價1</field>
                                    </block>
                                  </value>
                                  <value name="B">
                                    <block type="variables_get">
                                      <field name="VAR">布林上線1</field>
                                    </block>
                                  </value>
                                </block>
                              </value>
                              <value name="B">
                                <block type="logic_compare">
                                  <field name="OP">GTE</field>
                                  <value name="A">
                                    <block type="variables_get">
                                      <field name="VAR">收盤價2</field>
                                    </block>
                                  </value>
                                  <value name="B">
                                    <block type="variables_get">
                                      <field name="VAR">布林上線2</field>
                                    </block>
                                  </value>
                                </block>
                              </value>
                            </block>
                          </value>
                          <statement name="DO0">
                            <block type="variables_set">
                              <field name="VAR">函式運算結果</field>
                              <value name="VALUE">
                                <block type="logic_boolean">
                                  <field name="BOOL">TRUE</field>
                                </block>
                              </value>
                            </block>
                          </statement>
                          <statement name="ELSE">
                            <block type="variables_set">
                              <field name="VAR">函式運算結果</field>
                              <value name="VALUE">
                                <block type="logic_boolean">
                                  <field name="BOOL">FALSE</field>
                                </block>
                              </value>
                            </block>
                          </statement>
                        </block>
                      </next>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <value name="RETURN">
        <block type="variables_get">
          <field name="VAR">函式運算結果</field>
        </block>
      </value>
    </block>
  </xml>`,
};
export default Bool_Cross;
