export const MSG = {
   title: "Code",
   blocks: "Blocks",
   linkTooltip: "Save and link to blocks.",
   runTooltip: "Run the program defined by the blocks in the workspace.",
   badCode: "Program error:\n%1",
   timeout: "Maximum execution iterations exceeded.",
   trashTooltip: "Discard all blocks.",
   catLogic: "Logic",
   catLoops: "Loops",
   catMath: "Math",
   catText: "Text",
   catLists: "Lists",
   catColour: "Colour",
   catVariables: "Variables",
   catFunctions: "Functions",
   listVariable: "list",
   textVariable: "text",
   httpRequestError: "There was a problem with the request.",
   linkAlert: "Share your blocks with this link:\n\n%1",
   hashError: "Sorry, '%1' doesn't correspond with any saved program.",
   xmlError: "Could not load your saved file. Perhaps it was created with a different version of Blockly?",
   badXml: "Error parsing XML:\n%1\n\nSelect 'OK' to abandon your changes or 'Cancel' to further edit the XML.",

   catMain: "Main",
   catDateTime: "DateTime",
   catCommodity: "Commodity",
   catQuote: "Quote",
   catTECP: "Indicator",
   catOrder: "Trade",
   catOrder_Trade: "Order",
   catOrder_Entrust: "Entrust",
   catOrder_Inventory: "Inventory",
   catOrder_History: "History",
   catOrder_TradeIndex: "Order index",
   catAccount: "Account",

   SaveBlocks: "Save",
   LoadBlocks: "Load",
   SortBlocks: "Sort Blocks",
   LogOut: "logout",

   // Global
   blockMsg_Global_Commodity: "Current Commodity",
};
