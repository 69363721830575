import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes } from "./Block_Define";

Blockly.Blocks["block_order_history_getinfo_double"] = {
   init: function () {
      this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("歷史").appendField("取得數據(數字)").appendField("單號");
      this.appendDummyInput()
         .appendField("數據")
         .appendField(
            new Blockly.FieldDropdown([
               ["手數", "HVol"],
               ["下單價格", "InPrice"],
               ["平倉價格", "OutPrice"],
               ["停損價格", "StopLossPrice"],
               ["停利價格", "StopProfitPrice"],
               ["庫存費", "SwapMoney"],
               ["獲利", "Profit"],
               ["佣金", "CommissionMoney"],
            ]),
            "Field"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_History);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_history_getinfo_string"] = {
   init: function () {
      this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("歷史").appendField("取得數據(文字)").appendField("單號");
      this.appendDummyInput()
         .appendField("數據")
         .appendField(new Blockly.FieldDropdown([["商品", "StockCode"]]), "Field");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.String);
      this.setStyle(Keys_Block.Order_History);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_history_getinfo_datetime"] = {
   init: function () {
      this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("歷史").appendField("取得數據(時間)").appendField("單號");
      this.appendDummyInput()
         .appendField("數據")
         .appendField(
            new Blockly.FieldDropdown([
               ["下單時間", "InTime"],
               ["平倉時間", "OutTime"],
            ]),
            "Field"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.DateTime);
      this.setStyle(Keys_Block.Order_History);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_history_getinfo_boolean"] = {
   init: function () {
      this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("歷史").appendField("取得數據(是否)").appendField("單號");
      this.appendDummyInput()
         .appendField("數據")
         .appendField(
            new Blockly.FieldDropdown([
               ["是否為多單", "IsBuy"],
               ["是否為空單", "IsSell"],
            ]),
            "Field"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Boolean);
      this.setStyle(Keys_Block.Order_History);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.JavaScript["block_order_history_getinfo_double"] = function (block: any) {
   var value_TradeIdx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);

   var dropdown_Field = block.getFieldValue("Field");

   // TODO: Assemble JavaScript into code variable.
   var code = `_History_GetInfo_Double(${value_TradeIdx}, '${dropdown_Field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_history_getinfo_string"] = function (block: any) {
   var value_TradeIdx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);

   var dropdown_Field = block.getFieldValue("Field");

   // TODO: Assemble JavaScript into code variable.
   var code = `_History_GetInfo_String(${value_TradeIdx}, '${dropdown_Field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_history_getinfo_datetime"] = function (block: any) {
   var value_TradeIdx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);

   var dropdown_Field = block.getFieldValue("Field");

   // TODO: Assemble JavaScript into code variable.
   var code = `_History_GetInfo_DateTime(${value_TradeIdx}, '${dropdown_Field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_history_getinfo_boolean"] = function (block: any) {
   var value_TradeIdx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);

   var dropdown_Field = block.getFieldValue("Field");

   // TODO: Assemble JavaScript into code variable.
   var code = `_History_GetInfo_Boolean(${value_TradeIdx}, '${dropdown_Field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

export const Toolbox_Order_History = {
   kind: "category",
   name: "%{BKY_catOrder_History}",
   categorystyle: Keys_Cat.Order_History,
   contents: [
      {
         kind: "block",
         blockxml: `<block type="block_order_history_getinfo_double">
      <field name="Field">HVol</field>
      <value name="TradeIdx">
        <shadow type="math_number">
          <field name="NUM">0</field>
        </shadow>
      </value>
    </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_order_history_getinfo_string">
      <field name="Field">StockCode</field>
      <value name="TradeIdx">
        <shadow type="math_number">
          <field name="NUM">0</field>
        </shadow>
      </value>
    </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_order_history_getinfo_datetime">
      <field name="Field">InTime</field>
      <value name="TradeIdx">
        <shadow type="math_number">
          <field name="NUM">0</field>
        </shadow>
      </value>
    </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_order_history_getinfo_boolean">
      <field name="Field">IsBuy</field>
      <value name="TradeIdx">
        <shadow type="math_number">
          <field name="NUM">0</field>
        </shadow>
      </value>
    </block>`,
      },
   ],
};
