import ExampleType from "./type";
import Ex_Empty from "./Empty";
import Main_Single from "./Main_Single";
import KLine_Inc from "./KLine_Inc";
import MA_Cross from "./MA_Cross";
import Bool_Cross from "./Bool_Cross";
import KD_Cross from "./KD_Cross";
import RSI_Cross from "./RSI_Cross";
import MACD_Zero from "./MACD_Zero";
import KLine_Incs from "./KLine_Incs";

export * from "./type";

export const ExampleList: ExampleType[] = [Ex_Empty, Main_Single, KLine_Inc, MA_Cross, Bool_Cross, KD_Cross, RSI_Cross, MACD_Zero, KLine_Incs];
export const Examples = { Ex_Empty, Main_Single, KLine_Inc, MA_Cross, Bool_Cross, KD_Cross, RSI_Cross, MACD_Zero, KLine_Incs };
