import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes, UseEntrust } from "./Block_Define";
import { Block_Order_External } from "./Block_Order_External";

// Blockly.Blocks["block_order_trade_longin"] = {
//   init: function () {
//     const This = this as Blockly.Block;
//     this.appendValueInput("Commodity")
//       .setCheck(FieldTypes.Commodity)
//       .appendField("多單下單")
//       .appendField(new Blockly.FieldLabelSerializable("商品"), "Commodity");
//     this.appendValueInput("HVol").setCheck(FieldTypes.Number).appendField("手數");

//     This.appendDummyInput("StopLoss_CheckInput")

//       .appendField(
//         new Blockly.FieldCheckbox(false, this.OnStopLossCheck),
//         "StopLoss_Enable"
//       )
//       .appendField("停損");

//     // This.appendValueInput("StopLoss")
//     //   .setCheck("Number")
//     //   .appendField("停損")
//     //   .appendField(
//     //     new Blockly.FieldDropdown([
//     //       ["價格", "Price"],
//     //       ["點差", "PointDiff"],
//     //     ]),
//     //     "StopLoss_ValueType"
//     //   );
//     this.appendValueInput("StopProfit")
//       .setCheck("Number")
//       .appendField(new Blockly.FieldCheckbox("FALSE"), "StopProfit_Enable")
//       .appendField("停利")
//       .appendField(
//         new Blockly.FieldDropdown([
//           ["價格", "Price"],
//           ["點差", "PointDiff"],
//         ]),
//         "StopProfit_ValueType"
//       );
//     this.setInputsInline(true);
//     this.setOutput(true, "Number");
//     this.setStyle(Keys_Block.Order_Trade_LongIn);
//     this.setTooltip("");
//     this.setHelpUrl("");
//   },
//   OnStopLossCheck: function (_szCheck: "TRUE" | "FALSE") {
//     var This = this as Blockly.FieldCheckbox;
//     var Block = This.getSourceBlock();
//     if (_szCheck === "TRUE") {
//       var nIdx =
//         Block.inputList.findIndex((q) => q.name === "StopLoss_CheckInput") + 1;
//       Block.appendValueInput("StopLoss")
//         .setCheck("Number")
//         .appendField("停損")
//         .appendField(
//           new Blockly.FieldDropdown([
//             ["價格", "Price"],
//             ["點差", "PointDiff"],
//           ]),
//           "StopLoss_ValueType"
//         );

//       if (Block.inputList.length !== nIdx) {
//         Block.moveNumberedInputBefore(Block.inputList.length - 1, nIdx);
//         // var Tmp = Block.inputList[Block.inputList.length - 1];
//         // Block.inputList[Block.inputList.length - 1] = Block.inputList[nIdx];
//         // Block.inputList[nIdx] = Tmp;
//       }

//       console.log(Block.inputList);
//     } else {
//       Block.removeInput("StopLoss");
//     }
//   },
// };

Blockly.Blocks["block_order_trade_longin"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("多單下單").appendField(new Blockly.FieldLabelSerializable("商品"), "Commodity");
      this.appendValueInput("HVol").setCheck(FieldTypes.Number).appendField("手數");
      if (UseEntrust) {
         Block_Order_External.AddStopLossInput(this); // 停損
         Block_Order_External.AddStopProfitInput(this); // 停利
      }
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_Trade_LongIn);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_trade_shortin"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("空單下單").appendField(new Blockly.FieldLabelSerializable("商品"), "Commodity");
      this.appendValueInput("HVol").setCheck(FieldTypes.Number).appendField("手數");
      if (UseEntrust) {
         Block_Order_External.AddStopLossInput(this); // 停損
         Block_Order_External.AddStopProfitInput(this); // 停利
      }
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_Trade_ShortIn);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_trade_out"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Number).appendField("平倉").appendField("單號");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle(Keys_Block.Order_Trade_Out);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_trade_outall"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("平倉(多筆)").appendField("商品");
      this.appendDummyInput()
         .appendField("買賣別")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["多單", "Buy"],
               ["空單", "Sell"],
            ]),
            "BuySellType"
         );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle(Keys_Block.Order_Trade_Out);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_trade_out_buysellbalance"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("多空相互平倉").appendField("商品");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setInputsInline(true);
      this.setStyle(Keys_Block.Order_Trade_Out);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.JavaScript["block_order_trade_longin"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hvol = Blockly.JavaScript.valueToCode(block, "HVol", Blockly.JavaScript.ORDER_ATOMIC);

   // 停損
   const szStopLoss = Block_Order_External.GetCode_StopLoss(block, (b, k) => Blockly.JavaScript.valueToCode(b, k, Blockly.JavaScript.ORDER_ATOMIC));

   // 停利
   const szStopProfit = Block_Order_External.GetCode_StopProfit(block, (b, k) => Blockly.JavaScript.valueToCode(b, k, Blockly.JavaScript.ORDER_ATOMIC));

   // TODO: Assemble JavaScript into code variable.
   var code = `_Trade_LongIn(${value_commodity}, ${value_hvol}, ${szStopLoss}, ${szStopProfit})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_trade_shortin"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hvol = Blockly.JavaScript.valueToCode(block, "HVol", Blockly.JavaScript.ORDER_ATOMIC);
   // 停損
   const szStopLoss = Block_Order_External.GetCode_StopLoss(block, (b, k) => Blockly.JavaScript.valueToCode(b, k, Blockly.JavaScript.ORDER_ATOMIC));

   // 停利
   const szStopProfit = Block_Order_External.GetCode_StopProfit(block, (b, k) => Blockly.JavaScript.valueToCode(b, k, Blockly.JavaScript.ORDER_ATOMIC));

   // TODO: Assemble JavaScript into code variable.
   var code = `_Trade_ShortIn(${value_commodity}, ${value_hvol}, ${szStopLoss}, ${szStopProfit})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_trade_out"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Trade_Out(${value_commodity});\n`;

   return code;
};

Blockly.JavaScript["block_order_trade_outall"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   // TODO: Assemble JavaScript into code variable.
   var code = `_Trade_OutAll(${value_commodity}, '${dropdown_buyselltype}');\n`;
   return code;
};

Blockly.JavaScript["block_order_trade_out_buysellbalance"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Trade_OutAutoBalance(${value_commodity});\n`;
   return code;
};

const contents_UseEntrust = [
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_longin">
     <field name="Commodity">商品</field>
     <field name="StopLoss_Enable">FALSE</field>
     <field name="StopProfit_Enable">FALSE</field>
     <value name="Commodity">
       <block type="block_commodity"></block>
     </value>
     <value name="HVol">
       <shadow type="math_number">
         <field name="NUM">0.01</field>
       </shadow>
     </value>
     <value name="StopLoss">
       <shadow type="math_number">
         <field name="NUM">0</field>
       </shadow>
     </value>
     <value name="StopProfit">
       <shadow type="math_number">
         <field name="NUM">0</field>
       </shadow>
     </value>
   </block>`,
   },
   {
      kind: "sep",
      gap: "48",
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_shortin">
     <field name="Commodity">商品</field>
     <field name="StopLoss_Enable">FALSE</field>
     <field name="StopProfit_Enable">FALSE</field>
     <value name="Commodity">
       <block type="block_commodity"></block>
     </value>
     <value name="HVol">
       <shadow type="math_number">
         <field name="NUM">0.01</field>
       </shadow>
     </value>
     <value name="StopLoss">
       <shadow type="math_number">
         <field name="NUM">0</field>
       </shadow>
     </value>
     <value name="StopProfit">
       <shadow type="math_number">
         <field name="NUM">0</field>
       </shadow>
     </value>
   </block>`,
   },
   {
      kind: "sep",
      gap: "48",
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_out">
     <value name="Commodity">
       <shadow type="math_number">
         <field name="NUM">0</field>
       </shadow>
     </value>
   </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_outall">
     <field name="BuySellType">All</field>
     <value name="Commodity">
       <block type="block_commodity"></block>
     </value>
   </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_out_buysellbalance">
     <value name="Commodity">
       <block type="block_commodity"></block>
     </value>
   </block>`,
   },
];

const contents = [
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_longin">
     <field name="Commodity">商品</field>
     <value name="Commodity">
       <block type="block_commodity"></block>
     </value>
     <value name="HVol">
       <shadow type="math_number">
         <field name="NUM">0.01</field>
       </shadow>
     </value>
   </block>`,
   },
   {
      kind: "sep",
      gap: "48",
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_shortin">
     <field name="Commodity">商品</field>
     <value name="Commodity">
       <block type="block_commodity"></block>
     </value>
     <value name="HVol">
       <shadow type="math_number">
         <field name="NUM">0.01</field>
       </shadow>
     </value>
   </block>`,
   },
   {
      kind: "sep",
      gap: "48",
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_out">
     <value name="Commodity">
       <shadow type="math_number">
         <field name="NUM">0</field>
       </shadow>
     </value>
   </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_outall">
     <field name="BuySellType">All</field>
     <value name="Commodity">
       <block type="block_commodity"></block>
     </value>
   </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_trade_out_buysellbalance">
     <value name="Commodity">
       <block type="block_commodity"></block>
     </value>
   </block>`,
   },
];

export const ToolboxItems_Order_Trade = {
   kind: "category",
   name: "%{BKY_catOrder_Trade}",
   categorystyle: Keys_Cat.Order_Trade,
   contents: UseEntrust ? contents_UseEntrust : contents,
};
