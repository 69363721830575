import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFundPage(_Prop: any) {
   const Navigate = useNavigate();
   setTimeout(() => {
      Navigate("/");
   }, 500);
   return (
      <div>
         <h2>404 NotFund</h2>
      </div>
   );
}
