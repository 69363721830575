import { PostApi } from "../Api";
import { UpdateStrategyParamModel } from "../Api/PostApi";
import { StorageConnection } from "./StorageHelper";

export default class kcDataInfo {
   public static Account() {
      return PostApi.Account?.Account ?? "";
   }
   static {}

   public static async Login(_Account: string, _Password: string) {
      const mdLogin = await PostApi.Login(_Account, _Password);
      if (mdLogin?.Success) {
         StorageConnection.SendLogin();
      }
      return mdLogin;
   }

   public static LogOut() {
      PostApi.Logout();
      StorageConnection.SendLogOut();
   }

   // public static async UpdateStrategy(StrategyIndex: number, Version: string, StrategyName: string, XML: string, Code: string) {
   //    return PostApi.UpdateStrategy(StrategyIndex, Version, StrategyName, XML, Code);
   // }
   public static async UpdateStrategy(_mdStrategy: UpdateStrategyParamModel) {
      return PostApi.UpdateStrategy(_mdStrategy);
   }

   public static async GetStrategy(StrategyIndex: number) {
      return PostApi.GetStrategy(StrategyIndex);
   }
}

export class Extensions {
   public static Sleep(_nTimeOut: number) {
      return new Promise((r) => setTimeout(r, _nTimeOut));
   }
}
