import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { Version } from "./Block_Define";

export type MainBlockType = Blockly.Block & {
   GetStrategyName: () => string | undefined;
   GetDescription: () => string | undefined;
};

Blockly.Blocks["block_mainfunction"] = {
   init: function () {
      const This = this as Blockly.Block;
      //This.setInputsInline(false);
      this.appendDummyInput().appendField("主程式 v" + Version);
      This.appendDummyInput().appendField("策略名稱 : ").appendField(new Blockly.FieldTextInput("自訂策略"), "StrategyName");
      This.appendDummyInput().appendField("策略描述 : ").appendField(new Blockly.FieldTextInput("描述本策略"), "Description");

      this.appendDummyInput().appendField("初始化");
      this.appendStatementInput("fInitialize").setCheck(null);
      this.appendDummyInput().appendField("價格變動(每次)");
      this.appendStatementInput("fOnTick").setCheck(null);
      this.setStyle(Keys_Block.Main);
      this.setTooltip("");
      this.setHelpUrl("");

      this.base_allInputsFilled = this.allInputsFilled;
      // 空的時候回傳true
      this.allInputsFilled = (opt_shadowBlocksAreFilled?: boolean | undefined) => {
         if (this.base_allInputsFilled(opt_shadowBlocksAreFilled)) return true;

         const This = this as Blockly.Block;
         const lChildrens = This.getChildren(true);

         for (let Child of lChildrens) {
            if (!Child.allInputsFilled(true)) return false;
         }
         return true;
      };
   },
   GetStrategyName: function () {
      const This = this as Blockly.Block;
      const szStrategyName = This.getField("StrategyName")?.getText();
      if (szStrategyName) return szStrategyName;

      return undefined;
   },
   GetDescription: function () {
      const This = this as Blockly.Block;
      const szDescription = This.getField("Description")?.getText();
      if (szDescription) return szDescription;

      return undefined;
   },
};

Blockly.JavaScript["block_mainfunction"] = function (block: Blockly.Block) {
   var statements_fInitialize = Blockly.JavaScript.statementToCode(block, "fInitialize");
   var statements_fontick = Blockly.JavaScript.statementToCode(block, "fOnTick");

   var code_Init = "function Initialize() {\n" + statements_fInitialize + "}\n";
   var code_OnTick = "function OnTick() {\n" + statements_fontick + "}\n";

   return code_Init + "\n" + code_OnTick;
};

export const ToolboxItems_Main = {
   kind: "category",
   name: "%{BKY_catMain}",
   categorystyle: Keys_Cat.Main,
   contents: [
      {
         kind: "block",
         blockxml: `<block type="block_mainfunction">
      </block>`,
      },
   ],
};
