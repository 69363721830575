import { LoginAppModel } from "./Api/PostApi";
import { PostApi } from "./Api";

const window_Any = window as any;

class ShareApiCallbackModel {
   async onInit() {}
   async onGetBackColor() {
      return "";
   }
   async onGetLoginAppAccount() {
      return { AccIdx: Number.NaN, Account: "", Name: "", EMail: "" };
   }
   async onStrategyUpdate() {}
}

class ShareApiModel {
   public Callbacks = new ShareApiCallbackModel();
   public TopBackColor = "";
   public TradeAppMode = false;
   public IsBinded = false;
   public BlocklyEdit: any = undefined;

   /* ------------------------------------------ */
   // ShareApi function
   TraderAppInit() {
      this.TradeAppMode = true;
      return true;
   }

   SetToken = async (_szToken: string) => {
      PostApi.UpdateToken(_szToken);
      return true;
   };

   SetLoginAppInfo = async (_mdParam: LoginAppModel) => {
      if (LoginAppModel.HasSafeValue(_mdParam)) {
         LoginAppModel.SaveBuffer(_mdParam);
         return true;
      }
      return false;
   };

   SetTopBackcColor(_szBackColor: string) {
      this.TopBackColor = _szBackColor;
   }
   ChangeStrategy = async (_nStrategyIndex: number) => {};
   HasChangeBlock = () => {
      return false;
   };
   SaveBlock = async () => {
      return false;
   };
}

const ShareApi = new ShareApiModel();

export async function InitShareApi() {
   const CefSharp = window_Any.CefSharp;
   if (!CefSharp) return;

   window_Any.ShareApi = ShareApi;

   if (CefSharp) await CefSharp.BindObjectAsync("ShareApiCallbacks");

   if (window_Any.ShareApiCallbacks) {
      ShareApi.Callbacks = window_Any.ShareApiCallbacks;
      await ShareApi.Callbacks.onInit();
      ShareApi.IsBinded = true;
   }
}

export default ShareApi;
