import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes } from "./Block_Define";
import RegTECPHelper from "./Block_Helper_RegisterTECP";
// import RegTECPHelper from "./Block_Helper_RegisterTECP";

Blockly.Blocks["block_tecp_kline"] = {
   init: function () {
      this.appendDummyInput().appendField("K線圖");
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("商品");
      this.appendValueInput("HParam").setCheck(FieldTypes.HParam).appendField("時間週期");
      this.appendDummyInput()
         .appendField("價格")
         .appendField(
            new Blockly.FieldDropdown([
               ["收盤價", "ClosePrice"],
               ["開盤價", "OpenPrice"],
               ["最高價", "HighPrice"],
               ["最低價", "LowPrice"],
            ]),
            "Value"
         );

      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("K棒前移");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle(Keys_Block.TECP);
   },
};

Blockly.Blocks["block_tecp_ma"] = {
   init: function () {
      this.appendDummyInput().appendField("移動平均線");
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("商品");
      this.appendValueInput("HParam").setCheck(FieldTypes.HParam).appendField("時間週期");
      this.appendValueInput("MaBase").setCheck(FieldTypes.Number).appendField("週期");
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("K棒前移");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle(Keys_Block.TECP);
   },
};

Blockly.Blocks["block_tecp_bband"] = {
   init: function () {
      this.appendDummyInput().appendField("布林通道");
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("商品");
      this.appendValueInput("HParam").setCheck(FieldTypes.HParam).appendField("時間週期");
      this.appendValueInput("MaBase").setCheck(FieldTypes.Number).appendField("週期");
      this.appendValueInput("SDBase").setCheck(FieldTypes.Number).appendField("標準差");
      this.appendDummyInput()
         .appendField("價格")
         .appendField(
            new Blockly.FieldDropdown([
               ["上線", "MaPressure"],
               ["中線", "Ma"],
               ["下線", "MaStrut"],
            ]),
            "Value"
         );
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("K棒前移");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle(Keys_Block.TECP);
   },
};

Blockly.Blocks["block_tecp_vol"] = {
   init: function () {
      this.appendDummyInput().appendField("成交量");
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("商品");
      this.appendValueInput("HParam").setCheck(FieldTypes.HParam).appendField("時間週期");
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("K棒前移");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle(Keys_Block.TECP);
   },
};

Blockly.Blocks["block_tecp_kd"] = {
   init: function () {
      this.appendDummyInput().appendField("KD");
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("商品");
      this.appendValueInput("HParam").setCheck(FieldTypes.HParam).appendField("時間週期");
      this.appendValueInput("KDBase").setCheck(FieldTypes.Number).appendField("週期");
      this.appendDummyInput()
         .appendField("資料")
         .appendField(
            new Blockly.FieldDropdown([
               ["K (快線)", "K"],
               ["D (慢線)", "D"],
            ]),
            "Value"
         );
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("K棒前移");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle(Keys_Block.TECP);
   },
};

Blockly.Blocks["block_tecp_rsi"] = {
   init: function () {
      this.appendDummyInput().appendField("RSI");
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("商品");
      this.appendValueInput("HParam").setCheck(FieldTypes.HParam).appendField("時間週期");
      this.appendValueInput("RSI1_Base").setCheck(FieldTypes.Number).appendField("週期1");
      this.appendValueInput("RSI2_Base").setCheck(FieldTypes.Number).appendField("週期2");
      this.appendDummyInput()
         .appendField("資料")
         .appendField(
            new Blockly.FieldDropdown([
               ["RSI1", "RSI1"],
               ["RSI2", "RSI2"],
            ]),
            "Value"
         );
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("K棒前移");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle(Keys_Block.TECP);
   },
};

Blockly.Blocks["block_tecp_macd"] = {
   init: function () {
      this.appendDummyInput().appendField("MACD");
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("商品");
      this.appendValueInput("HParam").setCheck(FieldTypes.HParam).appendField("時間週期");
      this.appendValueInput("EMA1_Base").setCheck(FieldTypes.Number).appendField("短周期");
      this.appendValueInput("EMA2_Base").setCheck(FieldTypes.Number).appendField("長週期");
      this.appendValueInput("MACD_Base").setCheck(FieldTypes.Number).appendField("MACD週期");
      this.appendDummyInput()
         .appendField("資料")
         .appendField(
            new Blockly.FieldDropdown([
               ["OSC(柱狀)", "OSC"],
               ["DIF(快線)", "DIF"],
               ["MACD(慢線)", "MACD"],
            ]),
            "Value"
         );
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("K棒前移");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle(Keys_Block.TECP);
   },
};

Blockly.Blocks["block_hparam"] = {
   init: function () {
      this.appendDummyInput()
         .appendField("時間週期")
         .appendField(
            new Blockly.FieldDropdown([
               //  ["目前", "Current"],
               ["1分鐘", "1Minute"],
               ["5分鐘", "5Minute"],
               ["15分鐘", "15Minute"],
               ["30分鐘", "30Minute"],
               ["1小時", "60Minute"],
               ["4小時", "240Minute"],
               ["1日", "1Day"],
               ["1週", "1Week"],
               ["1個月", "1Month"],
            ]),
            "HParam"
         );
      this.setOutput(true, FieldTypes.HParam);
      this.setStyle(Keys_Block.HParam);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.JavaScript["block_tecp_kline"] = function (block: Blockly.Block) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hparam = Blockly.JavaScript.valueToCode(block, "HParam", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_name = block.getFieldValue("Value");
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_KLine(${value_commodity}, ${value_hparam}, '${dropdown_name}', ${value_index})`;

   RegTECPHelper.Append("KLine", value_commodity, `${value_hparam}`);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_tecp_ma"] = function (block: Blockly.Block) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hparam = Blockly.JavaScript.valueToCode(block, "HParam", Blockly.JavaScript.ORDER_ATOMIC);
   var value_mabase = Blockly.JavaScript.valueToCode(block, "MaBase", Blockly.JavaScript.ORDER_ATOMIC);
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_SMA(${value_commodity}, ${value_hparam}, ${value_mabase}, ${value_index})`;

   RegTECPHelper.Append("SMA", value_commodity, `${value_hparam}, ${value_mabase}`);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_tecp_bband"] = function (block: Blockly.Block) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hparam = Blockly.JavaScript.valueToCode(block, "HParam", Blockly.JavaScript.ORDER_ATOMIC);
   var value_mabase = Blockly.JavaScript.valueToCode(block, "MaBase", Blockly.JavaScript.ORDER_ATOMIC);
   var value_sdbase = Blockly.JavaScript.valueToCode(block, "SDBase", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_value = block.getFieldValue("Value");
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_BBand(${value_commodity}, ${value_hparam}, ${value_mabase}, ${value_sdbase}, '${dropdown_value}', ${value_index})`;

   RegTECPHelper.Append("BBand", value_commodity, `${value_hparam}, ${value_mabase}, ${value_sdbase}`);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_tecp_vol"] = function (block: Blockly.Block) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hparam = Blockly.JavaScript.valueToCode(block, "HParam", Blockly.JavaScript.ORDER_ATOMIC);
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_Vol(${value_commodity}, ${value_hparam}, ${value_index})`;

   RegTECPHelper.Append("Vol", value_commodity, `${value_hparam}`);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_tecp_kd"] = function (block: Blockly.Block) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hparam = Blockly.JavaScript.valueToCode(block, "HParam", Blockly.JavaScript.ORDER_ATOMIC);
   var value_kdbase = Blockly.JavaScript.valueToCode(block, "KDBase", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_value = block.getFieldValue("Value");
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_KD(${value_commodity}, ${value_hparam}, ${value_kdbase}, '${dropdown_value}', ${value_index})`;

   RegTECPHelper.Append("KD", value_commodity, `${value_hparam}, ${value_kdbase}`);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_tecp_rsi"] = function (block: Blockly.Block) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hparam = Blockly.JavaScript.valueToCode(block, "HParam", Blockly.JavaScript.ORDER_ATOMIC);
   var value_rsi1_base = Blockly.JavaScript.valueToCode(block, "RSI1_Base", Blockly.JavaScript.ORDER_ATOMIC);
   var value_rsi2_base = Blockly.JavaScript.valueToCode(block, "RSI2_Base", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_value = block.getFieldValue("Value");
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_RSI(${value_commodity}, ${value_hparam}, ${value_rsi1_base}, ${value_rsi2_base}, '${dropdown_value}', ${value_index})`;

   RegTECPHelper.Append("RSI", value_commodity, `${value_hparam}, ${value_rsi1_base}, ${value_rsi2_base}`);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_tecp_macd"] = function (block: Blockly.Block) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hparam = Blockly.JavaScript.valueToCode(block, "HParam", Blockly.JavaScript.ORDER_ATOMIC);
   var value_ema1_base = Blockly.JavaScript.valueToCode(block, "EMA1_Base", Blockly.JavaScript.ORDER_ATOMIC);
   var value_ema2_base = Blockly.JavaScript.valueToCode(block, "EMA2_Base", Blockly.JavaScript.ORDER_ATOMIC);
   var value_macd_base = Blockly.JavaScript.valueToCode(block, "MACD_Base", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_value = block.getFieldValue("Value");
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_MACD(${value_commodity}, ${value_hparam}, ${value_ema1_base}, ${value_ema2_base}, ${value_macd_base}, '${dropdown_value}', ${value_index})`;

   RegTECPHelper.Append("MACD", value_commodity, `${value_hparam}, ${value_ema1_base}, ${value_ema2_base}, ${value_macd_base}`);

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_hparam"] = function (block: Blockly.Block) {
   var dropdown_type = block.getFieldValue("HParam");
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetHParam('${dropdown_type}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

export const ToolboxItems_TECP = {
   kind: "category",
   name: "%{BKY_catTECP}",
   categorystyle: Keys_Cat.TECP,
   contents: [
      {
         kind: "block",
         blockxml: `<block type="block_tecp_kline">
        <field name="Value">ClosePrice</field>
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
        <value name="HParam">
          <block type="block_hparam">
            <field name="HParam">1Minute</field>
          </block>
        </value>
        <value name="Index">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_tecp_ma">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
        <value name="HParam">
          <block type="block_hparam">
            <field name="HParam">1Minute</field>
          </block>
        </value>
        <value name="MaBase">
          <block type="math_number">
            <field name="NUM">5</field>
          </block>
        </value>
        <value name="Index">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: ` <block type="block_tecp_bband">
        <field name="Value">MaPressure</field>
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
        <value name="HParam">
          <block type="block_hparam">
            <field name="HParam">1Minute</field>
          </block>
        </value>
        <value name="MaBase">
          <block type="math_number">
            <field name="NUM">20</field>
          </block>
        </value>
        <value name="SDBase">
          <block type="math_number">
            <field name="NUM">2</field>
          </block>
        </value>
        <value name="Index">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_tecp_vol">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
        <value name="HParam">
          <block type="block_hparam">
            <field name="HParam">1Minute</field>
          </block>
        </value>
        <value name="Index">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_tecp_kd">
        <field name="Value">K</field>
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
        <value name="HParam">
          <block type="block_hparam">
            <field name="HParam">1Minute</field>
          </block>
        </value>
        <value name="KDBase">
          <block type="math_number">
            <field name="NUM">9</field>
          </block>
        </value>
        <value name="Index">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_tecp_rsi">
        <field name="Value">RSI1</field>
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
        <value name="HParam">
          <block type="block_hparam">
            <field name="HParam">1Minute</field>
          </block>
        </value>
        <value name="RSI1_Base">
          <block type="math_number">
            <field name="NUM">6</field>
          </block>
        </value>
        <value name="RSI2_Base">
          <block type="math_number">
            <field name="NUM">12</field>
          </block>
        </value>
        <value name="Index">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_tecp_macd">
        <field name="Value">OSC</field>
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
        <value name="HParam">
          <block type="block_hparam">
            <field name="HParam">1Minute</field>
          </block>
        </value>
        <value name="EMA1_Base">
          <block type="math_number">
            <field name="NUM">12</field>
          </block>
        </value>
        <value name="EMA2_Base">
          <block type="math_number">
            <field name="NUM">26</field>
          </block>
        </value>
        <value name="MACD_Base">
          <block type="math_number">
            <field name="NUM">9</field>
          </block>
        </value>
        <value name="Index">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: '<block type="block_hparam"></block>',
      },
   ],
};
