export const Version = "1.0.1";
export const FieldTypes = {
   Commodity: "Commodity",
   HParam: "HParam",
   DateTime: "DateTime",
   Number: "Number",
   Boolean: "Boolean",
   String: "String",
};
export const UseEntrust = false; // 啟用掛單
