import Blockly from "blockly";
import { FieldTypes } from "./Block_Define";

type typeValueToCode = (_Block: Blockly.Block, _Key: string) => string;

export class Block_Order_External {
   private static Key_StopLoss = "StopLoss";
   private static Key_StopProfit = "StopProfit";

   private static KeySufix_Enable = "_Enable";
   private static KeySufix_ValueType = "_ValueType";

   // 加入停損Input
   public static AddStopLossInput(_Block: Blockly.Block): Blockly.Block {
      return this.AddStopInputsCore(_Block, this.Key_StopLoss, "停損");
   }
   // 加入停利Input
   public static AddStopProfitInput(_Block: Blockly.Block): Blockly.Block {
      return this.AddStopInputsCore(_Block, this.Key_StopProfit, "停利");
   }
   // 取得停損object
   public static GetCode_StopLoss(_Block: Blockly.Block, _fValueToCode: typeValueToCode) {
      const mdStop = this.GetStopValueCore(_Block, this.Key_StopLoss, _fValueToCode);
      if (mdStop) return mdStop.ToCode();
      return null;
   }
   // 取得停利object
   public static GetCode_StopProfit(_Block: Blockly.Block, _fValueToCode: typeValueToCode) {
      const mdStop = this.GetStopValueCore(_Block, this.Key_StopProfit, _fValueToCode);
      if (mdStop) return mdStop.ToCode();
      return null;
   }

   // 加入停損停利Input CoreFunction
   private static AddStopInputsCore(_Block: Blockly.Block, _szMainKey: string, _szText: string): Blockly.Block {
      const Field_Enable = new Blockly.FieldCheckbox("FALSE");
      // const Field_Type = new Blockly.FieldDropdown([
      //   ["價格", "Price"],
      //   ["點差", "PointDiff"],
      // ]);

      _Block
         .appendValueInput(_szMainKey)
         .appendField(" ") // 與前項UI間插入間隔
         .setCheck(FieldTypes.Number)
         .appendField(Field_Enable, _szMainKey + this.KeySufix_Enable)
         .appendField(_szText)
         .appendField("價格");
      //   .appendField(Field_Type, _szMainKey + this.KeySufix_ValueType);

      return _Block;
   }
   // 取得停損停利Input CoreFunction
   private static GetStopValueCore(_Block: Blockly.Block, _szMainKey: string, _fValueToCode: typeValueToCode) {
      const bEnable = (_Block.getFieldValue(_szMainKey + this.KeySufix_Enable) ?? "FALSE") === "TRUE";
      if (!bEnable) return null;

      const Type = _Block.getFieldValue(_szMainKey + this.KeySufix_ValueType) ?? "Price";
      const Value = _fValueToCode(_Block, _szMainKey);

      return {
         Type: Type,
         Value: Value,
         ToCode: () => {
            return `{ Type: '${Type}', Value: ${Value}}`;
         },
      };
   }
}
