import Blockly from "blockly";

const JS = Blockly.JavaScript;
const Key_RegTECP = "RegTECP";
const Ket_RegStock = "RegStock";
const RegTECPFunctionName = "_RegisterTECP";
const RegStockFunctionName = "_RegisterStock";
const RegisterTECPMainFuncName = "RunRegisterTECP";

const RegTECPHelper = {
   RunRegisterFuncUsed: false,
   Init: function () {
      this.RunRegisterFuncUsed = false;
      JS[Key_RegTECP] = [];
      JS[Ket_RegStock] = [];
   },
   Append: function (_szTECP: string, _szCommodity: string, _szParams: string) {
      if (!_szCommodity || _szCommodity.length <= 0) return;

      const szParams = `${_szCommodity}, ${_szParams}`;
      if (JS[Key_RegTECP].findIndex((q: any) => q.TECP === _szTECP && q.Params === szParams) >= 0) return;

      JS[Key_RegTECP].push({ TECP: _szTECP, Params: szParams });
      this.AppendCommodity(_szCommodity);
   },
   AppendCommodity: function (_szCommodity: string) {
      if (!_szCommodity || _szCommodity.length <= 0) return;
      if (JS[Ket_RegStock].findIndex((q: any) => q === _szCommodity) >= 0) return;
      JS[Ket_RegStock].push(_szCommodity);
   },
   Clear: function () {
      JS[Key_RegTECP] = [];
      JS[Ket_RegStock] = [];
   },
   GetCode: function (_bClear: boolean, _szCodeShift: string): string {
      var szRet = "";

      // AllStock
      if (JS[Ket_RegStock].length > 0) {
         if (szRet.length > 0) szRet += "\n";
         szRet += JS[Ket_RegStock].map((q: string) => `${_szCodeShift}${RegStockFunctionName}(${q});`).join("\n");
      }

      // TECP
      if (JS[Key_RegTECP].length > 0) {
         if (szRet.length > 0) szRet += "\n";
         szRet += JS[Key_RegTECP].map((q: any) => `${_szCodeShift}${RegTECPFunctionName}('${q.TECP}', ${q.Params});`).join("\n");
      }

      if (_bClear) this.Clear();
      return szRet;
   },
   GetRunRegisterFuncCode: function () {
      const szCodes = this.GetCode(true, JS.INDENT);
      if (!szCodes) return szCodes;

      var szRetCode = `function ${RegisterTECPMainFuncName}() {\n${szCodes}\n}`;

      /* --------------------------------------------- */
      // 註解
      szRetCode = `// 執行註冊\n` + szRetCode;

      return szRetCode;
   },
   UseRunRegisterFunc: function () {
      if (JS[Key_RegTECP].length === 0 || this.RunRegisterFuncUsed) return "";
      this.RunRegisterFuncUsed = true;
      return `${RegisterTECPMainFuncName}();\n`;
   },
};

export default RegTECPHelper;
