//const Key_Account = "Account";
const Key_Login = "Login";
const Key_Logout = "Logout";
const Key_Locale = "Locale";
const BlockBuffer_Key = "BlockBuffer";

export class SessionStorage {
   public static SaveLocale(_Local: string) {
      window.sessionStorage.setItem(Key_Locale, _Local);
   }
   public static ReadLocale() {
      return window.sessionStorage.getItem(Key_Locale);
   }
   public static SaveBlockBuffer(Account: string, StrategyIndex: number, XML: string) {
      const szKey = this.GetBlockBufferKey(Account);
      window.sessionStorage.setItem(szKey, XML);
      window.sessionStorage.setItem(szKey + "SIdx", `${StrategyIndex}`);
   }
   public static ReadBlockBuffer(Account: string) {
      const szKey = this.GetBlockBufferKey(Account);

      const szXML = window.sessionStorage.getItem(szKey);
      const szStrategyIndex = window.sessionStorage.getItem(szKey + "SIdx");
      var nStrategyIndex: number | undefined = undefined;
      if (szStrategyIndex !== null) {
         nStrategyIndex = Number(szStrategyIndex);
         if (!Number.isSafeInteger(nStrategyIndex)) nStrategyIndex = undefined;
      }
      const Obj = {
         XML: szXML,
         StrategyIndex: nStrategyIndex,
      };
      return Obj;
      //return window.sessionStorage.getItem(szKey);
   }

   private static GetBlockBufferKey = (_Account: string) => {
      if (_Account && _Account.length > 0) return BlockBuffer_Key + "_" + _Account;
      return BlockBuffer_Key;
   };
}

// 用localStorage跨頁籤溝通
export class StorageConnection {
   // static 建構子
   static {
      this.InitStorageConnection();
   }

   private static InitStorageConnection() {
      window.addEventListener("storage", (event) => {
         const { key, /*oldValue,*/ newValue } = event;

         if (key === Key_Login) {
            if (newValue === "true") {
               window.location.reload();
            }
         } else if (key === Key_Logout) {
            if (newValue === "true") {
               window.location.reload();
            }
         }
      });
   }
   public static SendLogin() {
      window.localStorage.setItem(Key_Login, "true");
      window.localStorage.removeItem(Key_Login);
   }
   public static SendLogOut() {
      window.localStorage.setItem(Key_Logout, "true");
      window.localStorage.removeItem(Key_Logout);
   }
}

// 讀取別的頁籤的sessionStorage 範例
export class ShareStorageHelper {
   // static 建構子
   static {
      this.InitShare();
   }

   private static InitShare() {
      // 判斷當前頁面是否存在sessionStorage
      if (!window.sessionStorage.length) {
         // 若不存在則加上一個localStorage Item, Key = getSessionStorageData
         window.localStorage.setItem("getSessionStorageData", `${Date.now()}`);
         window.localStorage.removeItem("getSessionStorageData");
      }

      // 增加window監聽事件'storage'
      window.addEventListener("storage", (event) => {
         // 如果偵聽到的事件是key是getSessionStorageData
         if (event.key === "getSessionStorageData") {
            // 再新增一個localstorage Item, key = sessionStorageData，value就是當前的sessionStorage
            window.localStorage.setItem("sessionStorageData", JSON.stringify(window.sessionStorage));
            // 刪除localstorage中key = sessionStorageData的item
            window.localStorage.removeItem("sessionStorageData");
         }
         // 如果偵聽到的事件是key是sessionStorageData, 且當前的sessionStorage是空的
         if (event.key === "sessionStorageData" && !window.sessionStorage.length) {
            // 把sessionStorageData的資料parse出來
            const data = JSON.parse(event.newValue as string);
            // 賦值到當前頁面的sessionStorage中
            for (let key in data) {
               window.sessionStorage.setItem(key, data[key]);
            }
         }
      });
   }
}
