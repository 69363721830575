import ExampleType from "./type";

const KLine_Inc: ExampleType = {
   Name: "單筆上漲",
   xml: `
  <xml xmlns="https://developers.google.com/blockly/xml">
    <variables>
      <variable>單號-多單</variable>
      <variable>收盤價1</variable>
      <variable>函式運算結果</variable>
      <variable>單號-空單</variable>
      <variable>收盤價2</variable>
      <variable>K線漲跌</variable>
    </variables>
    <block type="block_mainfunction" x="14" y="14">
      <field name="StrategyName">單筆上漲</field>
      <field name="Description">範例策略 - 單筆上漲</field>
      <statement name="fInitialize">
        <block type="variables_set">
          <field name="VAR">單號-多單</field>
          <value name="VALUE">
            <block type="block_order_tradeindex_getlasttradeidx_inventory">
              <field name="BuySellType">Buy</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">單號-空單</field>
              <value name="VALUE">
                <block type="block_order_tradeindex_getlasttradeidx_inventory">
                  <field name="BuySellType">Sell</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">函式運算結果</field>
                  <value name="VALUE">
                    <block type="logic_boolean">
                      <field name="BOOL">FALSE</field>
                    </block>
                  </value>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <statement name="fOnTick">
        <block type="controls_if">
          <mutation else="1"></mutation>
          <value name="IF0">
            <block type="logic_compare">
              <field name="OP">LTE</field>
              <value name="A">
                <block type="variables_get">
                  <field name="VAR">單號-多單</field>
                </block>
              </value>
              <value name="B">
                <block type="math_number">
                  <field name="NUM">0</field>
                </block>
              </value>
            </block>
          </value>
          <statement name="DO0">
            <block type="controls_if">
              <value name="IF0">
                <block type="procedures_callreturn">
                  <mutation name="判斷允許做多"></mutation>
                </block>
              </value>
              <statement name="DO0">
                <block type="variables_set">
                  <field name="VAR">單號-多單</field>
                  <value name="VALUE">
                    <block type="block_order_trade_longin">
                      <field name="Commodity">商品</field>
                      <value name="Commodity">
                        <block type="block_commodity"></block>
                      </value>
                      <value name="HVol">
                        <shadow type="math_number">
                          <field name="NUM">0.01</field>
                        </shadow>
                      </value>
                    </block>
                  </value>
                </block>
              </statement>
            </block>
          </statement>
          <statement name="ELSE">
            <block type="controls_if">
              <value name="IF0">
                <block type="procedures_callreturn">
                  <mutation name="判斷允許多平"></mutation>
                </block>
              </value>
              <statement name="DO0">
                <block type="block_order_trade_out">
                  <value name="Commodity">
                    <shadow type="math_number">
                      <field name="NUM">0</field>
                    </shadow>
                    <block type="variables_get">
                      <field name="VAR">單號-多單</field>
                    </block>
                  </value>
                  <next>
                    <block type="variables_set">
                      <field name="VAR">單號-多單</field>
                      <value name="VALUE">
                        <block type="block_order_tradeindex_getlasttradeidx_inventory">
                          <field name="BuySellType">Buy</field>
                          <value name="Commodity">
                            <block type="block_commodity"></block>
                          </value>
                        </block>
                      </value>
                    </block>
                  </next>
                </block>
              </statement>
            </block>
          </statement>
          <next>
            <block type="controls_if">
              <mutation else="1"></mutation>
              <value name="IF0">
                <block type="logic_compare">
                  <field name="OP">LTE</field>
                  <value name="A">
                    <block type="variables_get">
                      <field name="VAR">單號-空單</field>
                    </block>
                  </value>
                  <value name="B">
                    <block type="math_number">
                      <field name="NUM">0</field>
                    </block>
                  </value>
                </block>
              </value>
              <statement name="DO0">
                <block type="controls_if">
                  <value name="IF0">
                    <block type="procedures_callreturn">
                      <mutation name="判斷允許做空"></mutation>
                    </block>
                  </value>
                  <statement name="DO0">
                    <block type="variables_set">
                      <field name="VAR">單號-空單</field>
                      <value name="VALUE">
                        <block type="block_order_trade_shortin">
                          <field name="Commodity">商品</field>
                          <value name="Commodity">
                            <block type="block_commodity"></block>
                          </value>
                          <value name="HVol">
                            <shadow type="math_number">
                              <field name="NUM">0.01</field>
                            </shadow>
                          </value>
                        </block>
                      </value>
                    </block>
                  </statement>
                </block>
              </statement>
              <statement name="ELSE">
                <block type="controls_if">
                  <value name="IF0">
                    <block type="procedures_callreturn">
                      <mutation name="判斷允許空平"></mutation>
                    </block>
                  </value>
                  <statement name="DO0">
                    <block type="block_order_trade_out">
                      <value name="Commodity">
                        <shadow type="math_number">
                          <field name="NUM">0</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">單號-空單</field>
                        </block>
                      </value>
                      <next>
                        <block type="variables_set">
                          <field name="VAR">單號-空單</field>
                          <value name="VALUE">
                            <block type="block_order_tradeindex_getlasttradeidx_inventory">
                              <field name="BuySellType">Sell</field>
                              <value name="Commodity">
                                <block type="block_commodity"></block>
                              </value>
                            </block>
                          </value>
                        </block>
                      </next>
                    </block>
                  </statement>
                </block>
              </statement>
            </block>
          </next>
        </block>
      </statement>
    </block>
    <block type="procedures_defreturn" x="14" y="797">
      <field name="NAME">判斷允許做多</field>
      <comment pinned="false" h="36" w="147">使用者自訂此函式</comment>
      <statement name="STACK">
        <block type="variables_set">
          <field name="VAR">收盤價1</field>
          <value name="VALUE">
            <block type="block_tecp_kline">
              <field name="Value">ClosePrice</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
              <value name="HParam">
                <block type="block_hparam">
                  <field name="HParam">1Minute</field>
                </block>
              </value>
              <value name="Index">
                <block type="math_number">
                  <field name="NUM">1</field>
                </block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">收盤價2</field>
              <value name="VALUE">
                <block type="block_tecp_kline">
                  <field name="Value">ClosePrice</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                  <value name="HParam">
                    <block type="block_hparam">
                      <field name="HParam">1Minute</field>
                    </block>
                  </value>
                  <value name="Index">
                    <block type="math_number">
                      <field name="NUM">2</field>
                    </block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">K線漲跌</field>
                  <value name="VALUE">
                    <block type="math_arithmetic">
                      <field name="OP">MINUS</field>
                      <value name="A">
                        <shadow type="math_number">
                          <field name="NUM">1</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">收盤價1</field>
                        </block>
                      </value>
                      <value name="B">
                        <shadow type="math_number">
                          <field name="NUM">1</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">收盤價2</field>
                        </block>
                      </value>
                    </block>
                  </value>
                  <next>
                    <block type="controls_if">
                      <mutation else="1"></mutation>
                      <value name="IF0">
                        <block type="logic_compare">
                          <field name="OP">GT</field>
                          <value name="A">
                            <block type="variables_get">
                              <field name="VAR">K線漲跌</field>
                            </block>
                          </value>
                          <value name="B">
                            <block type="math_number">
                              <field name="NUM">0</field>
                            </block>
                          </value>
                        </block>
                      </value>
                      <statement name="DO0">
                        <block type="variables_set">
                          <field name="VAR">函式運算結果</field>
                          <value name="VALUE">
                            <block type="logic_boolean">
                              <field name="BOOL">TRUE</field>
                            </block>
                          </value>
                        </block>
                      </statement>
                      <statement name="ELSE">
                        <block type="variables_set">
                          <field name="VAR">函式運算結果</field>
                          <value name="VALUE">
                            <block type="logic_boolean">
                              <field name="BOOL">FALSE</field>
                            </block>
                          </value>
                        </block>
                      </statement>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <value name="RETURN">
        <block type="variables_get">
          <field name="VAR">函式運算結果</field>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="1094">
      <field name="NAME">判斷允許多平</field>
      <comment pinned="false" h="33" w="143">使用者自訂此函式</comment>
      <statement name="STACK">
        <block type="variables_set">
          <field name="VAR">收盤價1</field>
          <value name="VALUE">
            <block type="block_tecp_kline">
              <field name="Value">ClosePrice</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
              <value name="HParam">
                <block type="block_hparam">
                  <field name="HParam">1Minute</field>
                </block>
              </value>
              <value name="Index">
                <block type="math_number">
                  <field name="NUM">1</field>
                </block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">收盤價2</field>
              <value name="VALUE">
                <block type="block_tecp_kline">
                  <field name="Value">ClosePrice</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                  <value name="HParam">
                    <block type="block_hparam">
                      <field name="HParam">1Minute</field>
                    </block>
                  </value>
                  <value name="Index">
                    <block type="math_number">
                      <field name="NUM">2</field>
                    </block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">K線漲跌</field>
                  <value name="VALUE">
                    <block type="math_arithmetic">
                      <field name="OP">MINUS</field>
                      <value name="A">
                        <shadow type="math_number">
                          <field name="NUM">1</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">收盤價1</field>
                        </block>
                      </value>
                      <value name="B">
                        <shadow type="math_number">
                          <field name="NUM">1</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">收盤價2</field>
                        </block>
                      </value>
                    </block>
                  </value>
                  <next>
                    <block type="controls_if">
                      <mutation else="1"></mutation>
                      <value name="IF0">
                        <block type="logic_compare">
                          <field name="OP">LT</field>
                          <value name="A">
                            <block type="variables_get">
                              <field name="VAR">K線漲跌</field>
                            </block>
                          </value>
                          <value name="B">
                            <block type="math_number">
                              <field name="NUM">0</field>
                            </block>
                          </value>
                        </block>
                      </value>
                      <statement name="DO0">
                        <block type="variables_set">
                          <field name="VAR">函式運算結果</field>
                          <value name="VALUE">
                            <block type="logic_boolean">
                              <field name="BOOL">TRUE</field>
                            </block>
                          </value>
                        </block>
                      </statement>
                      <statement name="ELSE">
                        <block type="variables_set">
                          <field name="VAR">函式運算結果</field>
                          <value name="VALUE">
                            <block type="logic_boolean">
                              <field name="BOOL">FALSE</field>
                            </block>
                          </value>
                        </block>
                      </statement>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <value name="RETURN">
        <block type="variables_get">
          <field name="VAR">函式運算結果</field>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="1391">
      <field name="NAME">判斷允許做空</field>
      <comment pinned="false" h="34" w="151">使用者自訂此函式</comment>
      <statement name="STACK">
        <block type="variables_set">
          <field name="VAR">收盤價1</field>
          <value name="VALUE">
            <block type="block_tecp_kline">
              <field name="Value">ClosePrice</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
              <value name="HParam">
                <block type="block_hparam">
                  <field name="HParam">1Minute</field>
                </block>
              </value>
              <value name="Index">
                <block type="math_number">
                  <field name="NUM">1</field>
                </block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">收盤價2</field>
              <value name="VALUE">
                <block type="block_tecp_kline">
                  <field name="Value">ClosePrice</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                  <value name="HParam">
                    <block type="block_hparam">
                      <field name="HParam">1Minute</field>
                    </block>
                  </value>
                  <value name="Index">
                    <block type="math_number">
                      <field name="NUM">2</field>
                    </block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">K線漲跌</field>
                  <value name="VALUE">
                    <block type="math_arithmetic">
                      <field name="OP">MINUS</field>
                      <value name="A">
                        <shadow type="math_number">
                          <field name="NUM">1</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">收盤價1</field>
                        </block>
                      </value>
                      <value name="B">
                        <shadow type="math_number">
                          <field name="NUM">1</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">收盤價2</field>
                        </block>
                      </value>
                    </block>
                  </value>
                  <next>
                    <block type="controls_if">
                      <mutation else="1"></mutation>
                      <value name="IF0">
                        <block type="logic_compare">
                          <field name="OP">LT</field>
                          <value name="A">
                            <block type="variables_get">
                              <field name="VAR">K線漲跌</field>
                            </block>
                          </value>
                          <value name="B">
                            <block type="math_number">
                              <field name="NUM">0</field>
                            </block>
                          </value>
                        </block>
                      </value>
                      <statement name="DO0">
                        <block type="variables_set">
                          <field name="VAR">函式運算結果</field>
                          <value name="VALUE">
                            <block type="logic_boolean">
                              <field name="BOOL">TRUE</field>
                            </block>
                          </value>
                        </block>
                      </statement>
                      <statement name="ELSE">
                        <block type="variables_set">
                          <field name="VAR">函式運算結果</field>
                          <value name="VALUE">
                            <block type="logic_boolean">
                              <field name="BOOL">FALSE</field>
                            </block>
                          </value>
                        </block>
                      </statement>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <value name="RETURN">
        <block type="variables_get">
          <field name="VAR">函式運算結果</field>
        </block>
      </value>
    </block>
    <block type="procedures_defreturn" x="14" y="1688">
      <field name="NAME">判斷允許空平</field>
      <comment pinned="false" h="36" w="148">使用者自訂此函式</comment>
      <statement name="STACK">
        <block type="variables_set">
          <field name="VAR">收盤價1</field>
          <value name="VALUE">
            <block type="block_tecp_kline">
              <field name="Value">ClosePrice</field>
              <value name="Commodity">
                <block type="block_commodity"></block>
              </value>
              <value name="HParam">
                <block type="block_hparam">
                  <field name="HParam">1Minute</field>
                </block>
              </value>
              <value name="Index">
                <block type="math_number">
                  <field name="NUM">1</field>
                </block>
              </value>
            </block>
          </value>
          <next>
            <block type="variables_set">
              <field name="VAR">收盤價2</field>
              <value name="VALUE">
                <block type="block_tecp_kline">
                  <field name="Value">ClosePrice</field>
                  <value name="Commodity">
                    <block type="block_commodity"></block>
                  </value>
                  <value name="HParam">
                    <block type="block_hparam">
                      <field name="HParam">1Minute</field>
                    </block>
                  </value>
                  <value name="Index">
                    <block type="math_number">
                      <field name="NUM">2</field>
                    </block>
                  </value>
                </block>
              </value>
              <next>
                <block type="variables_set">
                  <field name="VAR">K線漲跌</field>
                  <value name="VALUE">
                    <block type="math_arithmetic">
                      <field name="OP">MINUS</field>
                      <value name="A">
                        <shadow type="math_number">
                          <field name="NUM">1</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">收盤價1</field>
                        </block>
                      </value>
                      <value name="B">
                        <shadow type="math_number">
                          <field name="NUM">1</field>
                        </shadow>
                        <block type="variables_get">
                          <field name="VAR">收盤價2</field>
                        </block>
                      </value>
                    </block>
                  </value>
                  <next>
                    <block type="controls_if">
                      <mutation else="1"></mutation>
                      <value name="IF0">
                        <block type="logic_compare">
                          <field name="OP">GT</field>
                          <value name="A">
                            <block type="variables_get">
                              <field name="VAR">K線漲跌</field>
                            </block>
                          </value>
                          <value name="B">
                            <block type="math_number">
                              <field name="NUM">0</field>
                            </block>
                          </value>
                        </block>
                      </value>
                      <statement name="DO0">
                        <block type="variables_set">
                          <field name="VAR">函式運算結果</field>
                          <value name="VALUE">
                            <block type="logic_boolean">
                              <field name="BOOL">TRUE</field>
                            </block>
                          </value>
                        </block>
                      </statement>
                      <statement name="ELSE">
                        <block type="variables_set">
                          <field name="VAR">函式運算結果</field>
                          <value name="VALUE">
                            <block type="logic_boolean">
                              <field name="BOOL">FALSE</field>
                            </block>
                          </value>
                        </block>
                      </statement>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <value name="RETURN">
        <block type="variables_get">
          <field name="VAR">函式運算結果</field>
        </block>
      </value>
    </block>
  </xml>`,
};
export default KLine_Inc;
