import React from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

export function withRouter(InputComponent) {
   return (props) => {
      const App = React.useRef((_szPath) => {});
      const location = useLocation();
      const params = useParams();
      const Navigate = useNavigate();

      React.useEffect(() => {
         App.current?.(location.pathname);
      }, [location.pathname]);

      const Route = {
         location: location,
         Navigate: Navigate,
         params: params,
         App,
      };
      return <InputComponent {...props} Route={Route} />;
   };
}
