import { ToolboxDefinition } from "react-blockly";
import { Toolbox } from "./Blocks";

const INITIAL_XML = `
<xml xmlns="https://developers.google.com/blockly/xml">
  <block type="block_mainfunction" x="14" y="14">
    <field name="StrategyName">自訂策略</field>
    <field name="Description">描述本策略</field>
  </block>
</xml>`;

const EmptyXml = '<xml xmlns="https://developers.google.com/blockly/xml"></xml>';

const TOOLBOX_Separate = { kind: "sep" };

const TOOLBOX_Logic = {
   kind: "category",
   //name: "Logic",
   name: "%{BKY_catLogic}",
   categorystyle: "logic_category",
   contents: [
      {
         kind: "block",
         type: "controls_if",
      },
      {
         kind: "block",
         blockxml: `<block type="logic_compare"><field name="OP">EQ</field></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="logic_operation"><field name="OP">AND</field></block>`,
      },
      {
         kind: "block",
         type: "logic_negate",
      },
      {
         kind: "block",
         blockxml: `<block type="logic_boolean"><field name="BOOL">TRUE</field></block>`,
      },
      {
         kind: "block",
         type: "logic_null",
      },
      {
         kind: "block",
         type: "logic_ternary",
      },
   ],
};
const TOOLBOX_Loops = {
   kind: "category",
   name: "%{BKY_catLoops}",
   categorystyle: "loop_category",
   contents: [
      {
         kind: "block",
         blockxml: `
         <block type="controls_repeat_ext">
            <value name="TIMES">
               <shadow type="math_number">
                  <field name="NUM">10</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="controls_whileUntil">
            <field name="MODE">WHILE</field>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="controls_for">
            <field name="VAR" variabletype="">i</field>
            <value name="FROM">
               <shadow type="math_number">
                  <field name="NUM">1</field>
               </shadow>
            </value>
            <value name="TO">
               <shadow type="math_number">
                  <field name="NUM">10</field>
               </shadow>
            </value>
            <value name="BY">
               <shadow type="math_number">
                  <field name="NUM">1</field>
               </shadow>
            </value>
         </block>`,
      },
      // {
      //    kind: "block",
      //    blockxml: `
      //    <block type="controls_forEach">
      //       <field name="VAR" variabletype="">j</field>
      //    </block>`,
      // },
      {
         kind: "block",
         blockxml: `
         <block type="controls_flow_statements">
            <field name="FLOW">BREAK</field>
         </block>`,
      },
   ],
};
const TOOLBOX_Math = {
   kind: "category",
   name: "%{BKY_catMath}",
   categorystyle: "math_category",
   contents: [
      {
         kind: "block",
         blockxml: `
         <block type="math_round">
            <field name="OP">ROUND</field>
            <value name="NUM">
               <shadow type="math_number">
                  <field name="NUM">3.1</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_number">
            <field name="NUM">0</field>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_single">
            <field name="OP">ROOT</field>
            <value name="NUM">
               <shadow type="math_number">
                  <field name="NUM">9</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_trig">
            <field name="OP">SIN</field>
            <value name="NUM">
               <shadow type="math_number">
                  <field name="NUM">45</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_constant">
            <field name="CONSTANT">PI</field>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_number_property">
            <mutation divisor_input="false"></mutation>
            <field name="PROPERTY">EVEN</field>
            <value name="NUMBER_TO_CHECK">
               <shadow type="math_number">
                  <field name="NUM">0</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_arithmetic">
            <field name="OP">ADD</field>
            <value name="A">
               <shadow type="math_number">
                  <field name="NUM">1</field>
               </shadow>
            </value>
            <value name="B">
               <shadow type="math_number">
                  <field name="NUM">1</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_on_list">
            <mutation op="SUM"></mutation>
            <field name="OP">SUM</field>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_modulo">
            <value name="DIVIDEND">
               <shadow type="math_number">
                  <field name="NUM">64</field>
               </shadow>
            </value>
            <value name="DIVISOR">
               <shadow type="math_number">
                  <field name="NUM">10</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_constrain">
            <value name="VALUE">
               <shadow type="math_number">
                  <field name="NUM">50</field>
               </shadow>
            </value>
            <value name="LOW">
               <shadow type="math_number">
                  <field name="NUM">1</field>
               </shadow>
            </value>
            <value name="HIGH">
               <shadow type="math_number">
                  <field name="NUM">100</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="math_random_int">
            <value name="FROM">
               <shadow type="math_number">
                  <field name="NUM">1</field>
               </shadow>
            </value>
            <value name="TO">
               <shadow type="math_number">
                  <field name="NUM">100</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         type: "math_random_float",
      },
   ],
};
const TOOLBOX_Text = {
   kind: "category",
   name: "%{BKY_catText}",
   categorystyle: "text_category",
   contents: [
      {
         kind: "block",
         blockxml: `
         <block type="text_charAt">
            <mutation at="true"></mutation>
            <field name="WHERE">FROM_START</field>
            <value name="VALUE">
               <block type="variables_get">
                  <field name="VAR" variabletype="">text</field>
               </block>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text">
            <field name="TEXT"></field>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_append">
            <field name="VAR" variabletype="">item</field>
            <value name="TEXT">
               <shadow type="text">
                  <field name="TEXT"></field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_length">
            <value name="VALUE">
               <shadow type="text">
                  <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_isEmpty">
            <value name="VALUE">
               <shadow type="text">
                  <field name="TEXT"></field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_indexOf">
            <field name="END">FIRST</field>
            <value name="VALUE">
               <block type="variables_get">
                  <field name="VAR" variabletype="">text</field>
               </block>
            </value>
            <value name="FIND">
               <shadow type="text">
                  <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_join">
            <mutation items="2"></mutation>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_getSubstring">
            <mutation at1="true" at2="true"></mutation>
            <field name="WHERE1">FROM_START</field>
            <field name="WHERE2">FROM_START</field>
            <value name="STRING">
               <block type="variables_get">
                  <field name="VAR" variabletype="">text</field>
               </block>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_changeCase">
            <field name="CASE">UPPERCASE</field>
            <value name="TEXT">
               <shadow type="text">
                  <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_trim">
            <field name="MODE">BOTH</field>
            <value name="TEXT">
               <shadow type="text">
                  <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_count">
            <value name="SUB">
               <shadow type="text"></shadow>
            </value>
            <value name="TEXT">
               <shadow type="text"></shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_replace">
            <value name="FROM">
               <shadow type="text"></shadow>
            </value>
            <value name="TO">
               <shadow type="text"></shadow>
            </value>
            <value name="TEXT">
               <shadow type="text"></shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="text_reverse">
            <value name="TEXT">
               <shadow type="text"></shadow>
            </value>
         </block>`,
      },
      // {
      //   kind: "block",
      //   blockxml:`
      //    <block type="text_print">
      //       <value name="TEXT">
      //          <shadow type="text">
      //             <field name="TEXT">abc</field>
      //          </shadow>
      //       </value>
      //    </block>`,
      // },
      // {
      //   kind: "block",
      //   blockxml:`
      //   <block type="text_prompt_ext">
      //       <mutation type="TEXT"></mutation>
      //       <field name="TYPE">TEXT</field>
      //       <value name="TEXT">
      //          <shadow type="text">
      //          <field name="TEXT">abc</field>
      //          </shadow>
      //       </value>
      //   </block>`,
      // },
   ],
};
const TOOLBOX_Lists = {
   kind: "category",
   name: "%{BKY_catLists}",
   categorystyle: "list_category",
   contents: [
      {
         kind: "block",
         blockxml: `
         <block type="lists_indexOf">
            <field name="END">FIRST</field>
            <value name="VALUE">
               <block type="variables_get">
                  <field name="VAR" variabletype="">list</field>
               </block>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="lists_create_with">
            <mutation items="0"></mutation>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="lists_repeat">
            <value name="NUM">
               <shadow type="math_number">
                  <field name="NUM">5</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         type: "lists_length",
      },
      {
         kind: "block",
         type: "lists_isEmpty",
      },
      {
         kind: "block",
         blockxml: `
         <block type="lists_create_with">
            <mutation items="3"></mutation>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="lists_getIndex">
            <mutation statement="false" at="true"></mutation>
            <field name="MODE">GET</field>
            <field name="WHERE">FROM_START</field>
            <value name="VALUE">
               <block type="variables_get">
                  <field name="VAR" variabletype="">list</field>
               </block>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="lists_setIndex">
            <mutation at="true"></mutation>
            <field name="MODE">SET</field>
            <field name="WHERE">FROM_START</field>
            <value name="LIST">
               <block type="variables_get">
                  <field name="VAR" variabletype="">list</field>
               </block>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="lists_getSublist">
            <mutation at1="true" at2="true"></mutation>
            <field name="WHERE1">FROM_START</field>
            <field name="WHERE2">FROM_START</field>
            <value name="LIST">
               <block type="variables_get">
                  <field name="VAR" variabletype="">list</field>
               </block>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="lists_split">
            <mutation mode="SPLIT"></mutation>
            <field name="MODE">SPLIT</field>
            <value name="DELIM">
               <shadow type="text">
                  <field name="TEXT">,</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="lists_sort">
            <field name="TYPE">NUMERIC</field>
            <field name="DIRECTION">1</field>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="lists_sort"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="lists_reverse"></block>`,
      },
   ],
};
const TOOLBOX_Colour = {
   kind: "category",
   name: "%{BKY_catColour}",
   categorystyle: "colour_category",
   contents: [
      {
         kind: "block",
         blockxml: `
         <block type="colour_picker">
            <field name="COLOUR">#ff0000</field>
         </block>`,
      },
      {
         kind: "block",
         type: "colour_random",
      },
      {
         kind: "block",
         blockxml: `
         <block type="colour_rgb">
            <value name="RED">
               <shadow type="math_number">
                  <field name="NUM">100</field>
               </shadow>
            </value>
            <value name="GREEN">
               <shadow type="math_number">
                  <field name="NUM">50</field>
               </shadow>
            </value>
            <value name="BLUE">
               <shadow type="math_number">
                  <field name="NUM">0</field>
               </shadow>
            </value>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="colour_blend">
            <value name="COLOUR1">
               <shadow type="colour_picker">
                  <field name="COLOUR">#ff0000</field>
               </shadow>
            </value>
            <value name="COLOUR2">
               <shadow type="colour_picker">
                  <field name="COLOUR">#3333ff</field>
               </shadow>
            </value>
            <value name="RATIO">
               <shadow type="math_number">
                  <field name="NUM">0.5</field>
               </shadow>
            </value>
         </block>`,
      },
   ],
};
const TOOLBOX_Variables = {
   kind: "category",
   name: "%{BKY_catVariables}",
   custom: "VARIABLE",
   categorystyle: "variable_category",
   contents: [
      {
         kind: "block",
         blockxml: `<block type="block_commodity"></block>`,
      },
   ],
};
const TOOLBOX_Variables_DYNAMIC = {
   kind: "category",
   name: "%{BKY_catVariables}",
   custom: "VARIABLE_DYNAMIC",
   categorystyle: "variable_dynamic_category",
};
const TOOLBOX_Functions = {
   kind: "category",
   name: "%{BKY_catFunctions}",
   custom: "PROCEDURE",
   categorystyle: "procedure_category",
};
const TOOLBOX_CustomButton = {
   kind: "category",
   name: "Custom Button",
   colour: 19,
   contents: [
      {
         kind: "button",
         text: "A button",
         callbackKey: "myFirstButtonPressed",
      },
   ],
};
const TOOLBOX_Debug = {
   kind: "category",
   name: "Cus",
   colour: 350,
   contents: [
      {
         kind: "block",
         blockxml: `
         <block type="colour_picker">
            <field name="COLOUR">#ff0000</field>
         </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_commodity"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_DateTime"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_hparam"></block>`,
      },
      {
         kind: "block",
         blockxml: `
         <block type="block_string_length_2">
            <value name="VALUE">
               <shadow type="text">
                  <field name="TEXT">9987</field>
               </shadow>
            </value>
         </block>`,
      },
   ],
};
const INITIAL_TOOLBOX_JSON = {
   kind: "categoryToolbox",
   contents: [
      Toolbox.Main,
      TOOLBOX_Separate,
      Toolbox.Account,
      Toolbox.Commodity,
      Toolbox.Quote,
      Toolbox.TECP,
      Toolbox.Order,
      TOOLBOX_Separate,
      TOOLBOX_Variables,
      //TOOLBOX_Variables_DYNAMIC,
      TOOLBOX_Functions,
      TOOLBOX_Separate,
      TOOLBOX_Logic,
      TOOLBOX_Loops,
      TOOLBOX_Math,
      TOOLBOX_Text,
      //TOOLBOX_Lists,
      //TOOLBOX_Colour,
      Toolbox.DateTime,
      //TOOLBOX_Debug,
   ],
};

const INITIAL_TOOLBOX_XML = `
   <xml xmlns="http://www.w3.org/1999/xhtml" id="toolbox" style="display: none;">
      <category name="Logic" colour="#5C81A6">
         <block type="controls_if"></block>
         <block type="logic_compare">
            <field name="OP">EQ</field>
         </block>
         <block type="logic_operation">
            <field name="OP">AND</field>
         </block>
         <block type="logic_negate"></block>
         <block type="logic_boolean">
            <field name="BOOL">TRUE</field>
         </block>
         <block type="logic_null"></block>
         <block type="logic_ternary"></block>
      </category>
      <category name="Loops" colour="#5CA65C">
         <block type="controls_repeat_ext">
            <value name="TIMES">
               <shadow type="math_number">
               <field name="NUM">10</field>
               </shadow>
            </value>
         </block>
         <block type="controls_whileUntil">
            <field name="MODE">WHILE</field>
         </block>
         <block type="controls_for">
            <field name="VAR" variabletype="">i</field>
            <value name="FROM">
               <shadow type="math_number">
               <field name="NUM">1</field>
               </shadow>
            </value>
            <value name="TO">
               <shadow type="math_number">
               <field name="NUM">10</field>
               </shadow>
            </value>
            <value name="BY">
               <shadow type="math_number">
               <field name="NUM">1</field>
               </shadow>
            </value>
         </block>
         <block type="controls_forEach">
            <field name="VAR" variabletype="">j</field>
         </block>
         <block type="controls_flow_statements">
            <field name="FLOW">BREAK</field>
         </block>
      </category>
      <category name="Math" colour="#5C68A6">
         <block type="math_round">
            <field name="OP">ROUND</field>
            <value name="NUM">
               <shadow type="math_number">
               <field name="NUM">3.1</field>
               </shadow>
            </value>
         </block>
         <block type="math_number">
            <field name="NUM">0</field>
         </block>
         <block type="math_single">
            <field name="OP">ROOT</field>
            <value name="NUM">
               <shadow type="math_number">
               <field name="NUM">9</field>
               </shadow>
            </value>
         </block>
         <block type="math_trig">
            <field name="OP">SIN</field>
            <value name="NUM">
               <shadow type="math_number">
               <field name="NUM">45</field>
               </shadow>
            </value>
         </block>
         <block type="math_constant">
            <field name="CONSTANT">PI</field>
         </block>
         <block type="math_number_property">
            <mutation divisor_input="false"></mutation>
            <field name="PROPERTY">EVEN</field>
            <value name="NUMBER_TO_CHECK">
               <shadow type="math_number">
               <field name="NUM">0</field>
               </shadow>
            </value>
         </block>
         <block type="math_arithmetic">
            <field name="OP">ADD</field>
            <value name="A">
               <shadow type="math_number">
               <field name="NUM">1</field>
               </shadow>
            </value>
            <value name="B">
               <shadow type="math_number">
               <field name="NUM">1</field>
               </shadow>
            </value>
         </block>
         <block type="math_on_list">
            <mutation op="SUM"></mutation>
            <field name="OP">SUM</field>
         </block>
         <block type="math_modulo">
            <value name="DIVIDEND">
               <shadow type="math_number">
               <field name="NUM">64</field>
               </shadow>
            </value>
            <value name="DIVISOR">
               <shadow type="math_number">
               <field name="NUM">10</field>
               </shadow>
            </value>
         </block>
         <block type="math_constrain">
            <value name="VALUE">
               <shadow type="math_number">
               <field name="NUM">50</field>
               </shadow>
            </value>
            <value name="LOW">
               <shadow type="math_number">
               <field name="NUM">1</field>
               </shadow>
            </value>
            <value name="HIGH">
               <shadow type="math_number">
               <field name="NUM">100</field>
               </shadow>
            </value>
         </block>
         <block type="math_random_int">
            <value name="FROM">
               <shadow type="math_number">
               <field name="NUM">1</field>
               </shadow>
            </value>
            <value name="TO">
               <shadow type="math_number">
               <field name="NUM">100</field>
               </shadow>
            </value>
         </block>
         <block type="math_random_float"></block>
      </category>
      <category name="Text" colour="#5CA68D">
         <block type="text_charAt">
            <mutation at="true"></mutation>
            <field name="WHERE">FROM_START</field>
            <value name="VALUE">
               <block type="variables_get">
               <field name="VAR" variabletype="">text</field>
               </block>
            </value>
         </block>
         <block type="text">
            <field name="TEXT"></field>
         </block>
         <block type="text_append">
            <field name="VAR" variabletype="">item</field>
            <value name="TEXT">
               <shadow type="text">
               <field name="TEXT"></field>
               </shadow>
            </value>
         </block>
         <block type="text_length">
            <value name="VALUE">
               <shadow type="text">
               <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>
         <block type="text_isEmpty">
            <value name="VALUE">
               <shadow type="text">
               <field name="TEXT"></field>
               </shadow>
            </value>
         </block>
         <block type="text_indexOf">
            <field name="END">FIRST</field>
            <value name="VALUE">
               <block type="variables_get">
               <field name="VAR" variabletype="">text</field>
               </block>
            </value>
            <value name="FIND">
               <shadow type="text">
               <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>
         <block type="text_join">
            <mutation items="2"></mutation>
         </block>
         <block type="text_getSubstring">
            <mutation at1="true" at2="true"></mutation>
            <field name="WHERE1">FROM_START</field>
            <field name="WHERE2">FROM_START</field>
            <value name="STRING">
               <block type="variables_get">
               <field name="VAR" variabletype="">text</field>
               </block>
            </value>
         </block>
         <block type="text_changeCase">
            <field name="CASE">UPPERCASE</field>
            <value name="TEXT">
               <shadow type="text">
               <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>
         <block type="text_trim">
            <field name="MODE">BOTH</field>
            <value name="TEXT">
               <shadow type="text">
               <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>
         <block type="text_print">
            <value name="TEXT">
               <shadow type="text">
               <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>
         <block type="text_prompt_ext">
            <mutation type="TEXT"></mutation>
            <field name="TYPE">TEXT</field>
            <value name="TEXT">
               <shadow type="text">
               <field name="TEXT">abc</field>
               </shadow>
            </value>
         </block>
      </category>
      <category name="Lists" colour="#745CA6">
         <block type="lists_indexOf">
            <field name="END">FIRST</field>
            <value name="VALUE">
               <block type="variables_get">
               <field name="VAR" variabletype="">list</field>
               </block>
            </value>
         </block>
         <block type="lists_create_with">
            <mutation items="0"></mutation>
         </block>
         <block type="lists_repeat">
            <value name="NUM">
               <shadow type="math_number">
               <field name="NUM">5</field>
               </shadow>
            </value>
         </block>
         <block type="lists_length"></block>
         <block type="lists_isEmpty"></block>
         <block type="lists_create_with">
            <mutation items="3"></mutation>
         </block>
         <block type="lists_getIndex">
            <mutation statement="false" at="true"></mutation>
            <field name="MODE">GET</field>
            <field name="WHERE">FROM_START</field>
            <value name="VALUE">
               <block type="variables_get">
               <field name="VAR" variabletype="">list</field>
               </block>
            </value>
         </block>
         <block type="lists_setIndex">
            <mutation at="true"></mutation>
            <field name="MODE">SET</field>
            <field name="WHERE">FROM_START</field>
            <value name="LIST">
               <block type="variables_get">
               <field name="VAR" variabletype="">list</field>
               </block>
            </value>
         </block>
         <block type="lists_getSublist">
            <mutation at1="true" at2="true"></mutation>
            <field name="WHERE1">FROM_START</field>
            <field name="WHERE2">FROM_START</field>
            <value name="LIST">
               <block type="variables_get">
               <field name="VAR" variabletype="">list</field>
               </block>
            </value>
         </block>
         <block type="lists_split">
            <mutation mode="SPLIT"></mutation>
            <field name="MODE">SPLIT</field>
            <value name="DELIM">
               <shadow type="text">
               <field name="TEXT">,</field>
               </shadow>
            </value>
         </block>
         <block type="lists_sort">
            <field name="TYPE">NUMERIC</field>
            <field name="DIRECTION">1</field>
         </block>
      </category>
      <category name="Colour" colour="#A6745C">
         <block type="colour_picker">
            <field name="COLOUR">#ff0000</field>
         </block>
         <block type="colour_random"></block>
         <block type="colour_rgb">
            <value name="RED">
               <shadow type="math_number">
               <field name="NUM">100</field>
               </shadow>
            </value>
            <value name="GREEN">
               <shadow type="math_number">
               <field name="NUM">50</field>
               </shadow>
            </value>
            <value name="BLUE">
               <shadow type="math_number">
               <field name="NUM">0</field>
               </shadow>
            </value>
         </block>
         <block type="colour_blend">
            <value name="COLOUR1">
               <shadow type="colour_picker">
               <field name="COLOUR">#ff0000</field>
               </shadow>
            </value>
            <value name="COLOUR2">
               <shadow type="colour_picker">
               <field name="COLOUR">#3333ff</field>
               </shadow>
            </value>
            <value name="RATIO">
               <shadow type="math_number">
               <field name="NUM">0.5</field>
               </shadow>
            </value>
         </block>
      </category>
      <sep></sep>
      <category name="Custom Button" colour="#A6745C">
         <button text="A button" callbackKey="myFirstButtonPressed"></button>
      </category>
      <category name="Variables" colour="#A65C81" custom="VARIABLE"></category>
      <category name="Functions" colour="#9A5CA6" custom="PROCEDURE"></category>
    </xml>`;

const INITIAL_TOOLBOX_CATEGORIES = [
   {
      name: "Controls",
      blocks: [
         { type: "controls_if" },
         {
            type: "controls_repeat_ext",
            values: {
               TIMES: {
                  type: "math_number",
                  shadow: true,
                  fields: {
                     NUM: 10,
                  },
               },
            },
            statements: {
               DO: {
                  type: "text_print",
                  shadow: true,
                  values: {
                     TEXT: {
                        type: "text",
                        shadow: true,
                        fields: {
                           TEXT: "abc",
                        },
                     },
                  },
               },
            },
         },
      ],
   },
   {
      name: "Text",
      blocks: [
         { type: "text" },
         {
            type: "text_print",
            values: {
               TEXT: {
                  type: "text",
                  shadow: true,
                  fields: {
                     TEXT: "abc",
                  },
               },
            },
         },
      ],
   },
];

const INITIAL_TOOLBOX_JSON_Object = INITIAL_TOOLBOX_JSON as ToolboxDefinition;

const ConfigFiles = {
   EmptyXml,
   INITIAL_XML,
   INITIAL_TOOLBOX_XML,
   INITIAL_TOOLBOX_JSON: INITIAL_TOOLBOX_JSON_Object,
   INITIAL_TOOLBOX_CATEGORIES,
   Get_INITIAL_XML(_StrategyName?: string, Description?: string) {
      var szRet = INITIAL_XML;
      if (_StrategyName) szRet = szRet.replaceAll("自訂策略", _StrategyName);
      if (Description) szRet = szRet.replaceAll("描述本策略", Description);
      return szRet;
   },
};

export default ConfigFiles;
