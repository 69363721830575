import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes } from "./Block_Define";

// Blockly.Blocks["block_datetime_gethparam"] = {
//    init: function () {
//       this.appendDummyInput().appendField("取得目前圖表時間週期");
//       this.setOutput(true, FieldTypes.HParam);
//       this.setStyle(Keys_Block.HParam);
//       this.setTooltip("");
//       this.setHelpUrl("");
//    },
// };

Blockly.Blocks["block_datetime_gettime"] = {
   init: function () {
      this.appendDummyInput()
         .appendField("取得")
         .appendField(
            new Blockly.FieldDropdown([
               ["伺服器", "Server"],
               ["客戶端", "Client"],
            ]),
            "Type"
         )
         .appendField("目前時間");
      this.setOutput(true, FieldTypes.DateTime);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_gettimefield"] = {
   init: function () {
      this.appendDummyInput()
         .appendField("取得")
         .appendField(
            new Blockly.FieldDropdown([
               ["伺服器", "Server"],
               ["客戶端", "Client"],
            ]),
            "Type"
         )
         .appendField("目前時間")
         .appendField(
            new Blockly.FieldDropdown([
               ["年", "Year"],
               ["月", "Month"],
               ["日", "Day"],
               ["時", "Hour"],
               ["分", "Minute"],
               ["秒", "Second"],
               ["星期(1,2,3,4,5,6,0)", "DayOfWeek"],
            ]),
            "Field"
         );
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_timespan"] = {
   init: function () {
      this.appendDummyInput().appendField("有效時間").appendField(new Blockly.FieldDropdown(EnumDropDownItems_Day()), "Day").appendField("日").appendField(new Blockly.FieldDropdown(EnumDropDownItems_Hour()), "Hour").appendField("小時").appendField(new Blockly.FieldDropdown(EnumDropDownItems_Minute()), "Minute").appendField("分鐘").appendField(new Blockly.FieldDropdown(EnumDropDownItems_Second()), "Second").appendField("秒");
      this.setOutput(true, FieldTypes.DateTime);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_newdatetime"] = {
   init: function () {
      this.appendValueInput("Year").setCheck(FieldTypes.Number).appendField("指定時間");
      this.appendValueInput("Month").setCheck(FieldTypes.Number).appendField("年");
      this.appendValueInput("Day").setCheck(FieldTypes.Number).appendField("月");
      this.appendValueInput("Hour").setCheck(FieldTypes.Number).appendField("日");
      this.appendValueInput("Minute").setCheck(FieldTypes.Number).appendField("時");
      this.appendValueInput("Second").setCheck(FieldTypes.Number).appendField("分");
      this.appendDummyInput().appendField("秒");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.DateTime);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_getfield"] = {
   init: function () {
      this.appendValueInput("Value").setCheck(FieldTypes.DateTime).appendField("取得").appendField("指定時間");
      this.appendDummyInput()
         .appendField("時間")
         .appendField(
            new Blockly.FieldDropdown([
               ["年", "Year"],
               ["月", "Month"],
               ["日", "Day"],
               ["時", "Hour"],
               ["分", "Minute"],
               ["秒", "Second"],
               ["星期(1,2,3,4,5,6,0)", "DayOfWeek"],
            ]),
            "Field"
         );
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_getklinetime"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得K棒時間").appendField("商品");
      this.appendValueInput("HParam").setCheck(FieldTypes.HParam).appendField("時間週期");
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("K棒前移");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.DateTime);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_getordertime"] = {
   init: function () {
      this.appendValueInput("TradeIndex").setCheck(FieldTypes.Number).appendField("用單號取得下單時間").appendField("單號");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.DateTime);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_isatrange"] = {
   init: function () {
      this.appendValueInput("Time").setCheck(FieldTypes.DateTime).appendField("是否").appendField("時間");
      this.appendDummyInput().appendField("在此時段").appendField(new Blockly.FieldCheckbox("FALSE"), "1").appendField("星期一").appendField(new Blockly.FieldCheckbox("FALSE"), "2").appendField("星期二").appendField(new Blockly.FieldCheckbox("FALSE"), "3").appendField("星期三").appendField(new Blockly.FieldCheckbox("FALSE"), "4").appendField("星期四").appendField(new Blockly.FieldCheckbox("FALSE"), "5").appendField("星期五").appendField(new Blockly.FieldCheckbox("FALSE"), "6").appendField("星期六").appendField(new Blockly.FieldCheckbox("FALSE"), "0").appendField("星期日").appendField("開始時間").appendField(new Blockly.FieldDropdown(EnumDropDownItems_Hour()), "TS_Hour").appendField("時").appendField(new Blockly.FieldDropdown(EnumDropDownItems_Minute()), "TS_Minute").appendField("分").appendField("到").appendField(new Blockly.FieldDropdown(EnumDropDownItems_Hour()), "TE_Hour").appendField("時").appendField(new Blockly.FieldDropdown(EnumDropDownItems_Minute()), "TE_Minute").appendField("分");
      this.setOutput(true, FieldTypes.Boolean);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_isinrange"] = {
   init: function () {
      this.appendValueInput("Time").setCheck(FieldTypes.DateTime).appendField("是否").appendField("時間");
      this.appendValueInput("StartTime").setCheck(FieldTypes.DateTime).appendField("在此時段").appendField("開始時間");
      this.appendValueInput("EndTime").setCheck(FieldTypes.DateTime).appendField("到");
      //this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Boolean);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_datetime_isdaylightsavingtime"] = {
   init: function () {
      this.appendDummyInput().appendField("是否為日光節約時間");
      this.setOutput(true, FieldTypes.Boolean);
      this.setStyle(Keys_Block.DateTime);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

const EnumDropDownItems_Day = function () {
   var Items: [string, string][] = [];
   for (let i = 0; i <= 31; i++) Items.push([`${i}`, `${i}`]);
   return Items;
};
const EnumDropDownItems_Hour = function () {
   var Items: [string, string][] = [];
   for (let i = 0; i <= 23; i++) Items.push([`${i}`, `${i}`]);
   return Items;
};
const EnumDropDownItems_Minute = function () {
   var Items: [string, string][] = [];
   for (let i = 0; i <= 59; i++) Items.push([`${i}`, `${i}`]);
   return Items;
};
const EnumDropDownItems_Second = function () {
   var Items: [string, string][] = [];
   for (let i = 0; i <= 59; i++) Items.push([`${i}`, `${i}`]);
   return Items;
};

// Blockly.JavaScript["block_datetime_gethparam"] = function (block: Blockly.Block) {
//    // TODO: Assemble JavaScript into code variable.
//    var code = "_GetCurrentHParam()";

//    return [code, Blockly.JavaScript.ORDER_ATOMIC];
// };

Blockly.JavaScript["block_datetime_gettime"] = function (block: Blockly.Block) {
   var dropdown_type = block.getFieldValue("Type");
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetCurrentTime('${dropdown_type}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_gettimefield"] = function (block: Blockly.Block) {
   var dropdown_type = block.getFieldValue("Type");
   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetCurrentTimeField('${dropdown_type}', '${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_timespan"] = function (block: Blockly.Block) {
   var dropdown_day = block.getFieldValue("Day");
   var dropdown_hour = block.getFieldValue("Hour");
   var dropdown_minute = block.getFieldValue("Minute");
   var dropdown_second = block.getFieldValue("Second");
   // TODO: Assemble JavaScript into code variable.
   var code = `_CreateTimeSpan(${dropdown_day}, ${dropdown_hour}, ${dropdown_minute}, ${dropdown_second})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_newdatetime"] = function (block: Blockly.Block) {
   var value_year = Blockly.JavaScript.valueToCode(block, "Year", Blockly.JavaScript.ORDER_ATOMIC);
   var value_month = Blockly.JavaScript.valueToCode(block, "Month", Blockly.JavaScript.ORDER_ATOMIC);
   var value_day = Blockly.JavaScript.valueToCode(block, "Day", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hour = Blockly.JavaScript.valueToCode(block, "Hour", Blockly.JavaScript.ORDER_ATOMIC);
   var value_minute = Blockly.JavaScript.valueToCode(block, "Minute", Blockly.JavaScript.ORDER_ATOMIC);
   var value_second = Blockly.JavaScript.valueToCode(block, "Second", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_CreateDateTime(${value_year}, ${value_month}, ${value_day}, ${value_hour}, ${value_minute}, ${value_second})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_getfield"] = function (block: Blockly.Block) {
   var value_value = Blockly.JavaScript.valueToCode(block, "Value", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetDateTimeField(${value_value},'${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_getklinetime"] = function (block: Blockly.Block) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var value_hparam = Blockly.JavaScript.valueToCode(block, "HParam", Blockly.JavaScript.ORDER_ATOMIC);
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetKLineTime(${value_commodity}, ${value_hparam}, ${value_index})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_getordertime"] = function (block: Blockly.Block) {
   var value_tradeindex = Blockly.JavaScript.valueToCode(block, "TradeIndex", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetOrderTime(${value_tradeindex})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_isatrange"] = function (block: Blockly.Block) {
   var value_time = Blockly.JavaScript.valueToCode(block, "Time", Blockly.JavaScript.ORDER_ATOMIC);
   var checkbox_1 = block.getFieldValue("1") === "TRUE";
   var checkbox_2 = block.getFieldValue("2") === "TRUE";
   var checkbox_3 = block.getFieldValue("3") === "TRUE";
   var checkbox_4 = block.getFieldValue("4") === "TRUE";
   var checkbox_5 = block.getFieldValue("5") === "TRUE";
   var checkbox_6 = block.getFieldValue("6") === "TRUE";
   var checkbox_0 = block.getFieldValue("0") === "TRUE";
   var dropdown_ts_hour = block.getFieldValue("TS_Hour");
   var dropdown_ts_minute = block.getFieldValue("TS_Minute");
   var dropdown_te_hour = block.getFieldValue("TE_Hour");
   var dropdown_te_minute = block.getFieldValue("TE_Minute");

   var Checks = [];
   if (checkbox_1) Checks.push(1);
   if (checkbox_2) Checks.push(2);
   if (checkbox_3) Checks.push(3);
   if (checkbox_4) Checks.push(4);
   if (checkbox_5) Checks.push(5);
   if (checkbox_6) Checks.push(6);
   if (checkbox_0) Checks.push(0);
   var szChecks = `[${Checks.join(", ")}]`;

   // TODO: Assemble JavaScript into code variable.
   var code = `_IsTimeAtRange(${value_time}, '${szChecks}', ${dropdown_ts_hour}, ${dropdown_ts_minute}, ${dropdown_te_hour}, ${dropdown_te_minute})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_isinrange"] = function (block: Blockly.Block) {
   var value_time = Blockly.JavaScript.valueToCode(block, "Time", Blockly.JavaScript.ORDER_ATOMIC);
   var value_starttime = Blockly.JavaScript.valueToCode(block, "StartTime", Blockly.JavaScript.ORDER_ATOMIC);
   var value_endtime = Blockly.JavaScript.valueToCode(block, "EndTime", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_IsTimeInRange(${value_time}, ${value_starttime}, ${value_endtime})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_datetime_isdaylightsavingtime"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_isdaylightsavingtime() // 尚未實作";

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

export const ToolboxItems_DateTime = {
   kind: "category",
   name: "%{BKY_catDateTime}",
   categorystyle: Keys_Cat.DateTIme,
   contents: [
      // {
      //    kind: "block",
      //    blockxml: `<block type="block_datetime_gethparam"></block>`,
      // },
      {
         kind: "block",
         blockxml: `<block type="block_datetime_gettime">
        <field name="Type">Server</field>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_datetime_gettimefield">
        <field name="Type">Server</field>
        <field name="Field">Year</field>
      </block>`,
      },
      // {
      //   kind: "block",
      //   blockxml: `<block type="block_datetime_timespan">
      //     <field name="Day">0</field>
      //     <field name="Hour">0</field>
      //     <field name="Minute">0</field>
      //     <field name="Second">0</field>
      //   </block>`,
      // },
      {
         kind: "block",
         blockxml: `<block type="block_datetime_newdatetime">
        <value name="Year">
          <shadow type="math_number">
            <field name="NUM">2022</field>
          </shadow>
        </value>
        <value name="Month">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="Day">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="Hour">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="Minute">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="Second">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_datetime_getfield">
        <field name="Field">Year</field>
        <value name="Value">
          <block type="block_datetime_gettime">
            <field name="Type">Client</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_datetime_getklinetime">
        <value name="Commodity">
          <block type="block_commodity"></block>
        </value>
        <value name="HParam">
          <block type="block_hparam">
            <field name="HParam">1Minute</field>
          </block>
        </value>
        <value name="Index">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_datetime_getordertime">
        <value name="TradeIndex">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_datetime_isatrange">
        <field name="1">FALSE</field>
        <field name="2">FALSE</field>
        <field name="3">FALSE</field>
        <field name="4">FALSE</field>
        <field name="5">FALSE</field>
        <field name="6">FALSE</field>
        <field name="0">FALSE</field>
        <field name="TS_Hour">0</field>
        <field name="TS_Minute">0</field>
        <field name="TE_Hour">23</field>
        <field name="TE_Minute">59</field>
        <value name="Time">
          <block type="block_datetime_gettime">
            <field name="Type">Client</field>
          </block>
        </value>
      </block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_datetime_isinrange">
        <value name="Time">
          <block type="block_datetime_gettime">
            <field name="Type">Client</field>
          </block>
        </value>
        <value name="StartTime">
          <block type="block_datetime_newdatetime">
            <value name="Year">
              <shadow type="math_number">
                <field name="NUM">2022</field>
              </shadow>
            </value>
            <value name="Month">
              <shadow type="math_number">
                <field name="NUM">1</field>
              </shadow>
            </value>
            <value name="Day">
              <shadow type="math_number">
                <field name="NUM">1</field>
              </shadow>
            </value>
            <value name="Hour">
              <shadow type="math_number">
                <field name="NUM">0</field>
              </shadow>
            </value>
            <value name="Minute">
              <shadow type="math_number">
                <field name="NUM">0</field>
              </shadow>
            </value>
            <value name="Second">
              <shadow type="math_number">
                <field name="NUM">0</field>
              </shadow>
            </value>
          </block>
        </value>
        <value name="EndTime">
          <block type="block_datetime_newdatetime">
            <value name="Year">
              <shadow type="math_number">
                <field name="NUM">2022</field>
              </shadow>
            </value>
            <value name="Month">
              <shadow type="math_number">
                <field name="NUM">1</field>
              </shadow>
            </value>
            <value name="Day">
              <shadow type="math_number">
                <field name="NUM">1</field>
              </shadow>
            </value>
            <value name="Hour">
              <shadow type="math_number">
                <field name="NUM">0</field>
              </shadow>
            </value>
            <value name="Minute">
              <shadow type="math_number">
                <field name="NUM">0</field>
              </shadow>
            </value>
            <value name="Second">
              <shadow type="math_number">
                <field name="NUM">0</field>
              </shadow>
            </value>
          </block>
        </value>
      </block>`,
      },
      // {
      //   kind: "block",
      //   blockxml: `<block type="block_datetime_isdaylightsavingtime"></block>`,
      // },
   ],
};
