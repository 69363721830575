import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes } from "./Block_Define";

Blockly.Blocks["block_account_getinfo_double"] = {
   init: function () {
      this.appendDummyInput()
         .appendField("取得帳戶資料 (double)")
         .appendField("型別")
         .appendField(
            new Blockly.FieldDropdown([
               ["餘額", "SecurityMoney"],
               ["浮動損益", "TotalProfitMoney"],
               ["淨值", "NetWorthMoney"],
               ["已用預付款", "TotalPrepaidMoney"],
               ["可用預付款", "CanUsePrepaidMoney"],
               ["爆倉比例", "PrepaidRate"],
            ]),
            "Field"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Account);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};
Blockly.Blocks["block_account_getsecuritymoney"] = {
   init: function () {
      this.appendDummyInput().appendField("取得").appendField("帳戶").appendField("餘額");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Account);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_account_gettotalprofitmoney"] = {
   init: function () {
      this.appendDummyInput().appendField("取得").appendField("帳戶").appendField("浮動損益");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Account);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_account_getnetworthmoney"] = {
   init: function () {
      this.appendDummyInput().appendField("取得").appendField("帳戶").appendField("淨值");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Account);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_account_gettotalprepaidmoney"] = {
   init: function () {
      this.appendDummyInput().appendField("取得").appendField("帳戶").appendField("已用預付款");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Account);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_account_getcanuseprepaidmoney"] = {
   init: function () {
      this.appendDummyInput().appendField("取得").appendField("帳戶").appendField("可用預付款");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Account);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_account_getprepaidrate"] = {
   init: function () {
      this.appendDummyInput().appendField("取得").appendField("帳戶").appendField("爆倉比例");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Account);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.JavaScript["block_account_getinfo_double"] = function (block: Blockly.Block) {
   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_GetAccountInfo_Double('${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_account_getsecuritymoney"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_GetAccountInfo_SecurityMoney()";

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_account_gettotalprofitmoney"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_GetAccountInfo_TotalProfitMoney()";

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_account_getnetworthmoney"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_GetAccountInfo_NetWorthMoney()";

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_account_gettotalprepaidmoney"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_GetAccountInfo_TotalPrepaidMoney()";

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_account_getcanuseprepaidmoney"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_GetAccountInfo_CanUsePrepaidMoney()";

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_account_getprepaidrate"] = function (block: Blockly.Block) {
   // TODO: Assemble JavaScript into code variable.
   var code = "_GetAccountInfo_PrepaidRate()";

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

export const ToolboxItems_Account = {
   kind: "category",
   name: "%{BKY_catAccount}",
   categorystyle: Keys_Cat.Account,
   contents: [
      // {
      //   kind: "block",
      //   blockxml: `<block type="block_account_getinfo_double"></block>`,
      // },
      {
         kind: "block",
         blockxml: `<block type="block_account_getsecuritymoney"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_account_gettotalprofitmoney"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_account_getnetworthmoney"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_account_gettotalprepaidmoney"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_account_getcanuseprepaidmoney"></block>`,
      },
      {
         kind: "block",
         blockxml: `<block type="block_account_getprepaidrate"></block>`,
      },
   ],
};
