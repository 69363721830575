import Axios from "axios";
import jwt from "jwt-decode";
import * as Types from "./types";

const BaseUrl = "/EABuildApi";
const Token_Key = "EABuildUser";

function SetToken(_Token: string) {
   window?.sessionStorage?.setItem(Token_Key, _Token);
}
function GetToken() {
   const Token = window?.sessionStorage?.getItem(Token_Key);
   if (!Token) return "";
   return Token;
}
function ClearToken() {
   window?.sessionStorage?.removeItem(Token_Key);
}
function TokenToAccount(_Token: string) {
   try {
      const mdAccount = jwt(_Token) as AccountModel;
      return mdAccount;
   } catch (error) {
      return undefined;
   }
}

export interface AccountModel {
   Idx: number;
   Account: string;
   EMail: string;
   Name: string;
   State: string;
   iss: string;
   iat: number;
}

export interface StrategyFileInfoModel {
   uid: string;
   StrategyIndex: number;
   StrategyName: string;
   Description: string;
}

export interface StrategyFileModel extends StrategyFileInfoModel {
   Version: string;
   XML: string;
}

type FailResponse = { Success: false; Message: string };

export interface UpdateStrategyParamModel extends StrategyFileModel {
   Code: string;
}

type URLParam = { Key: string; Value: string };

export class PostApi {
   public static Token = GetToken();
   public static Account = TokenToAccount(this.Token);

   public static async Awake() {
      return await Axios.get(URL + "Awake/")
         .then((response) => {
            return response.data;
         })
         .then((value) => {
            return value;
         });
   }

   public static async Login(Account: string, Password: string): Promise<Types.ILogin_Resault> {
      const url = BaseUrl + "/Login";
      const Data: Types.ILogin_Param = { Account, Password };
      const Response = await Axios({ method: "POST", url, data: Data });

      if (Response.status === 200) {
         const mdRet: Types.ILogin_Resault = { ...Response.data };
         if (mdRet.Success && mdRet.Token) {
            this.UpdateToken(mdRet.Token);
         }

         return Response.data as Types.ILogin_Resault;
      } else {
         const res: Types.ILogin_Resault = {
            Success: false,
            Message: "未處裡錯誤",
         };
         return res;
      }
   }

   public static Logout() {
      ClearToken();
      // window?.location?.reload(); // 流氓reload
   }

   public static async GetStrategyFiles(): Promise<FailResponse | { Success: true; Data: StrategyFileInfoModel[] }> {
      const url = BaseUrl + "/GetStrategyFiles";
      try {
         const Response = await Axios({ method: "POST", url, headers: this.GetAuthHeader() });
         const mdRes: { Success: boolean; Message: string; Data: StrategyFileInfoModel[] } = { ...Response.data };
         const { Success, Message = "", Data } = mdRes;

         if (!Success) return { Success, Message };
         return { Success, Data };
      } catch (e: any) {
         const Message = e?.response?.data ?? "未處裡錯誤";
         const res: FailResponse = { Success: false, Message };
         return res;
      }
   }

   public static async GetStrategy(StrategyIndex: number): Promise<FailResponse | { Success: true; Data: StrategyFileModel }> {
      const url = BaseUrl + "/GetStrategy";
      const data: Types.GetStrategyParamModel = { StrategyIndex, Complete: false };
      try {
         const Response = await Axios({ method: "POST", url, data, headers: this.GetAuthHeader() });
         const mdRes: { Success: boolean; Message: string; Data: StrategyFileModel } = { ...Response.data };
         const { Success, Message = "", Data } = mdRes;

         if (!Success) return { Success, Message };
         return { Success, Data };
      } catch (e: any) {
         const Message = e?.response?.data ?? "未處裡錯誤";
         const res: FailResponse = { Success: false, Message };
         return res;
      }
   }

   public static async CreateStrategy(): Promise<FailResponse | { Success: true; Data: StrategyFileModel }> {
      const url = BaseUrl + "/CreateStrategy";
      try {
         const Response = await Axios({ method: "POST", url, headers: this.GetAuthHeader() });
         const mdRes: { Success: boolean; Message: string; Data: StrategyFileModel } = { ...Response.data };
         const { Success, Message = "", Data } = mdRes;

         if (!Success) return { Success, Message };
         return { Success, Data };
      } catch (e: any) {
         const Message = e?.response?.data ?? "未處裡錯誤";
         const res: FailResponse = { Success: false, Message };
         return res;
      }
   }

   public static async UpdateStrategy(_mdStrategy: UpdateStrategyParamModel): Promise<FailResponse | { Success: true }> {
      const url = BaseUrl + "/UpdateStrategy";
      const data: UpdateStrategyParamModel = { ..._mdStrategy };
      // const data: UpdateStrategyParamModel = {
      //    StrategyIndex,
      //    Version,
      //    StrategyName,
      //    XML,
      //    Code,
      // };

      try {
         const Response = await Axios({ method: "POST", url, data, headers: this.GetAuthHeader() });
         const mdRes: { Success: boolean; Message: string } = { ...Response.data };
         const { Success, Message = "" } = mdRes;

         if (!Success) return { Success, Message };
         return { Success };
      } catch (e: any) {
         const Message = e?.response?.data ?? "未處裡錯誤";
         const res: FailResponse = { Success: false, Message };
         return res;
      }
   }

   public static async DeleteStrategy(StrategyIndex: number): Promise<FailResponse | { Success: true }> {
      const url = BaseUrl + "/DeleteStrategy";
      const data: Types.GetStrategyParamModel = { StrategyIndex, Complete: false };
      try {
         const Response = await Axios({ method: "POST", url, data, headers: this.GetAuthHeader() });
         const mdRes: { Success: boolean; Message: string } = { ...Response.data };
         const { Success, Message = "" } = mdRes;

         if (!Success) return { Success, Message };
         return { Success };
      } catch (e: any) {
         const Message = e?.response?.data ?? "未處裡錯誤";
         const res: FailResponse = { Success: false, Message };
         return res;
      }
   }

   /* ------------------------------------------------- */
   // 尚未變更

   // Externals 暫時放在這
   public static URLParamsToObject(_object: Object, _szParams: string): boolean {
      if (_object === null || _object === undefined) return false;

      const URLParams = this.URLParamsToArray(_szParams);
      if (!(URLParams?.length > 0)) return false;

      let szSearch = _szParams;
      if (szSearch[0] === "?") szSearch = szSearch.substring(1);

      for (let szKey of Object.keys(_object)) {
         let szValue = this.GetURLParam(szKey, URLParams);
         if (szValue === undefined) return false;

         let oValue: any = szValue;
         let szType = typeof (_object as any)[szKey];
         if (szType === "boolean") oValue = this.ParseToBoolean(oValue);
         else if (szType === "number") oValue = this.ParseToNumber(oValue);

         (_object as any)[szKey] = oValue;
      }

      return true;
   }
   public static URLParamsToArray(_szParams: string): URLParam[] {
      if (!_szParams) return [];

      let szSearch = _szParams;
      if (szSearch[0] === "?") szSearch = szSearch.substring(1);

      /* ---------------------------------------------- */
      // _szParams字串轉為KeyValue的Array
      let aszSplits = szSearch.split("&");
      let aKeyValues: { Key: string; Value: string }[] = [];
      for (let sz of aszSplits) {
         let aKeyValue = sz.split("=");
         if (aKeyValue.length === 2)
            aKeyValues.push({
               Key: aKeyValue[0].trim(),
               Value: aKeyValue[1].trim(),
            });
      }
      /* ---------------------------------------------- */
      return aKeyValues;
   }

   public static ParseToBoolean(_szValue: string): boolean {
      if (_szValue?.toLocaleLowerCase?.call(_szValue) === "true") return true;
      return false;
   }
   public static ParseToNumber(_szValue: string): number {
      if (typeof _szValue === "string" && _szValue.length <= 0) return Number.NaN;
      let nValue = Number(_szValue);
      return nValue;
   }

   public static GetURLParam(_szKey: string, _aParam: string | URLParam[]): string | undefined {
      if (typeof _aParam === "string") _aParam = this.URLParamsToArray(_aParam);

      const mdFind = _aParam?.find((q) => q.Key === _szKey);
      return mdFind?.Value;
   }
   public static GetURLParamAsNumber(_szKey: string, _aParam: string | URLParam[]): number | undefined {
      if (typeof _aParam === "string") _aParam = this.URLParamsToArray(_aParam);

      const mdFind = _aParam?.find((q) => q.Key === _szKey);
      if (mdFind?.Value === undefined) return undefined;
      return this.ParseToNumber(mdFind.Value);
   }

   /* ----------------------------------------------------------- */
   //
   public static GetAuthHeader() {
      const headers: { authorization?: string } = {};
      if (this.Token) headers.authorization = `Bearer ${this.Token}`;
      return headers;
   }

   /* ----------------------------------------------------------- */
   //
   public static UpdateToken(_Token: string) {
      SetToken(_Token);
      this.Token = _Token;
      this.Account = TokenToAccount(_Token);
   }
}

export class LoginAppModel {
   private static BufferKey = "LoginApp"; // sessionStorage Key
   public AccIdx: number = Number.NaN;
   public Account: string = "";
   public Name: string = "";
   public EMail: string = "";

   public HasValue(): boolean {
      return LoginAppModel.HasSafeValue(this);
   }

   public static CreateFromURLParams(_szParams: string) {
      const md = new LoginAppModel();

      if (!PostApi.URLParamsToObject(md, _szParams) || !md.HasValue()) return undefined;

      return md;
   }

   public static HasSafeValue(_mdParam: LoginAppModel) {
      if (!_mdParam) return false;
      if (!_mdParam.Account || !_mdParam.Name || !_mdParam.EMail) return false;
      if (!Number.isSafeInteger(_mdParam.AccIdx)) return false;

      return true;
   }

   public static SaveBuffer(_mdParam: LoginAppModel) {
      if (!_mdParam) return;
      const szJSon = JSON.stringify(_mdParam);
      window.sessionStorage.setItem(this.BufferKey, szJSon);
   }
   public static ReadBuffer() {
      const szJSon = window.sessionStorage.getItem(this.BufferKey);
      if (!szJSon) return undefined;

      return JSON.parse(szJSon) as LoginAppModel;
   }
}
