import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes, UseEntrust } from "./Block_Define";

Blockly.Blocks["block_order_tradeindex_getcount_inventory"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("庫存筆數").appendField("商品");
      this.appendDummyInput()
         .appendField("買賣別")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["多單", "Buy"],
               ["空單", "Sell"],
            ]),
            "BuySellType"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_Inv);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_tradeindex_getcount_entrust"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("掛單筆數").appendField("商品");
      this.appendDummyInput()
         .appendField("掛單方式")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["Buy Limit", "Buy_Limit"],
               ["Sell Limit", "Sell_Limit"],
               ["Buy Stop", "Buy_Stop"],
               ["Sell Stop", "Sell_Stop"],
            ]),
            "BuySellType"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_Ent);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_tradeindex_getcount_history"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("歷史筆數").appendField("商品");
      this.appendDummyInput()
         .appendField("買賣別")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["多單", "Buy"],
               ["空單", "Sell"],
            ]),
            "BuySellType"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_His);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_tradeindex_gettradeidx_inventory"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("庫存單號").appendField("商品");
      this.appendDummyInput()
         .appendField("買賣別")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["多單", "Buy"],
               ["空單", "Sell"],
            ]),
            "BuySellType"
         );
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("依下單順序").appendField("第");
      this.appendDummyInput().appendField("筆");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_Inv);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_tradeindex_gettradeidx_entrust"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("掛單單號").appendField("商品");
      this.appendDummyInput()
         .appendField("掛單方式")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["Buy Limit", "Buy_Limit"],
               ["Sell Limit", "Sell_Limit"],
               ["Buy Stop", "Buy_Stop"],
               ["Sell Stop", "Sell_Stop"],
            ]),
            "BuySellType"
         );
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("依掛單順序").appendField("第");
      this.appendDummyInput().appendField("筆");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_Ent);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_tradeindex_gettradeidx_history"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("歷史單號").appendField("商品");
      this.appendDummyInput()
         .appendField("買賣別")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["多單", "Buy"],
               ["空單", "Sell"],
            ]),
            "BuySellType"
         );
      this.appendValueInput("Index").setCheck(FieldTypes.Number).appendField("依平倉順序").appendField("第");
      this.appendDummyInput().appendField("筆");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_His);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_tradeindex_getlasttradeidx_inventory"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("庫存單號").appendField("最新一筆").appendField("商品");
      this.appendDummyInput()
         .appendField("買賣別")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["多單", "Buy"],
               ["空單", "Sell"],
            ]),
            "BuySellType"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_Inv);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_tradeindex_getlasttradeidx_entrust"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("掛單單號").appendField("最新一筆").appendField("商品");
      this.appendDummyInput()
         .appendField("掛單方式")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["Buy Limit", "Buy_Limit"],
               ["Sell Limit", "Sell_Limit"],
               ["Buy Stop", "Buy_Stop"],
               ["Sell Stop", "Sell_Stop"],
            ]),
            "BuySellType"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_Ent);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_tradeindex_getlasttradeidx_history"] = {
   init: function () {
      this.appendValueInput("Commodity").setCheck(FieldTypes.Commodity).appendField("取得").appendField("歷史單號").appendField("最新一筆").appendField("商品");
      this.appendDummyInput()
         .appendField("買賣別")
         .appendField(
            new Blockly.FieldDropdown([
               ["全部", "All"],
               ["多單", "Buy"],
               ["空單", "Sell"],
            ]),
            "BuySellType"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_TradeIndex_His);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.JavaScript["block_order_tradeindex_getcount_inventory"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetCount_Inventory(${value_commodity}, '${dropdown_buyselltype}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_tradeindex_getcount_entrust"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetCount_Entrust(${value_commodity}, '${dropdown_buyselltype}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_tradeindex_getcount_history"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetCount_History(${value_commodity}, '${dropdown_buyselltype}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_tradeindex_gettradeidx_inventory"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetTradeIdx_Inventory(${value_commodity}, '${dropdown_buyselltype}', ${value_index})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_tradeindex_gettradeidx_entrust"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetTradeIdx_Entrust(${value_commodity}, '${dropdown_buyselltype}', ${value_index})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_tradeindex_gettradeidx_history"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   var value_index = Blockly.JavaScript.valueToCode(block, "Index", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetTradeIdx_History(${value_commodity}, '${dropdown_buyselltype}', ${value_index})`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_tradeindex_getlasttradeidx_inventory"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetLastTradeIdx_Inventory(${value_commodity}, '${dropdown_buyselltype}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_tradeindex_getlasttradeidx_entrust"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetLastTradeIdx_Entrust(${value_commodity}, '${dropdown_buyselltype}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_tradeindex_getlasttradeidx_history"] = function (block: any) {
   var value_commodity = Blockly.JavaScript.valueToCode(block, "Commodity", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_buyselltype = block.getFieldValue("BuySellType");
   // TODO: Assemble JavaScript into code variable.
   var code = `_TradeIndex_GetLastTradeIdx_History(${value_commodity}, '${dropdown_buyselltype}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

const contents_UseEntrust = [
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getlasttradeidx_inventory">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getcount_inventory">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_gettradeidx_inventory">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
   <value name="Index">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "sep",
      gap: "48",
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getlasttradeidx_entrust">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getcount_entrust">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_gettradeidx_entrust">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
   <value name="Index">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "sep",
      gap: "48",
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getlasttradeidx_history">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getcount_history">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_gettradeidx_history">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
   <value name="Index">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
];
const contents = [
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getlasttradeidx_inventory">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getcount_inventory">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_gettradeidx_inventory">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
   <value name="Index">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "sep",
      gap: "48",
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getlasttradeidx_history">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_getcount_history">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_tradeindex_gettradeidx_history">
   <field name="BuySellType">All</field>
   <value name="Commodity">
     <block type="block_commodity"></block>
   </value>
   <value name="Index">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
];

export const Toolbox_Order_TradeIndex = {
   kind: "category",
   name: "%{BKY_catOrder_TradeIndex}",
   categorystyle: Keys_Cat.Order_TradeIndex,
   contents: UseEntrust ? contents_UseEntrust : contents,
};
