import { Keys_Cat } from "../Blockly_Theme";
import { ToolboxItems_Order_Trade } from "./Blocks_Order_Trade";
import { Toolbox_Order_Entrust } from "./Blocks_Order_Entrust";
import { Toolbox_Order_Inventory } from "./Blocks_Order_Inventory";
import { Toolbox_Order_History } from "./Blocks_Order_History";
import { Toolbox_Order_TradeIndex } from "./Blocks_Order_TradeIndex";
import { UseEntrust } from "./Block_Define";

const contents_UseEntrust = [ToolboxItems_Order_Trade, Toolbox_Order_Entrust, Toolbox_Order_Inventory, Toolbox_Order_History, Toolbox_Order_TradeIndex];
const contents = [ToolboxItems_Order_Trade, Toolbox_Order_Inventory, Toolbox_Order_History, Toolbox_Order_TradeIndex];
export const Toolbox_Order = {
   kind: "category",
   name: "%{BKY_catOrder}",
   categorystyle: Keys_Cat.Order,
   contents: UseEntrust ? contents_UseEntrust : contents,
};
