import Blockly from "blockly";
import { FieldTypes } from "./Block_Define";

Blockly.Blocks["variables_declare"] = {
   init: function () {
      var DropDown = new Blockly.FieldDropdown(
         [
            ["int", "int"],
            ["double", "double"],
            ["bool", "bool"],
            ["string", "string"],
            ["DateTime", "DateTime"],
         ],
         this.validateType
      );

      this.appendValueInput("Value").setCheck(null).appendField("宣告").appendField(new Blockly.FieldVariable(null), "VAR").appendField("當").appendField(DropDown, "Type").appendField("資料");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle("variable_blocks");
   },
};

Blockly.Blocks["variables_declare"] = {
   init: function () {
      this.appendValueInput("Value")
         .setCheck(null)
         .appendField("宣告")
         .appendField(new Blockly.FieldVariable(null), "VAR")
         .appendField("當")
         .appendField(
            new Blockly.FieldDropdown(
               [
                  ["int", "int"],
                  ["double", "double"],
                  ["bool", "bool"],
                  ["string", "string"],
                  ["DateTime", "DateTime"],
               ]
               //this.validateType
            ),
            "Type"
         )
         .appendField("資料");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip("");
      this.setHelpUrl("");
      this.setStyle("variable_blocks");
   },
   validateType: function (newValue: any) {
      this.getSourceBlock().UpdateInputType(newValue);
      return newValue;
   },
   UpdateInputType: function (newValue: any) {
      //const workspace = this.workspace as WorkspaceSvg;

      const Input = this.inputList[0];
      if (Input) {
         if (newValue === "int" || newValue === "double") Input.setCheck(FieldTypes.Number);
         else if (newValue === "bool") Input.setCheck(FieldTypes.Boolean);
         else if (newValue === "string") Input.setCheck(FieldTypes.String);
         else if (newValue === "DateTime") Input.setCheck(FieldTypes.DateTime);
      }

      // 更改變數的Type, 改成跟原本不一樣時會有Bug
      // const variable = this.getField("VAR").getVariable();
      // if (newValue === "int" || newValue === "double") variable.type = "Number";
      // else if (newValue === "bool") variable.type = "Boolean";
      // else if (newValue === "string") variable.type = "String";
      // else if (newValue === "DateTime") variable.type = "DateTime";
   },
};

Blockly.JavaScript["variables_declare"] = function (block: any) {
   var dropdown_Type = block.getFieldValue("Type");
   var variable_var = Blockly.JavaScript.nameDB_.getName(block.getFieldValue("VAR"), "VARIABLE");

   var value_value = Blockly.JavaScript.valueToCode(block, "Value", Blockly.JavaScript.ORDER_ATOMIC);
   // TODO: Assemble JavaScript into code variable.
   //var code = dropdown_Type + " " + variable_var + " = " + value_value + ";\n";
   var code = variable_var + " = " + value_value + ";\n";

   return code;
};
