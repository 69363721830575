import Blockly from "blockly";

/* --------------------------------------------------------------- */
// 修改預設主題

// 整體樣式
const componentStyles = {
   workspaceBackgroundColour: "#fbfbfb", //"#1e1e1e", // 工作區(右邊)底色
   toolboxBackgroundColour: "#EEEEEE", //"blackBackground", // 工具箱底色
   toolboxForegroundColour: "#000000", // // 工具箱文字顏色
   flyoutBackgroundColour: "#E0E0E0FF", //"#252526", // 工具箱展開區域 底色
   flyoutForegroundColour: "#000000", //"#ccc", // 工具箱展開區域 文字顏色
   flyoutOpacity: 0.8, // 工具箱展開區域 透明度
   scrollbarColour: "#797979", // scrollbar顏色
   scrollbarOpacity: 0.8, // scrollbar透明度
   blackBackground: "#333", // 不知道
};

Blockly.Themes.Classic.componentStyles = {
   ...Blockly.Themes.Classic.componentStyles,
   ...componentStyles,
};

// 工具箱
const categoryStyles = Blockly.Themes.Classic.categoryStyles;
categoryStyles.colour_category.colour = "20";
categoryStyles.list_category.colour = "260";
categoryStyles.logic_category.colour = "#626b75";
categoryStyles.loop_category.colour = "#757570";
categoryStyles.math_category.colour = "#12599f";
categoryStyles.procedure_category.colour = "#495057";
categoryStyles.text_category.colour = "#2d9f4c";
categoryStyles.variable_category.colour = "#1976D2";
categoryStyles.variable_dynamic_category.colour = "#1976D2";
categoryStyles["CatStyles_Main"] = { colour: "#495057" };
categoryStyles["CatStyles_DateTIme"] = { colour: "#716161" };
categoryStyles["CatStyles_Commodity"] = { colour: "#2d9f4c" };
categoryStyles["CatStyles_Account"] = { colour: "#499f2d" };
categoryStyles["CatStyles_Order"] = { colour: "#784fb0" };
categoryStyles["CatStyles_Order_Trade"] = { colour: "#784fb0" };
categoryStyles["CatStyles_Order_Entrust"] = { colour: "#1462ae" };
categoryStyles["CatStyles_Order_Inventory"] = { colour: "#1462ae" };
categoryStyles["CatStyles_Order_History"] = { colour: "#1462ae" };
categoryStyles["CatStyles_Order_TradeIndex"] = { colour: "#1462ae" };
categoryStyles["CatStyles_Quote"] = { colour: "#1375ae" };
categoryStyles["CatStyles_TECP"] = { colour: "#134eae" };

export const Keys_Cat = {
   colour: "colour_category",
   list: "list_category",
   logic: "logic_category",
   loop: "loop_category",
   math: "math_category",
   procedure: "procedure_category",
   text: "text_category",
   variable: "variable_category",
   variable_dynamic: "variable_dynamic_category",
   Main: "CatStyles_Main",
   DateTIme: "CatStyles_DateTIme",
   Commodity: "CatStyles_Commodity",
   Account: "CatStyles_Account",
   Order: "CatStyles_Order",
   Order_Trade: "CatStyles_Order_Trade",
   Order_Entrust: "CatStyles_Order_Entrust",
   Order_Inventory: "CatStyles_Order_Inventory",
   Order_History: "CatStyles_Order_History",
   Order_TradeIndex: "CatStyles_Order_TradeIndex",
   Quote: "CatStyles_Quote",
   TECP: "CatStyles_TECP",
};

// Blocks
const blocks = Blockly.Themes.Classic.blockStyles;
const BaseBlock = { ...blocks.logic_blocks };
blocks.colour_blocks.colourPrimary = "20"; // 顏色 沒用
blocks.list_blocks.colourPrimary = "260"; // List 沒用
blocks.logic_blocks.colourPrimary = "#626b75"; // 邏輯 boolean
blocks.loop_blocks.colourPrimary = "#757570"; // 迴圈
blocks.math_blocks.colourPrimary = "#12599f"; // 數字
blocks.procedure_blocks.colourPrimary = "#495057"; // function
blocks.text_blocks.colourPrimary = "#2d9f4c"; // 字串
blocks.variable_blocks.colourPrimary = "#1976D2"; //"#1976D2"; // 變數
blocks.variable_dynamic_blocks.colourPrimary = "#1976D2"; // 變數
blocks.hat_blocks.colourPrimary = "#615151";
blocks["Block_Main"] = { ...BaseBlock, colourPrimary: "#495057" }; // 616a73{ ...BaseBlock, colourPrimary: "180" };
blocks["Block_DateTime"] = { ...BaseBlock, colourPrimary: "#716161" };
blocks["Block_Commodity"] = { ...BaseBlock, colourPrimary: "#2d9f4c" }; // 商品
blocks["Block_Account"] = { ...BaseBlock, colourPrimary: "#499f2d" };
blocks["Block_Order_Trade"] = { ...BaseBlock, colourPrimary: "#616161" };
blocks["Block_Order_Trade_LongIn"] = { ...BaseBlock, colourPrimary: "#784fb0" }; // 多方進場
blocks["Block_Order_Trade_ShortIn"] = { ...BaseBlock, colourPrimary: "#784fb0" }; // 空方進場
blocks["Block_Order_Trade_Out"] = { ...BaseBlock, colourPrimary: "#784fb0" }; // 出場
blocks["Block_Order_Entrust"] = { ...BaseBlock, colourPrimary: "#1462ae" };
blocks["Block_Order_Inventory"] = { ...BaseBlock, colourPrimary: "#1462ae" };
blocks["Block_Order_History"] = { ...BaseBlock, colourPrimary: "#1462ae" };
blocks["Block_Order_TradeIndex"] = { ...BaseBlock, colourPrimary: "#1462ae" };
blocks["Block_Order_TradeIndex_Inv"] = { ...BaseBlock, colourPrimary: "#1462ae" }; // 庫存
blocks["Block_Order_TradeIndex_Ent"] = { ...BaseBlock, colourPrimary: "#1462ae" }; // 掛單
blocks["Block_Order_TradeIndex_His"] = { ...BaseBlock, colourPrimary: "#1462ae" }; // 歷史
blocks["Block_Quote"] = { ...BaseBlock, colourPrimary: "#1375ae" };
blocks["Block_HParam"] = { ...BaseBlock, colourPrimary: "#2d9f4c" };
blocks["Block_TECP"] = { ...BaseBlock, colourPrimary: "#134eae" };

export const Keys_Block = {
   colour: "colour_blocks",
   list: "list_blocks",
   logic: "logic_blocks",
   loop: "loop_blocks",
   math: "math_blocks",
   procedure: "procedure_blocks",
   text: "text_blocks",
   variable: "variable_blocks",
   variable_dynamic: "variable_dynamic_blocks",
   hat: "hat_blocks",
   Main: "Block_Main",
   DateTime: "Block_DateTime",
   Commodity: "Block_Commodity",
   Account: "Block_Account",
   Order_Trade: "Block_Order_Trade",
   Order_Trade_LongIn: "Block_Order_Trade_LongIn",
   Order_Trade_ShortIn: "Block_Order_Trade_ShortIn",
   Order_Trade_Out: "Block_Order_Trade_Out",
   Order_Entrust: "Block_Order_Entrust",
   Order_Inventory: "Block_Order_Inventory",
   Order_History: "Block_Order_History",
   Order_TradeIndex: "Block_Order_TradeIndex",
   Order_TradeIndex_Inv: "Block_Order_TradeIndex_Inv",
   Order_TradeIndex_Ent: "Block_Order_TradeIndex_Ent",
   Order_TradeIndex_His: "Block_Order_TradeIndex_His",
   Quote: "Block_Quote",
   TECP: "Block_TECP",
   HParam: "Block_HParam",
};

/* --------------------------------------------------------------- */
// 可切換的新主題 範例
export const Theme_Dark = Blockly.Theme.defineTheme("dark", {
   base: Blockly.Themes.Classic,
   componentStyles: {
      workspaceBackgroundColour: "#1e1e1e",
      toolboxBackgroundColour: "blackBackground",
      toolboxForegroundColour: "#fff",
      flyoutBackgroundColour: "#252526",
      flyoutForegroundColour: "#ccc",
      flyoutOpacity: 1,
      scrollbarColour: "#797979",
      insertionMarkerColour: "#fff",
      insertionMarkerOpacity: 0.3,
      scrollbarOpacity: 0.4,
      cursorColour: "#d0d0d0",
      blackBackground: "#333",
   },
});
//this.m_workspace.setTheme(Theme_Dark);
