import Blockly from "blockly";
import { FieldTypes } from "./Block_Define";

var today = new Date();
Blockly.Blocks["block_DateTime"] = {
   init: function () {
      this.appendDummyInput()
         .appendField("指定時間")
         .appendField(new Blockly.FieldNumber(today.getUTCFullYear(), 2020, today.getUTCFullYear(), 1), "Year")
         .appendField("年")
         .appendField(new Blockly.FieldNumber(today.getUTCMonth() + 1, 1, 12, 1), "Month")
         .appendField("月")
         .appendField(new Blockly.FieldNumber(today.getUTCDate(), 1, 31, 1), "Date")
         .appendField("日")
         .appendField(new Blockly.FieldNumber(today.getUTCHours(), 0, 23, 1), "Hour")
         .appendField("時")
         .appendField(new Blockly.FieldNumber(today.getUTCMinutes(), 0, 59, 1), "Minute")
         .appendField("分");
      this.setOutput(true, FieldTypes.DateTime);
      this.setColour(300);
      this.setTooltip("日期");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_string_length_2"] = {
   init: function () {
      this.appendValueInput("VALUE").setCheck(FieldTypes.String).appendField("leng %1 of");
      this.setOutput(true, FieldTypes.Number);
      this.setColour(160);
      this.setTooltip("Returns number of letters in the provided text.");
      this.setHelpUrl("http://www.w3schools.com/jsref/jsref_length_string.asp");
   },
};
