import Blockly from "blockly";
import { Keys_Cat, Keys_Block } from "../Blockly_Theme";
import { FieldTypes, UseEntrust } from "./Block_Define";
import { Block_Order_External } from "./Block_Order_External";

if (UseEntrust) {
   Blockly.Blocks["block_order_inventory_changeorder"] = {
      init: function () {
         this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("修改停損停利").appendField("單號");
         Block_Order_External.AddStopLossInput(this); // 停損
         Block_Order_External.AddStopProfitInput(this); // 停利
         this.setInputsInline(true);
         this.setPreviousStatement(true, null);
         this.setNextStatement(true, null);
         this.setStyle(Keys_Block.Order_Inventory);
         this.setTooltip("");
         this.setHelpUrl("");
      },
   };
}
Blockly.Blocks["block_order_inventory_getinfo_double"] = {
   init: function () {
      this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("庫存").appendField("取得數據(數字)").appendField("單號");
      this.appendDummyInput()
         .appendField("數據")
         .appendField(
            new Blockly.FieldDropdown([
               ["手數", "HVol"],
               ["下單價格", "Price"],
               ["停損價格", "StopLossPrice"],
               ["停利價格", "StopProfitPrice"],
               ["庫存費", "SwapMoney"],
               ["獲利", "Profit"],
               ["佣金", "CommissionMoney"],
            ]),
            "Field"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Number);
      this.setStyle(Keys_Block.Order_Inventory);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_inventory_getinfo_string"] = {
   init: function () {
      this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("庫存").appendField("取得數據(文字)").appendField("單號");
      this.appendDummyInput()
         .appendField("數據")
         .appendField(new Blockly.FieldDropdown([["商品", "StockCode"]]), "Field");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.String);
      this.setStyle(Keys_Block.Order_Inventory);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_inventory_getinfo_datetime"] = {
   init: function () {
      this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("庫存").appendField("取得數據(時間)").appendField("單號");
      this.appendDummyInput()
         .appendField("數據")
         .appendField(new Blockly.FieldDropdown([["成交時間", "Time"]]), "Field");
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.DateTime);
      this.setStyle(Keys_Block.Order_Inventory);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.Blocks["block_order_inventory_getinfo_boolean"] = {
   init: function () {
      this.appendValueInput("TradeIdx").setCheck(FieldTypes.Number).appendField("庫存").appendField("取得數據(是否)").appendField("單號");
      this.appendDummyInput()
         .appendField("數據")
         .appendField(
            new Blockly.FieldDropdown([
               ["是否在庫存", "IsInventory"],
               ["是否為多單", "IsBuy"],
               ["是否為空單", "IsSell"],
            ]),
            "Field"
         );
      this.setInputsInline(true);
      this.setOutput(true, FieldTypes.Boolean);
      this.setStyle(Keys_Block.Order_Inventory);
      this.setTooltip("");
      this.setHelpUrl("");
   },
};

Blockly.JavaScript["block_order_inventory_changeorder"] = function (block: any) {
   var value_tradeidx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);
   // 停損
   const szStopLoss = Block_Order_External.GetCode_StopLoss(block, (b, k) => Blockly.JavaScript.valueToCode(b, k, Blockly.JavaScript.ORDER_ATOMIC));

   // 停利
   const szStopProfit = Block_Order_External.GetCode_StopProfit(block, (b, k) => Blockly.JavaScript.valueToCode(b, k, Blockly.JavaScript.ORDER_ATOMIC));

   // TODO: Assemble JavaScript into code variable.
   var code = `_Inventory_ChangeOrder(${value_tradeidx}, ${szStopLoss}, ${szStopProfit});\n`;
   return code;
};

Blockly.JavaScript["block_order_inventory_getinfo_double"] = function (block: any) {
   var value_tradeidx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_Inventory_GetInfo_Double(${value_tradeidx}, '${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_inventory_getinfo_string"] = function (block: any) {
   var value_tradeidx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_Inventory_GetInfo_String(${value_tradeidx},'${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_inventory_getinfo_datetime"] = function (block: any) {
   var value_tradeidx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_Inventory_GetInfo_DateTime(${value_tradeidx}, '${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript["block_order_inventory_getinfo_boolean"] = function (block: any) {
   var value_tradeidx = Blockly.JavaScript.valueToCode(block, "TradeIdx", Blockly.JavaScript.ORDER_ATOMIC);
   var dropdown_field = block.getFieldValue("Field");
   // TODO: Assemble JavaScript into code variable.
   var code = `_Inventory_GetInfo_Boolean(${value_tradeidx}, '${dropdown_field}')`;

   return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

const contents_UseEntrust = [
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_changeorder">
   <field name="StopLoss_Enable">FALSE</field>
   <field name="StopProfit_Enable">FALSE</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
   <value name="StopLoss">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
   <value name="StopProfit">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_getinfo_double">
   <field name="Field">HVol</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_getinfo_string">
   <field name="Field">StockCode</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_getinfo_datetime">
   <field name="Field">Time</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_getinfo_boolean">
   <field name="Field">IsInventory</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
];
const contents = [
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_getinfo_double">
   <field name="Field">HVol</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_getinfo_string">
   <field name="Field">StockCode</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_getinfo_datetime">
   <field name="Field">Time</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
   {
      kind: "block",
      blockxml: `<block type="block_order_inventory_getinfo_boolean">
   <field name="Field">IsInventory</field>
   <value name="TradeIdx">
     <shadow type="math_number">
       <field name="NUM">0</field>
     </shadow>
   </value>
 </block>`,
   },
];

export const Toolbox_Order_Inventory = {
   kind: "category",
   name: "%{BKY_catOrder_Inventory}",
   categorystyle: Keys_Cat.Order_Inventory,
   contents: UseEntrust ? contents_UseEntrust : contents,
};
