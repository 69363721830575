//import Blockly from "blockly";

import * as Blockly from "blockly/core";
import { WorkspaceSvg, BlockSvg } from "blockly/core";
import { SessionStorage } from "../../DataInfo/StorageHelper";
import "blockly/blocks";
import "blockly/javascript"; // Or the generator of your choice
import "./Blockly_Theme";
import * as kcBlocks from "./Blocks";

export const LANGUAGE_NAME = {
   ar: "العربية",
   "be-tarask": "Taraškievica",
   br: "Brezhoneg",
   ca: "Català",
   cs: "Česky",
   da: "Dansk",
   de: "Deutsch",
   el: "Ελληνικά",
   en: "English",
   es: "Español",
   et: "Eesti",
   fa: "فارسی",
   fr: "Français",
   he: "עברית",
   hrx: "Hunsrik",
   hu: "Magyar",
   ia: "Interlingua",
   is: "Íslenska",
   it: "Italiano",
   ja: "日本語",
   kab: "Kabyle",
   ko: "한국어",
   mk: "Македонски",
   ms: "Bahasa Melayu",
   nb: "Norsk Bokmål",
   nl: "Nederlands, Vlaams",
   oc: "Lenga d'òc",
   pl: "Polski",
   pms: "Piemontèis",
   "pt-br": "Português Brasileiro",
   ro: "Română",
   ru: "Русский",
   sc: "Sardu",
   sk: "Slovenčina",
   sr: "Српски",
   sv: "Svenska",
   ta: "தமிழ்",
   th: "ภาษาไทย",
   tlh: "tlhIngan Hol",
   tr: "Türkçe",
   uk: "Українська",
   vi: "Tiếng Việt",
   "zh-hans": "简体中文",
   "zh-hant": "繁體中文",
};

export const LanguageList = [
   {
      Name: "en",
      Text: "English",
      Locale: require("blockly/msg/en"),
      CusLocale: require("./Msg/en"),
   },
   {
      Name: "zh-hant",
      Text: "繁體中文",
      Locale: require("blockly/msg/zh-hant"),
      CusLocale: require("./Msg/zh-hant"),
   },
];

type CheckBlockType = {
   Resault: boolean;
   FailMsg: string;
   FailBlocks: Blockly.Block[];
};
export default class BlocklyHelper {
   public static ChangeLocale(_Locale: string, _Account: string, _StrategyIndex: number, _workspace: WorkspaceSvg | undefined) {
      var LocaleObj = LanguageList.find((q) => q.Name === _Locale);
      if (!LocaleObj) return;

      if (_workspace) {
         /* ----------------------------------------------- */
         // 直接重新載入
         (Blockly as any).setLocale(LocaleObj.Locale);
         (Blockly as any).setLocale(LocaleObj.CusLocale.MSG);

         for (var messageKey in LocaleObj.CusLocale.MSG) {
            Blockly.Msg[messageKey.toUpperCase()] = LocaleObj.CusLocale.MSG[messageKey];
         }

         const CurrentDom = Blockly.Xml.workspaceToDom(_workspace);
         Blockly.Xml.clearWorkspaceAndLoadFromXml(CurrentDom, _workspace); // 直接重新載入
         /* ----------------------------------------------- */

         /* ----------------------------------------------- */
         // // reload() 重新讀取頁面, 解更改語系時下拉選單不會變更的問題
         // SessionStorage.SaveLocale(LocaleObj.Name);
         // this.SaveBlockBuffer(_Account, _StrategyIndex, _workspace);
         // window.location.reload();
      } else {
         (Blockly as any).setLocale(LocaleObj.Locale);
         (Blockly as any).setLocale(LocaleObj.CusLocale.MSG);

         for (var messageKey in LocaleObj.CusLocale.MSG) {
            Blockly.Msg[messageKey.toUpperCase()] = LocaleObj.CusLocale.MSG[messageKey];
         }
         document.head.parentElement?.setAttribute("lang", LocaleObj.Name);
      }
   }
   public static SaveBlockBuffer(_Account: string, StrategyIndex: number, _workspace: WorkspaceSvg | undefined) {
      if (_workspace === undefined) return;
      var text = BlocklyHelper.getBlockDefinition_XML(_workspace, true, false);
      SessionStorage.SaveBlockBuffer(_Account, StrategyIndex, text);
   }
   public static GetBlockBuffer(_Account: string) {
      return SessionStorage.ReadBlockBuffer(_Account);
   }
   public static GetStorageLocale() {
      return SessionStorage.ReadLocale();
   }

   // 取得策略Block定義版本
   public static GetCurrentVersion(): string {
      return kcBlocks.BlocklyVersion;
   }

   // 從主程式Block取得StrategyName
   public static GetStrategyName(_ws?: Blockly.WorkspaceSvg): string {
      var MainBlock = _ws?.getBlocksByType("block_mainfunction", true);
      if (!MainBlock || MainBlock.length <= 0) return "";
      const MainBlock0 = MainBlock[0] as kcBlocks.MainBlockType;
      const szStrategyName = MainBlock0.GetStrategyName();
      if (!szStrategyName) return "";
      return szStrategyName;
   }

   // 從主程式Block取得Description
   public static GetDescription(_ws?: Blockly.WorkspaceSvg): string {
      var MainBlock = _ws?.getBlocksByType("block_mainfunction", true);
      if (!MainBlock || MainBlock.length <= 0) return "";
      const MainBlock0 = MainBlock[0] as kcBlocks.MainBlockType;
      const szDescription = MainBlock0.GetDescription();
      if (!szDescription) return "";
      return szDescription;
   }

   // 檢查是否只有一個主程式
   public static CheckBlock_MainBlock(_TopBlocks: Blockly.Block[]): CheckBlockType {
      var mdRet: CheckBlockType = { Resault: false, FailMsg: "", FailBlocks: [] };

      const MainBlocks = _TopBlocks.filter((q) => q.type === "block_mainfunction");
      if (MainBlocks.length < 1) {
         mdRet.FailMsg = "必須要有一個主程式";
      } else if (MainBlocks.length > 1) {
         mdRet.FailBlocks.push(...MainBlocks);
         mdRet.FailMsg = "只能有一個主程式";
      } else mdRet.Resault = true;

      return mdRet;
   }

   // 檢查Block輸入輸出是否完整
   public static CheckBlock_InOut(_Block: Blockly.Block): CheckBlockType {
      var mdRet: CheckBlockType = { Resault: false, FailMsg: "", FailBlocks: [] };

      // 檢查自己的輸出
      if (!this.CheckOutputConnected(_Block)) {
         mdRet.FailBlocks.push(_Block);
         mdRet.FailMsg = "這個積木沒有正確連接";
         //BlocklyHelper.BlinkBlock(_Block as BlockSvg);
         //alert("這個積木沒有正確連接");
         return mdRet;
      }

      // 先簡單判一下自己是否完整
      if (_Block.allInputsFilled(true)) {
         mdRet.Resault = true;
         return mdRet;
      }

      /* ----------------------------------------------- */
      // 一次HighLight一個錯誤項, 從最末端開始
      // 檢查Child是否填充
      const Childs = _Block.getChildren(false);
      for (let Child of Childs) {
         var ChildRes = this.CheckBlock_InOut(Child);
         // 只要有一個false就跳過其他的
         if (!ChildRes.Resault) return ChildRes;
      }

      // 用Input檢查
      let bThisError = false;
      const lInputs = _Block.inputList;
      for (let Input of lInputs) {
         // Input.type === 1 必填輸入
         // Input.type === 3 Function類型
         // Input.type === 5 空輸入, 可以不檢查
         if (Input.type === 5) continue;
         if (Input.type === 3) continue;
         if (Input.type === 1) {
            if (Input.connection.targetBlock() === null) {
               bThisError = true;
               break;
            }
         }
      }

      if (bThisError) {
         mdRet.FailBlocks.push(_Block);
         mdRet.FailMsg = "積木尚未完整填充";
         return mdRet;
      }

      mdRet.Resault = true;
      return mdRet;

      /* ----------------------------------------------- */
      // 用Input檢查, 可以做到一次HighLight全部錯誤項
      // let bThisError = false;
      // const lInputs = _Block.inputList;
      // for (let Input of lInputs) {
      //   // Input.type == 1 必填輸入
      //   // Input.type == 3 Function類型
      //   // Input.type == 5 空輸入, 可以不檢查
      //   if (Input.type == 5) continue;
      //   var TargetBlock = Input.connection.targetBlock();
      //   if (Input.type == 3) {
      //     if (TargetBlock === null) continue;
      //     this.CheckBlock_InOut(TargetBlock);
      //     // if (!this.CheckBlock_InOut(TargetBlock)) bThisError = true;
      //   }
      //   if (Input.type == 1) {
      //     if (TargetBlock === null) bThisError = true;
      //     else this.CheckBlock_InOut(TargetBlock);
      //   }
      // }
      // if (bThisError) BlocklyHelper.BlinkBlock(_Block as BlockSvg);
      // return !bThisError;
   }

   // 檢查Block輸入輸出是否完整
   public static CheckBlock_InOut_Pure(_Block: Blockly.Block): boolean {
      // 檢查自己的輸出
      if (!this.CheckOutputConnected(_Block)) {
         BlocklyHelper.BlinkBlock(_Block as BlockSvg);
         //alert("這個積木沒有正確連接");
         return false;
      }

      // 先簡單判一下自己是否完整
      if (_Block.allInputsFilled(true)) return true;

      /* ----------------------------------------------- */
      // 一次HighLight一個錯誤項, 從最末端開始
      // 檢查Child是否填充
      const Childs = _Block.getChildren(false);
      for (let Child of Childs) {
         if (!this.CheckBlock_InOut(Child))
            // 只要有一個false就跳過其他的
            return false;
      }

      // 用Input檢查
      let bThisError = false;
      const lInputs = _Block.inputList;
      for (let Input of lInputs) {
         // Input.type === 1 必填輸入
         // Input.type === 3 Function類型
         // Input.type === 5 空輸入, 可以不檢查
         if (Input.type === 5) continue;
         if (Input.type === 3) continue;
         if (Input.type === 1) {
            if (Input.connection.targetBlock() === null) {
               bThisError = true;
               break;
            }
         }
      }

      if (bThisError) {
         BlocklyHelper.BlinkBlock(_Block as BlockSvg);
         //alert("積木尚未完整填充");
         return false;
      }

      return true;

      /* ----------------------------------------------- */
      // 用Input檢查, 可以做到一次HighLight全部錯誤項
      // let bThisError = false;
      // const lInputs = _Block.inputList;
      // for (let Input of lInputs) {
      //   // Input.type == 1 必填輸入
      //   // Input.type == 3 Function類型
      //   // Input.type == 5 空輸入, 可以不檢查
      //   if (Input.type == 5) continue;
      //   var TargetBlock = Input.connection.targetBlock();
      //   if (Input.type == 3) {
      //     if (TargetBlock === null) continue;
      //     this.CheckBlock_InOut(TargetBlock);
      //     // if (!this.CheckBlock_InOut(TargetBlock)) bThisError = true;
      //   }
      //   if (Input.type == 1) {
      //     if (TargetBlock === null) bThisError = true;
      //     else this.CheckBlock_InOut(TargetBlock);
      //   }
      // }
      // if (bThisError) BlocklyHelper.BlinkBlock(_Block as BlockSvg);
      // return !bThisError;
   }
   // 檢查Block輸出是否已有連接
   private static CheckOutputConnected(_Block: Blockly.Block) {
      if (_Block.outputConnection === null) return true;
      return _Block.outputConnection.targetBlock() !== null;
   }

   public static getBlockDefinition_XML(_workspace: WorkspaceSvg, _bRemoveID: boolean, _bNewLine: boolean): string {
      if (!_workspace) return "";

      const XmlElement = Blockly.Xml.workspaceToDom(_workspace);
      var code_Xml = _bNewLine ? Blockly.Xml.domToPrettyText(XmlElement) : Blockly.Xml.domToText(XmlElement);
      if (_bRemoveID) code_Xml = this.RemoveID_XML(code_Xml);
      return code_Xml;
   }

   public static getBlockDefinition_JSon(_workspace: WorkspaceSvg, _bRemoveID: boolean, _bNewLine: boolean): string {
      if (!_workspace) return "";

      var JSonObj = Blockly.serialization.workspaces.save(_workspace);
      var fReplace = undefined;
      if (_bRemoveID)
         fReplace = (key: string, value: any) => {
            if (key === "id") return undefined;
            return value;
         };
      var nSpace = _bNewLine ? 2 : 0;

      return JSON.stringify(JSonObj, fReplace, nSpace);
   }

   public static RemoveID_XML(_Xml: string) {
      var szXml = _Xml;
      while (true) {
         var nSIdx = szXml.indexOf(' id="');
         if (nSIdx < 0) break;
         var nEIdx = szXml.indexOf('"', nSIdx + 5);
         if (nEIdx < 0) break;
         var szCut = szXml.substring(nSIdx, nEIdx + 1);
         szXml = szXml.replaceAll(szCut, "");
      }
      return szXml;
   }

   public static StartBlinkBlocks(Blocks: Blockly.Block[] | BlockSvg[]) {
      return BlinkBlockHelper.StartBlink(Blocks);
   }
   public static BlinkBlocks(_TopBlocks: Blockly.Block[] | BlockSvg[]) {
      _TopBlocks.forEach((_Block) => {
         (_Block as BlockSvg).select();
         (_Block as BlockSvg).setHighlighted(true);
         this.BlockBlink_Core(_Block as BlockSvg, 0, 5);
      });
   }
   public static BlinkBlock(_Block: BlockSvg) {
      _Block.bringToFront();
      _Block.select();
      _Block.setHighlighted(true);
      this.BlockBlink_Core(_Block, 0, 5);
   }
   private static async BlockBlink_Core(_Block: BlockSvg, _nNowCount: number, _nTimes: number) {
      const Timsout = 200;

      await this.Sleep(Timsout);
      _Block.setHighlighted(false);
      _Block.removeSelect();

      await this.Sleep(Timsout);
      _Block.setHighlighted(true);
      _Block.addSelect();

      _nNowCount++;
      if (_nNowCount < _nTimes) this.BlockBlink_Core(_Block, _nNowCount, _nTimes);
      else {
         await this.Sleep(Timsout);
         _Block.setHighlighted(false);
      }
   }
   private static Sleep(_nTimeOut: number) {
      return new Promise((r) => setTimeout(r, _nTimeOut));
   }

   public static RainbowBlock(_Blocks: Blockly.Block[]) {
      var Blocks = _Blocks.map((q) => q as BlockSvg);
      Blocks.forEach((v) => v.setHighlighted(true));

      this.RainbowBlock_Core(Blocks, 0);
   }
   private static async RainbowBlock_Core(_Blocks: BlockSvg[], _nIndex: number) {
      const Timsout = 15;

      await this.Sleep(Timsout);
      for (let i = 0; i < _Blocks.length; i++) {
         let nCol = ((_Blocks.length - i) * 1 + _nIndex) % 361;
         _Blocks[i].setColour(nCol);
      }

      let nextindex = (_nIndex + 1) % 361;
      if (nextindex < 0) nextindex += 361;
      this.RainbowBlock_Core(_Blocks, nextindex);
   }
   private static randomColor() {
      let r = this.rgbToHex(Math.floor(Math.random() * 255));
      let g = this.rgbToHex(Math.floor(Math.random() * 255));
      let b = this.rgbToHex(Math.floor(Math.random() * 255));
      return "#" + r + g + b;
   }
   private static rgbToHex = function (rgb: number) {
      var hex = Number(rgb).toString(16);
      if (hex.length < 2) {
         hex = "0" + hex;
      }
      return hex;
   };
}

class BlinkBlockHelper {
   static Timsout = 200;
   constructor(_Blocks: BlockSvg[] | Blockly.Block[]) {
      this.m_Blocks = _Blocks as BlockSvg[];
   }

   m_bBlinking = false;
   m_Blocks: BlockSvg[] = [];
   m_nBlickCount = 0;
   m_nStopCount = 3;

   public Start() {
      if (this.m_Blocks === undefined || this.m_Blocks.length === 0) return;
      this.m_bBlinking = true;

      this.m_Blocks.forEach((_Block) => {
         _Block.bringToFront();
         _Block.select();
         _Block.setHighlighted(true);
      });
      BlinkBlockHelper.BlockBlink_Core(this);
   }
   public Stop(_StopTimes?: number) {
      this.m_nBlickCount = 0;
      this.m_bBlinking = false;
   }
   public static StartBlink(_Blocks: BlockSvg[] | Blockly.Block[]) {
      var md = new BlinkBlockHelper(_Blocks);
      md.Start();
      return md;
   }

   private static async BlockBlink_Core(_Obj: BlinkBlockHelper) {
      if (!_Obj.m_bBlinking && _Obj.m_nBlickCount >= _Obj.m_nStopCount) {
         await this.Sleep(this.Timsout);
         _Obj.m_Blocks.forEach((_Block) => {
            _Block.setHighlighted(false);
         });
         return;
      }

      await this.Sleep(this.Timsout);
      _Obj.m_Blocks.forEach((_Block) => {
         _Block.setHighlighted(false);
         _Block.removeSelect();
      });

      await this.Sleep(this.Timsout);
      _Obj.m_Blocks.forEach((_Block) => {
         _Block.setHighlighted(true);
         _Block.addSelect();
      });

      _Obj.m_nBlickCount += 1;
      this.BlockBlink_Core(_Obj);
   }

   private static Sleep(_nTimeOut: number) {
      return new Promise((r) => setTimeout(r, _nTimeOut));
   }
}
