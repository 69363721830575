import React from "react";
import "./App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Home } from "./Pages/Home";
import Login from "./Pages/Login";
import BlocklyEdit from "./Pages/BlocklyEdit";
//import BlocklyEdit from "./Pages/BlocklyEdit/BlocklyEdit copy";
import NotFundPage from "./Pages/NotFund";
import ShareApi, { InitShareApi } from "./ShareApi";
import { PostApi } from "./Api/PostApi";

const InitPromise = InitShareApi();

export const App: React.FC = () => {
   const [Inited, setInited] = React.useState(false);
   const Location = useLocation();
   const Navigate = useNavigate();
   const [StrategyIndex, setStrategyIndex] = React.useState(1);

   React.useEffect(() => {
      InitAsync();
   }, []);
   React.useEffect(() => {
      if (Location.search) {
         var nStrategyIndex = PostApi.GetURLParamAsNumber("StrategyIndex", Location.search);
         if (nStrategyIndex !== undefined) setStrategyIndex(nStrategyIndex);
      }
   }, [Location.search]);

   const InitAsync = async () => {
      await InitPromise;
      await CheckAccount();
      setInited(true);
   };

   const CheckAccount = async () => {
      if (!PostApi.Account && Location.pathname !== "/Login") Navigate("/Login");
      else if (Location.pathname === "/") Navigate("/Code");
   };

   const ChangeStrategy = () => {
      Navigate(`/Code?StrategyIndex=${StrategyIndex + 1}`);
   };

   const getBackgroundColor = () => {
      if (ShareApi.TradeAppMode && ShareApi.TopBackColor) return ShareApi.TopBackColor;
      return "#846b00";
   };

   if (!Inited) return <Home />; // Loading

   if (Location.pathname === "/") return <Home />; // Loading
   if (Location.pathname === "/Loading") return <Home />; // Loading
   if (Location.pathname === "/Login") return <Login />;
   else if (Location.pathname === "/Code")
      return (
         <div style={{ backgroundColor: getBackgroundColor() }}>
            <BlocklyEdit StrategyIndex={StrategyIndex} ChangeStrategy={ChangeStrategy} />
         </div>
      );
   else return <NotFundPage />;
};

export default App;

// type tState = { Inited: boolean; UpdateUI: {} };
// class App extends React.Component<RouterProp, tState> {
//    state: tState = { Inited: false, UpdateUI: {} };
//    m_bFirstDidMount = true;

//    async componentDidMount() {
//       if (this.m_bFirstDidMount) {
//          this.m_bFirstDidMount = false;
//          this.InitAsync();
//       }
//       this.props.Route.App.current = this.onRoute;
//    }

//    InitAsync = async () => {
//       await InitPromise; //InitShareApi();
//       await this.CheckAccount();
//       this.setState({ Inited: true });
//    };

//    private CheckAccount = async () => {
//       if (PostApi.Account) this.props.Route.Navigate("/Code");
//       else this.props.Route.Navigate("/Login");
//       // const mdAccountState = await kcDataInfo.GetAccount();
//       // console.log("mdAccountState: ", mdAccountState.Succeed, mdAccountState.Account);
//       // const bAccountOK = mdAccountState.Succeed;
//       // if (bAccountOK) this.props.Route.Navigate("/Code");
//       // else this.props.Route.Navigate("/Login");
//    };

//    private onRoute = (_szPath: string) => {
//       this.setState({ UpdateUI: {} });
//    };

//    render() {
//       if (!this.state.Inited)
//          return (
//             <Routes>
//                <Route path="*" element={<Home />}></Route>
//             </Routes>
//          );
//       return (
//          <Routes>
//             <Route path="/" element={<Home />}></Route>
//             <Route path="/Login" element={<Login />}></Route>
//             <Route path="/Code" element={<BlocklyEdit />}></Route>
//             <Route path="*" element={<NotFundPage />}></Route>
//          </Routes>
//       );
//    }
// }

// export default withRouter(App);
